import { extendTheme } from '@chakra-ui/react';
import localFont from 'next/font/local';

import { buttonTheme } from '@/theme/button';
import { checkboxTheme } from '@/theme/checkbox';

const KintoSans = localFont({
  src: [
    {
      path: '../../public/fonts/KintoSans-Regular.woff2',
      weight: '400',
      style: 'normal',
    },
    {
      path: '../../public/fonts/KintoSans-Medium.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: '../../public/fonts/KintoSans-Bold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
});

export const theme = extendTheme({
  colors: {
    // Text
    text: {
      primary: '#22252D',
      secondary: '#7F7F89',
      negative: '#9EA7B8',
      error: '#EE2D38',
      white: 'white',
      black: '#020202',
      darkBlack: '#181B1E',
      gray: '#9CA1AB',
      lightGray: '#CFD2D7',
      darkGray: '#7F8596',
      semiDarkGray: '#6D6D6D',
      ultraDarkGray: '#656D7E',
      purpleGray: '#9AA0AC',
      blueGray: '#636E80',
      disabledGray: '#D6D9DC',
      red: '#EF6762',
      pink: '#FF7881',
      lightBlue: '#54CFF6',
      lime: '#57C03C',
      lightGreen: '#3AD47F',
      darkGreen: '#0B9D2F',
      darkPastelGreen: '#0CBE5B',
      green: '#3AD57F',
      purple: '#8F80FF',
    },

    // BackGround
    bg: {
      primary: '#FFFFFF',
      secondary: '#F8F9FD',
      error: '#EE2D38',
      darkCream: '#F7F7F0',
      lightCream: '#FCFCF9',
      black: '#020202',
      darkBlack: '#181B1E',
      lightGray: '#F3F4F5',
      darkGray: '#F2F2F4',
      buttonGray: '#F9F9FB',
      lightGreen: '#E6FFEC',
      green: '#3AD57F',
      pink: '#FFE5E7',
    },

    // Stroke
    stroke: {
      primary: '#E4E7EC',
      error: '#EE2D38',
      gray: '#E7E7E1',
      black: '#020202',
      lightGray: '#F3F4F5',
    },

    // Accent
    accent: {
      pink: '#D56FE6',
      purple: '#8E6FE6',
      orange: '#FF9457',
      lightGreen: '#3AD47F',
      darkGray: '#7F8596',
      lightGray: '#B9BCC5',
      red: '#EF6762',
      darkRed: '#E42F3C',
      ultraLightGray: '#FAFAFA',
      yellow: '#FFEA2F',
    },

    // custom colorSchema
    lightGreen: {
      500: '#3AD47F',
    },

    button: {
      gray: {
        50: '#f1f2f4',
        100: '#EBECEF',
        200: '#bbbcbe',
        300: '#9fa1a7',
        400: '#83868f',
        500: '#696c77',
        600: '#52545b',
        700: '#3b3c41',
        800: '#232427',
        900: '#0b0c0e',
      },
    },
  },
  fonts: {
    body: KintoSans.style.fontFamily,
    heading: KintoSans.style.fontFamily,
  },
  fontSizes: {
    '5xl': '64px',
    '4xl': '48px',
    '3xl': '40px',
    '2xl': '32px',
    xxl: '26px',
    xl: '24px',
    lg: '20px',
    ml: '18px',
    md: '16px',
    sm: '14px',
    xs: '12px',
    xxs: '11px',
    x3s: '10px',
    x4s: '9px',
  },
  styles: {
    global: {
      body: {
        '&>:first-of-type': {
          background: 'bg.primary',
        },
        overscrollBehavior: 'none',
      },
    },
  },
  components: {
    Button: buttonTheme,
    Checkbox: checkboxTheme,
  },
});
