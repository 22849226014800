import { useRef } from 'react';

import { useResizeEvent } from '@/hooks/useResizeEvent';

export const useEarth = () => {
  const imageBoxRef = useRef<HTMLDivElement>(null);

  const setWidth = () => {
    if (imageBoxRef.current) {
      const bodyHeight = document.documentElement.clientHeight;
      imageBoxRef.current.style.width =
        bodyHeight < 600 ? `${bodyHeight / 3}px` : 'revert-layer';
    }
  };

  useResizeEvent(setWidth);

  return { imageBoxRef };
};
