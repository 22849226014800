import { useTotalYellCountQuery } from '@/graphql/generated';

export const useSocialImpactCard = () => {
  const [{ data, fetching, error }] = useTotalYellCountQuery();

  const count =
    !fetching && error === undefined && data !== undefined
      ? data.totalYellNftHistoriesCount
      : 0;

  return { count, fetching };
};
