import { signInWithCustomToken, User } from 'firebase/auth';
import { useRouter } from 'next/router';
import { parseCookies } from 'nookies';
import {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { gql } from 'urql';

import { languages } from '@/app/i18n/settings';
import { LoadingScreen } from '@/components/common/LoadingScreen';
import { authUrlPatterns } from '@/constants/auth';
import { Paths } from '@/constants/path';
import { useCustomClaimAuthContextQuery } from '@/graphql/generated';
import { IsLoginStatus, useInnerAuthContext } from '@/hooks/useAuthContext';
import { auth } from '@/utils/firebase/client';
import { createPath } from '@/utils/path';

// 認証前のページで全画面ローディングを表示したくない場合はここにパスを追加する
const notLoadingPath: Set<string> = new Set([Paths.login]);

gql`
  fragment CustomClaimAuthContext on MyCustomClaimOutput {
    isOnboardingCompleted
  }
`;

gql`
  query customClaimAuthContext {
    webMyCustomClaims {
      ...CustomClaimAuthContext
    }
  }
`;

interface AuthContextProps {
  currentUser?: User | null;
  isLoginStatus?: IsLoginStatus;
  isLoading?: boolean;
}

const AuthContext = createContext<AuthContextProps>({
  currentUser: undefined,
  isLoginStatus: undefined,
  isLoading: undefined,
});

type Props = {
  children: ReactNode;
};

export const AuthProvider: FC<Props> = ({ children }) => {
  const router = useRouter();
  const { currentUser, isLoginStatus, isLoading } = useInnerAuthContext();
  const [{ data: claimsData, fetching: claimsFetching }] =
    useCustomClaimAuthContextQuery({
      pause:
        !currentUser ||
        !authUrlPatterns.some((pattern) => pattern.test(router.asPath)),
    });
  const [isRedirect, setIsRedirect] = useState(false);
  const isAuthenticatedUrl = useMemo(() => {
    return authUrlPatterns.some((pattern) => pattern.test(router.asPath));
  }, [router.asPath]);

  useEffect(() => {
    const cookies = parseCookies();
    if (cookies.appCustomToken && !currentUser) {
      signInWithCustomToken(auth, cookies.appCustomToken)
        .then((userCredential) => {
          const updatedCurrentUser = userCredential.user;
          setIsRedirect(
            !isLoading && !updatedCurrentUser && isAuthenticatedUrl
          );
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (!currentUser) {
      setIsRedirect(!isLoading && isAuthenticatedUrl);
    }
  }, [currentUser, isAuthenticatedUrl, isLoading, router.asPath]);

  useEffect(() => {
    const pathname = router.asPath.split('?').at(0);
    if (
      isRedirect &&
      pathname &&
      !new RegExp(`^(/(${languages.join('|')}))?/login$`).test(pathname)
    ) {
      router.replace(
        `${createPath({
          path: Paths.login,
        })}?redirect=${pathname}`
      );
    }
  }, [isRedirect, router]);

  useEffect(() => {
    const isOnboardingCompleted =
      claimsData?.webMyCustomClaims.isOnboardingCompleted;
    if (
      !claimsFetching &&
      currentUser &&
      isAuthenticatedUrl &&
      isOnboardingCompleted !== undefined &&
      !isOnboardingCompleted &&
      router.pathname.replace('/[lng]', '') !== Paths.v2Onboarding
    ) {
      router.replace(createPath({ path: Paths.v2Onboarding }));
    }
  }, [claimsData, claimsFetching, currentUser, isAuthenticatedUrl, router]);

  return (
    <AuthContext.Provider value={{ currentUser, isLoginStatus }}>
      {isLoading && !notLoadingPath.has(router.pathname) ? (
        <LoadingScreen />
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export type { AuthContextProps };
export { AuthContext };
