import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

import { useTranslation } from '@/app/i18n/client';
import { languages } from '@/app/i18n/settings';
import { SvgIconCell2 } from '@/components/assets/IconCell2';
import { SvgIconHeart } from '@/components/assets/IconHeart';
import { SvgIconHome } from '@/components/assets/IconHome';
import { SvgIconNft } from '@/components/assets/IconNft';
import { SvgIconStamp } from '@/components/assets/IconStamp';
import { SvgIconStore } from '@/components/assets/IconStore';
import { Paths } from '@/constants/path';
import { createPath } from '@/utils/path';

const languagesStr = languages.join('|');

export const useBottomNav = () => {
  const pathname = usePathname();
  const { t } = useTranslation('common');

  const navItems = useMemo(
    () => [
      {
        title: t('nav.home', 'ホーム'),
        icon: <SvgIconHome size={20} />,
        path: createPath({ path: Paths.v2 }),
        isActive: new RegExp(`^(/(${languagesStr}))?/v2$`).test(pathname ?? ''),
      },
      {
        title: t('nav.nfts', 'NFT'),
        icon: <SvgIconNft size={20} />,
        path: createPath({ path: Paths.v2MyNfts }),
        isActive: new RegExp(`^(/(${languagesStr}))?/v2/my-nfts/?`).test(
          pathname ?? ''
        ),
      },
      {
        title: t('nav.checked', 'みたよ'),
        icon: <SvgIconHeart size={20} />,
        path: createPath({ path: Paths.v2Checked }),
        isActive: new RegExp(`^(/(${languagesStr}))?/v2/checked/?`).test(
          pathname ?? ''
        ),
      },
      {
        title: t('nav.hoshi', 'HOSHI'),
        icon: <SvgIconCell2 size={19} color="currentColor" />,
        path: createPath({ path: Paths.v2Cells }),
        isActive:
          new RegExp(`^(/(${languagesStr}))?/v2/cells/?`).test(
            pathname ?? ''
          ) ||
          new RegExp(`^(/(${languagesStr}))?/v2/cell/?`).test(pathname ?? ''),
      },
      {
        title: t('nav.stamps', 'スタンプ'),
        icon: <SvgIconStamp size={19} color="currentColor" noViewBoxPadding />,
        path: createPath({ path: Paths.v2Stamps }),
        isActive: new RegExp(`^(/(${languagesStr}))?/v2/stamps/?`).test(
          pathname ?? ''
        ),
      },
      {
        title: t('nav.store', 'ストア'),
        icon: <SvgIconStore size={19} />,
        path: createPath({ path: Paths.v2Stores }),
        isActive: new RegExp(`^(/(${languagesStr}))?/v2/store/?`).test(
          pathname ?? ''
        ),
      },
    ],
    [pathname, t]
  );

  return { navItems };
};
