import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type DateString = string & { __dateStringBrand: any };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: DateString;
  Decimal: number;
  JSON: any;
};

export type AchievementHistory = {
  __typename?: 'AchievementHistory';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type ActionHistory = {
  __typename?: 'ActionHistory';
  actionActiveRate: Scalars['Float'];
  actions: Scalars['Float'];
  checkActiveRate: Scalars['Float'];
  checks: Scalars['Float'];
  position: Scalars['String'];
  quizActiveRate: Scalars['Float'];
  quizzes: Scalars['Float'];
  uniqueActions: Scalars['Float'];
};

export type ActiveCellArea = {
  __typename?: 'ActiveCellArea';
  _count: ActiveCellAreaCount;
  cellCount: Scalars['Int'];
  cells?: Maybe<Array<Cell>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  downLeftLat: Scalars['Decimal'];
  downLeftLng: Scalars['Decimal'];
  downRightLat: Scalars['Decimal'];
  downRightLng: Scalars['Decimal'];
  id: Scalars['ID'];
  latitudes: Scalars['String'];
  longitudes: Scalars['String'];
  upLeftLat: Scalars['Decimal'];
  upLeftLng: Scalars['Decimal'];
  upRightLat: Scalars['Decimal'];
  upRightLng: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
};

export type ActiveCellAreaCount = {
  __typename?: 'ActiveCellAreaCount';
  cells: Scalars['Int'];
};

export type AddInvestmentPointInput = {
  courseId: Scalars['ID'];
  requestPoint: Scalars['Int'];
};

export type AddPowerHistoryInput = {
  checkableFrom: Scalars['DateTime'];
  checkableTo: Scalars['DateTime'];
  co2Power: Scalars['Decimal'];
  collectionFrom: Scalars['DateTime'];
  collectionTo: Scalars['DateTime'];
  contributorId: Scalars['String'];
  power: Scalars['Decimal'];
};

export type AddPowerHistoryOutput = {
  __typename?: 'AddPowerHistoryOutput';
  failures?: Maybe<Array<Failures>>;
  numOfSuccess: Scalars['Float'];
};

export type AddQuizAnswerOutput = {
  __typename?: 'AddQuizAnswerOutput';
  answer: Scalars['Int'];
  answerSource: Scalars['String'];
  isCorrect: Scalars['Boolean'];
  point: Scalars['Int'];
};

export type AddQuizOutput = {
  __typename?: 'AddQuizOutput';
  error?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  question: Scalars['String'];
  success: Scalars['Boolean'];
};

export type AddUniqueQuizInput = {
  answer: Scalars['Int'];
  answerSource: Scalars['String'];
  category: QuizCategory;
  choice1: Scalars['String'];
  choice2: Scalars['String'];
  choice3?: InputMaybe<Scalars['String']>;
  choice4?: InputMaybe<Scalars['String']>;
  dispatchAt: Scalars['DateTime'];
  index?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['Int']>;
  question: Scalars['String'];
  quizAssignmentStatus: QuizAssignmentStatus;
  quizStatus: QuizStatus;
};

export enum AggregateTargetType {
  Company = 'COMPANY',
  Department = 'DEPARTMENT',
  Employee = 'EMPLOYEE'
}

export type Announcement = {
  __typename?: 'Announcement';
  UserOnAnnouncement?: Maybe<Array<UserOnAnnouncement>>;
  _count: AnnouncementCount;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type AnnouncementCount = {
  __typename?: 'AnnouncementCount';
  UserOnAnnouncement: Scalars['Int'];
};

export type ApiKey = {
  __typename?: 'ApiKey';
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  expiredAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  keyHash: Scalars['String'];
  status: ApiKeyStatus;
  updatedAt: Scalars['DateTime'];
};

export enum ApiKeyStatus {
  Available = 'AVAILABLE',
  Unavailable = 'UNAVAILABLE'
}

export type AssignedQuizOutput = {
  __typename?: 'AssignedQuizOutput';
  answerSource: Scalars['String'];
  /** 回答済み従業員数 */
  answeredEmployeesCount: Scalars['Int'];
  choice1: Choice;
  choice2: Choice;
  choice3: Choice;
  choice4: Choice;
  /** 正解の選択肢 */
  correctAnswerInt: Scalars['Int'];
  dispatchAt: Scalars['DateTime'];
  /** ユーザーが選択した選択肢 */
  myAnswerInt?: Maybe<Scalars['Int']>;
  question: Scalars['String'];
  /** 回答済み従業員数 / 全従業員数 */
  quizAnsweredPercentage: Scalars['Int'];
};

export type AssignedQuizzesOutput = {
  __typename?: 'AssignedQuizzesOutput';
  /** 回答済み従業員数 */
  answeredEmployeesCount: Scalars['Int'];
  /** 正解の選択肢 */
  correctAnswerText: Scalars['String'];
  dispatchAt: Scalars['DateTime'];
  /** ユーザーが選択した選択肢 */
  myAnswerText?: Maybe<Scalars['String']>;
  question: Scalars['String'];
  /** 回答済み従業員数 / 全従業員数 */
  quizAnsweredPercentage: Scalars['Int'];
  quizAssignmentId: Scalars['String'];
};

export type Bin = {
  __typename?: 'Bin';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type Cell = {
  __typename?: 'Cell';
  _count: CellCount;
  activeCellArea: ActiveCellArea;
  activeCellAreaId: Scalars['String'];
  addressEn: Scalars['String'];
  addressJa: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  landmarksEn?: Maybe<Scalars['String']>;
  lat: Scalars['Decimal'];
  lng: Scalars['Decimal'];
  revealedCells?: Maybe<Array<RevealedCell>>;
  updatedAt: Scalars['DateTime'];
};

export type CellCount = {
  __typename?: 'CellCount';
  revealedCells: Scalars['Int'];
};

export type CellWithIsSelfReveal = {
  __typename?: 'CellWithIsSelfReveal';
  _count: CellCount;
  activeCellArea: ActiveCellArea;
  activeCellAreaId: Scalars['String'];
  addressEn: Scalars['String'];
  addressJa: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isSelfRevealed: Scalars['Boolean'];
  landmarksEn?: Maybe<Scalars['String']>;
  lat: Scalars['Decimal'];
  lng: Scalars['Decimal'];
  revealedCells?: Maybe<Array<RevealedCell>>;
  updatedAt: Scalars['DateTime'];
};

export enum Chain {
  Polygon = 'POLYGON'
}

export type Character = {
  __typename?: 'Character';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type CharacterExtends = {
  __typename?: 'CharacterExtends';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  products: Array<Product>;
  updatedAt: Scalars['DateTime'];
};

export type CheckCompanyActionHistory = {
  __typename?: 'CheckCompanyActionHistory';
  CheckCompanyActionSummary: CheckCompanyActionSummary;
  CompanyActionHistory: CompanyActionHistory;
  checkCompanyActionSummaryId: Scalars['String'];
  companyActionHistoryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type CheckCompanyActionSummary = {
  __typename?: 'CheckCompanyActionSummary';
  CheckCompanyActionHistory?: Maybe<Array<CheckCompanyActionHistory>>;
  CheckSummary: CheckSummary;
  Company: Company;
  CompanyUser: CompanyUser;
  _count: CheckCompanyActionSummaryCount;
  checkSummaryId: Scalars['String'];
  companyId: Scalars['String'];
  companyUserId: Scalars['String'];
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type CheckCompanyActionSummaryCount = {
  __typename?: 'CheckCompanyActionSummaryCount';
  CheckCompanyActionHistory: Scalars['Int'];
};

export type CheckContributorHistory = {
  __typename?: 'CheckContributorHistory';
  checkContributorSummary: CheckContributorSummary;
  checkContributorSummaryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type CheckContributorSummary = {
  __typename?: 'CheckContributorSummary';
  _count: CheckContributorSummaryCount;
  checkContributorHistory?: Maybe<Array<CheckContributorHistory>>;
  checkSummary: CheckSummary;
  checkSummaryId: Scalars['String'];
  contributor: Contributor;
  contributorId: Scalars['String'];
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type CheckContributorSummaryCount = {
  __typename?: 'CheckContributorSummaryCount';
  checkContributorHistory: Scalars['Int'];
};

export type CheckSummary = {
  __typename?: 'CheckSummary';
  CheckCompanyActionSummary?: Maybe<CheckCompanyActionSummary>;
  CheckContributorSummary?: Maybe<Array<CheckContributorSummary>>;
  _count: CheckSummaryCount;
  count: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type CheckSummaryCount = {
  __typename?: 'CheckSummaryCount';
  CheckContributorSummary: Scalars['Int'];
};

export type Choice = {
  __typename?: 'Choice';
  answeredPercentage: Scalars['Float'];
  text: Scalars['String'];
};

export type Co2PointHistoriesOutput = {
  __typename?: 'Co2PointHistoriesOutput';
  amount?: Maybe<Scalars['Decimal']>;
  balance: Scalars['Decimal'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  targetDate: Scalars['DateTime'];
  totalAmount: Scalars['Decimal'];
  type?: Maybe<Co2PointHistoryType>;
};

export type Co2PointHistory = {
  __typename?: 'Co2PointHistory';
  amount: Scalars['Decimal'];
  balance: Scalars['Decimal'];
  co2PointSummary: Co2PointSummary;
  co2PointSummaryId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  targetId: Scalars['String'];
  targetType: Co2PointHistoryTargetType;
  totalAmount: Scalars['Decimal'];
  type: Co2PointHistoryType;
  updatedAt: Scalars['DateTime'];
};

export enum Co2PointHistoryTargetType {
  Cell = 'Cell',
  RevealedCell = 'RevealedCell',
  YellNftHistory = 'YellNftHistory'
}

export enum Co2PointHistoryType {
  Add = 'ADD',
  Reduce = 'REDUCE'
}

export type Co2PointSummary = {
  __typename?: 'Co2PointSummary';
  _count: Co2PointSummaryCount;
  balance: Scalars['Decimal'];
  co2PointHistory?: Maybe<Array<Co2PointHistory>>;
  id: Scalars['ID'];
  totalAmount: Scalars['Decimal'];
  user: User;
  userId: Scalars['String'];
};

export type Co2PointSummaryCount = {
  __typename?: 'Co2PointSummaryCount';
  co2PointHistory: Scalars['Int'];
};

export type Co2PointSummaryOutput = {
  __typename?: 'Co2PointSummaryOutput';
  amountOfThisYear: Scalars['Decimal'];
  balance: Scalars['Decimal'];
  id: Scalars['String'];
  totalAmount: Scalars['Decimal'];
  userId: Scalars['String'];
};

export type Co2PointSummaryOutputForGraph = {
  __typename?: 'Co2PointSummaryOutputForGraph';
  amountOfThisYear: Scalars['Decimal'];
  balance: Scalars['Decimal'];
  co2PointHistories: Array<Co2PointHistoriesOutput>;
  id: Scalars['String'];
  totalAmount: Scalars['Decimal'];
  userId: Scalars['String'];
};

export type Company = {
  __typename?: 'Company';
  CheckCompanyActionSummary?: Maybe<Array<CheckCompanyActionSummary>>;
  _count: CompanyCount;
  answeredQuizPoint: Scalars['Int'];
  apiKeys?: Maybe<Array<ApiKey>>;
  checkedCompanyActionHistoryPoint: Scalars['Int'];
  checkedContributorPoint: Scalars['Int'];
  city: Scalars['String'];
  cityEn?: Maybe<Scalars['String']>;
  companyImages?: Maybe<Array<CompanyImage>>;
  companySales?: Maybe<Array<CompanySale>>;
  companyUsers?: Maybe<Array<CompanyUser>>;
  contributors?: Maybe<Array<Contributor>>;
  correctlyAnsweredQuizPoint: Scalars['Int'];
  country: Country;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  departments?: Maybe<Array<Department>>;
  description?: Maybe<Scalars['String']>;
  descriptionEn?: Maybe<Scalars['String']>;
  ecoActionTemplateCategory?: Maybe<Array<EcoActionTemplateCategory>>;
  hpUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isBizUser: Scalars['Boolean'];
  logoUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
  nameKana: Scalars['String'];
  postalCode: Scalars['String'];
  postedEcoActionPoint: Scalars['Int'];
  postedEcoActionPointLimitInDay?: Maybe<Scalars['Int']>;
  prefecture: Scalars['String'];
  prefectureEn?: Maybe<Scalars['String']>;
  quizAssignments?: Maybe<Array<QuizAssignment>>;
  salesRate: Scalars['Decimal'];
  street1: Scalars['String'];
  street1En?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street2En?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type CompanyActionActivityInput = {
  date: Scalars['DateTime'];
};

export type CompanyActionActivityOutput = {
  __typename?: 'CompanyActionActivityOutput';
  checkParticipantCount: Scalars['Float'];
  ecoActionPostCount: Scalars['Float'];
  employeeCount: Scalars['Float'];
  participantCount: Scalars['Float'];
  participationRate: Scalars['Float'];
  quizParticipantCount: Scalars['Float'];
};

export type CompanyActionDataHistory = {
  __typename?: 'CompanyActionDataHistory';
  actionActiveRate: Scalars['Float'];
  actions: Scalars['Float'];
  checkActiveRate: Scalars['Float'];
  checks: Scalars['Float'];
  position: Scalars['String'];
  quizActiveRate: Scalars['Float'];
  quizCorrectRate: Scalars['Float'];
  quizzes: Scalars['Float'];
  uniqueActions: Scalars['Float'];
};

export type CompanyActionHistoriesSummary = {
  __typename?: 'CompanyActionHistoriesSummary';
  totalConversionValue: Scalars['Float'];
  totalEcoActionCount: Scalars['Float'];
};

export type CompanyActionHistory = {
  __typename?: 'CompanyActionHistory';
  CheckCompanyActionHistory?: Maybe<Array<CheckCompanyActionHistory>>;
  _count: CompanyActionHistoryCount;
  checkActiveRate: Scalars['Decimal'];
  checkCount: Scalars['Int'];
  companyId: Scalars['String'];
  conversionValue: Scalars['Decimal'];
  date: Scalars['DateTime'];
  ecoActionActiveRate: Scalars['Decimal'];
  ecoActionCount: Scalars['Int'];
  employeeCount: Scalars['Int'];
  id: Scalars['ID'];
  quizActiveRate: Scalars['Decimal'];
  quizCorrectRate: Scalars['Decimal'];
  quizCount: Scalars['Int'];
  uniqueCheckCount: Scalars['Int'];
  uniqueEcoActionCount: Scalars['Int'];
};

export type CompanyActionHistoryCount = {
  __typename?: 'CompanyActionHistoryCount';
  CheckCompanyActionHistory: Scalars['Int'];
};

export type CompanyActionHistoryInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  interval: Interval;
  isIgnoreHoliday?: InputMaybe<Scalars['Boolean']>;
  isIgnoreWeekEnd?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyActionHistoryWithCheckedFlag = {
  __typename?: 'CompanyActionHistoryWithCheckedFlag';
  /** チェックしたデータ本体(8/10にチェックしたら8/9のデータが入る) */
  companyActionHistory?: Maybe<CompanyActionHistory>;
  /** Format: 'YYYY-MM-DD' / チェック日の日付 */
  date: Scalars['String'];
  /** dateが8/10なら8/10に、8/9のデータをチェックしたかどうかのフラグ */
  isChecked: Scalars['Boolean'];
};

export type CompanyActionStats = {
  __typename?: 'CompanyActionStats';
  averageActionActiveRate: Scalars['Decimal'];
  averageCheckActiveRate: Scalars['Decimal'];
  averageQuizActiveRate: Scalars['Decimal'];
  averageQuizCorrectRate: Scalars['Decimal'];
  totalActionCount: Scalars['Float'];
  totalCheckCount: Scalars['Float'];
  totalConversionValue: Scalars['Decimal'];
  totalQuizCount: Scalars['Float'];
  totalUniqueActionCount: Scalars['Float'];
};

export type CompanyActionStatsInput = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  isIgnoreHoliday?: InputMaybe<Scalars['Boolean']>;
  isIgnoreWeekEnd?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyCount = {
  __typename?: 'CompanyCount';
  CheckCompanyActionSummary: Scalars['Int'];
  apiKeys: Scalars['Int'];
  companyImages: Scalars['Int'];
  companySales: Scalars['Int'];
  companyUsers: Scalars['Int'];
  contributors: Scalars['Int'];
  departments: Scalars['Int'];
  ecoActionTemplateCategory: Scalars['Int'];
  quizAssignments: Scalars['Int'];
};

export type CompanyImage = {
  __typename?: 'CompanyImage';
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  order: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export type CompanySale = {
  __typename?: 'CompanySale';
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isDonation: Scalars['Boolean'];
  orderDetail: OrderDetail;
  orderDetailId: Scalars['String'];
  price: Scalars['Int'];
  status: CompanySaleStatus;
  updatedAt: Scalars['DateTime'];
};

export enum CompanySaleStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

export type CompanyUser = {
  __typename?: 'CompanyUser';
  CheckCompanyActionSummary?: Maybe<CheckCompanyActionSummary>;
  QuizAnswer?: Maybe<Array<QuizAnswer>>;
  QuizAnswerSummary?: Maybe<QuizAnswerSummary>;
  _count: CompanyUserCount;
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  department?: Maybe<Department>;
  departmentId?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firebaseUid: Scalars['String'];
  id: Scalars['ID'];
  invitationExpiredAt: Scalars['DateTime'];
  name: Scalars['String'];
  role: CompanyUserRole;
  status: CompanyUserStatus;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type CompanyUserActionHistoryInput = {
  companyUserId: Scalars['ID'];
  endDate?: InputMaybe<Scalars['DateTime']>;
  interval: Interval;
  isIgnoreHoliday?: InputMaybe<Scalars['Boolean']>;
  isIgnoreWeekEnd?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type CompanyUserCount = {
  __typename?: 'CompanyUserCount';
  QuizAnswer: Scalars['Int'];
};

export enum CompanyUserRole {
  Employee = 'EMPLOYEE',
  Manager = 'MANAGER',
  Owner = 'OWNER'
}

export type CompanyUserStats = {
  __typename?: 'CompanyUserStats';
  actionCount: Scalars['Int'];
  checkCount: Scalars['Int'];
  co2ReductionAction: Scalars['Float'];
  quizAccuracy: Scalars['Float'];
  quizAnswerCount: Scalars['Int'];
  quizAverageAccuracy: Scalars['Float'];
  quizTotalCount: Scalars['Int'];
};

export enum CompanyUserStatus {
  Accepted = 'ACCEPTED',
  Deleted = 'DELETED',
  Disabled = 'DISABLED',
  Invited = 'INVITED'
}

export type CompanyUsersInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  createdAtMax?: InputMaybe<Scalars['DateTime']>;
  createdAtMin?: InputMaybe<Scalars['DateTime']>;
  departmentId?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<Scalars['String']>;
  firebaseUid?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderType>;
  roleEnum?: InputMaybe<CompanyUserRole>;
  updatedAtMax?: InputMaybe<Scalars['DateTime']>;
  updatedAtMin?: InputMaybe<Scalars['DateTime']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type CompanyUsersPagination = {
  __typename?: 'CompanyUsersPagination';
  hasNextPage: Scalars['Boolean'];
  nodes?: Maybe<Array<CompanyUser>>;
  totalCount: Scalars['Int'];
};

export type CompanyYellHistoriesInput = {
  id: Scalars['ID'];
  startAt: Scalars['DateTime'];
  term: Scalars['String'];
};

export type CompanyYellHistoriesOutput = {
  __typename?: 'CompanyYellHistoriesOutput';
  comparisonToYesterdayRate: Scalars['Float'];
  histories: Array<YellsForDate>;
  latestDate?: Maybe<Scalars['DateTime']>;
  latestNumOfYells: Scalars['Float'];
};

export type Contributor = {
  __typename?: 'Contributor';
  _count: ContributorCount;
  checkContributorSummary?: Maybe<Array<CheckContributorSummary>>;
  checkDuration: ContributorCheckDuration;
  city?: Maybe<Scalars['String']>;
  cityEn?: Maybe<Scalars['String']>;
  co2PowerOffset: Scalars['Decimal'];
  co2PowerPerUnit: Scalars['Decimal'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  contributorTagTargets?: Maybe<Array<ContributorTagTarget>>;
  country: Country;
  createdAt: Scalars['DateTime'];
  daylightHours?: Maybe<Scalars['Decimal']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  descriptionEn?: Maybe<Scalars['String']>;
  genre: ContributorGenre;
  groundArea?: Maybe<Scalars['Decimal']>;
  hpUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCo2Reduction: Scalars['Boolean'];
  lat: Scalars['Decimal'];
  lng: Scalars['Decimal'];
  name: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
  operationStartedAt?: Maybe<Scalars['DateTime']>;
  operationStoppedAt?: Maybe<Scalars['DateTime']>;
  plantCapacityNumber: Scalars['Decimal'];
  plantId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  powerTotal?: Maybe<PowerTotal>;
  prefecture: Scalars['String'];
  prefectureEn?: Maybe<Scalars['String']>;
  solarPlantSpec?: Maybe<SolarPlantSpec>;
  status: ContributorStatus;
  street1?: Maybe<Scalars['String']>;
  street1En?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street2En?: Maybe<Scalars['String']>;
  type: ContributorType;
  updatedAt: Scalars['DateTime'];
  weatherHistories?: Maybe<Array<WeatherHistory>>;
};

export enum ContributorCheckDuration {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type ContributorCount = {
  __typename?: 'ContributorCount';
  checkContributorSummary: Scalars['Int'];
  contributorTagTargets: Scalars['Int'];
  weatherHistories: Scalars['Int'];
};

export type ContributorCreateInput = {
  city?: InputMaybe<Scalars['String']>;
  cityEn?: InputMaybe<Scalars['String']>;
  co2PowerOffset?: InputMaybe<Scalars['Decimal']>;
  co2PowerPerUnit: Scalars['Decimal'];
  country: Country;
  daylightHours?: InputMaybe<Scalars['Decimal']>;
  description?: InputMaybe<Scalars['String']>;
  descriptionEn?: InputMaybe<Scalars['String']>;
  genre: ContributorGenre;
  groundArea?: InputMaybe<Scalars['Decimal']>;
  hpUrl?: InputMaybe<Scalars['String']>;
  lat: Scalars['Decimal'];
  lng: Scalars['Decimal'];
  name: Scalars['String'];
  nameEn?: InputMaybe<Scalars['String']>;
  operationStartedAt?: InputMaybe<Scalars['DateTime']>;
  operationStoppedAt?: InputMaybe<Scalars['DateTime']>;
  plantCapacityNumber: Scalars['Decimal'];
  plantId: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  prefecture: Scalars['String'];
  prefectureEn?: InputMaybe<Scalars['String']>;
  status: ContributorStatus;
  street1?: InputMaybe<Scalars['String']>;
  street1En?: InputMaybe<Scalars['String']>;
  street2?: InputMaybe<Scalars['String']>;
  street2En?: InputMaybe<Scalars['String']>;
};

export type ContributorExtends = {
  __typename?: 'ContributorExtends';
  _count: ContributorCount;
  checkContributorSummary?: Maybe<Array<CheckContributorSummary>>;
  checkDuration: ContributorCheckDuration;
  city?: Maybe<Scalars['String']>;
  cityEn?: Maybe<Scalars['String']>;
  co2PowerOffset: Scalars['Decimal'];
  co2PowerPerUnit: Scalars['Decimal'];
  company?: Maybe<Company>;
  companyId?: Maybe<Scalars['String']>;
  contributorTagTargets?: Maybe<Array<ContributorTagTarget>>;
  country: Country;
  createdAt: Scalars['DateTime'];
  daylightHours?: Maybe<Scalars['Decimal']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  descriptionEn?: Maybe<Scalars['String']>;
  genre: ContributorGenre;
  groundArea?: Maybe<Scalars['Decimal']>;
  hpUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isCo2Reduction: Scalars['Boolean'];
  lat: Scalars['Decimal'];
  lng: Scalars['Decimal'];
  name: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
  operationStartedAt?: Maybe<Scalars['DateTime']>;
  operationStoppedAt?: Maybe<Scalars['DateTime']>;
  plantCapacityNumber: Scalars['Decimal'];
  plantId: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
  powerTotal?: Maybe<PowerTotal>;
  prefecture: Scalars['String'];
  prefectureEn?: Maybe<Scalars['String']>;
  products: Array<Product>;
  solarPlantSpec?: Maybe<SolarPlantSpec>;
  status: ContributorStatus;
  street1?: Maybe<Scalars['String']>;
  street1En?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  street2En?: Maybe<Scalars['String']>;
  type: ContributorType;
  updatedAt: Scalars['DateTime'];
  weatherHistories?: Maybe<Array<WeatherHistory>>;
};

export enum ContributorGenre {
  Factory = 'FACTORY',
  Home = 'HOME',
  Natural = 'NATURAL',
  Town = 'TOWN'
}

export type ContributorHeart = {
  __typename?: 'ContributorHeart';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export enum ContributorStatus {
  Maintenance = 'MAINTENANCE',
  Stop = 'STOP',
  Working = 'WORKING'
}

export type ContributorTag = {
  __typename?: 'ContributorTag';
  _count: ContributorTagCount;
  contributorTagCategory: ContributorTagCategory;
  contributorTagCategoryId: Scalars['String'];
  contributorTagTargets?: Maybe<Array<ContributorTagTarget>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ContributorTagCategory = {
  __typename?: 'ContributorTagCategory';
  _count: ContributorTagCategoryCount;
  contributorTags?: Maybe<Array<ContributorTag>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type ContributorTagCategoryCount = {
  __typename?: 'ContributorTagCategoryCount';
  contributorTags: Scalars['Int'];
};

export type ContributorTagCount = {
  __typename?: 'ContributorTagCount';
  contributorTagTargets: Scalars['Int'];
};

export type ContributorTagTarget = {
  __typename?: 'ContributorTagTarget';
  contributor: Contributor;
  contributorId: Scalars['String'];
  contributorTag: ContributorTag;
  contributorTagId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
};

export enum ContributorType {
  Office = 'OFFICE',
  Solar = 'SOLAR'
}

export type ContributorsPaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderType>;
};

export type ContributorsPaginationOutput = {
  __typename?: 'ContributorsPaginationOutput';
  hasNextPage: Scalars['Boolean'];
  nodes?: Maybe<Array<Contributor>>;
  totalCount: Scalars['Int'];
};

export enum Country {
  Ad = 'AD',
  Ae = 'AE',
  Af = 'AF',
  Ag = 'AG',
  Ai = 'AI',
  Al = 'AL',
  Am = 'AM',
  Ao = 'AO',
  Aq = 'AQ',
  Ar = 'AR',
  As = 'AS',
  At = 'AT',
  Au = 'AU',
  Aw = 'AW',
  Ax = 'AX',
  Az = 'AZ',
  Ba = 'BA',
  Bb = 'BB',
  Bd = 'BD',
  Be = 'BE',
  Bf = 'BF',
  Bg = 'BG',
  Bh = 'BH',
  Bi = 'BI',
  Bj = 'BJ',
  Bl = 'BL',
  Bm = 'BM',
  Bn = 'BN',
  Bo = 'BO',
  Bq = 'BQ',
  Br = 'BR',
  Bs = 'BS',
  Bt = 'BT',
  Bv = 'BV',
  Bw = 'BW',
  By = 'BY',
  Bz = 'BZ',
  Ca = 'CA',
  Cc = 'CC',
  Cd = 'CD',
  Cf = 'CF',
  Cg = 'CG',
  Ch = 'CH',
  Ci = 'CI',
  Ck = 'CK',
  Cl = 'CL',
  Cm = 'CM',
  Cn = 'CN',
  Co = 'CO',
  Cr = 'CR',
  Cu = 'CU',
  Cv = 'CV',
  Cw = 'CW',
  Cx = 'CX',
  Cy = 'CY',
  Cz = 'CZ',
  De = 'DE',
  Dj = 'DJ',
  Dk = 'DK',
  Dm = 'DM',
  Do = 'DO',
  Dz = 'DZ',
  Ec = 'EC',
  Ee = 'EE',
  Eg = 'EG',
  Eh = 'EH',
  Er = 'ER',
  Es = 'ES',
  Et = 'ET',
  Fi = 'FI',
  Fj = 'FJ',
  Fk = 'FK',
  Fm = 'FM',
  Fo = 'FO',
  Fr = 'FR',
  Ga = 'GA',
  Gb = 'GB',
  Gd = 'GD',
  Ge = 'GE',
  Gf = 'GF',
  Gg = 'GG',
  Gh = 'GH',
  Gi = 'GI',
  Gl = 'GL',
  Gm = 'GM',
  Gn = 'GN',
  Gp = 'GP',
  Gq = 'GQ',
  Gr = 'GR',
  Gs = 'GS',
  Gt = 'GT',
  Gu = 'GU',
  Gw = 'GW',
  Gy = 'GY',
  Hk = 'HK',
  Hm = 'HM',
  Hn = 'HN',
  Hr = 'HR',
  Ht = 'HT',
  Hu = 'HU',
  Id = 'ID',
  Ie = 'IE',
  Il = 'IL',
  Im = 'IM',
  In = 'IN',
  Io = 'IO',
  Iq = 'IQ',
  Ir = 'IR',
  Is = 'IS',
  It = 'IT',
  Je = 'JE',
  Jm = 'JM',
  Jo = 'JO',
  Jp = 'JP',
  Ke = 'KE',
  Kg = 'KG',
  Kh = 'KH',
  Ki = 'KI',
  Km = 'KM',
  Kn = 'KN',
  Kp = 'KP',
  Kr = 'KR',
  Kw = 'KW',
  Ky = 'KY',
  Kz = 'KZ',
  La = 'LA',
  Lb = 'LB',
  Lc = 'LC',
  Li = 'LI',
  Lk = 'LK',
  Lr = 'LR',
  Ls = 'LS',
  Lt = 'LT',
  Lu = 'LU',
  Lv = 'LV',
  Ly = 'LY',
  Ma = 'MA',
  Mc = 'MC',
  Md = 'MD',
  Me = 'ME',
  Mf = 'MF',
  Mg = 'MG',
  Mh = 'MH',
  Mk = 'MK',
  Ml = 'ML',
  Mm = 'MM',
  Mn = 'MN',
  Mo = 'MO',
  Mp = 'MP',
  Mq = 'MQ',
  Mr = 'MR',
  Ms = 'MS',
  Mt = 'MT',
  Mu = 'MU',
  Mv = 'MV',
  Mw = 'MW',
  Mx = 'MX',
  My = 'MY',
  Mz = 'MZ',
  Na = 'NA',
  Nc = 'NC',
  Ne = 'NE',
  Nf = 'NF',
  Ng = 'NG',
  Ni = 'NI',
  Nl = 'NL',
  No = 'NO',
  Np = 'NP',
  Nr = 'NR',
  Nu = 'NU',
  Nz = 'NZ',
  Om = 'OM',
  Pa = 'PA',
  Pe = 'PE',
  Pf = 'PF',
  Pg = 'PG',
  Ph = 'PH',
  Pk = 'PK',
  Pl = 'PL',
  Pm = 'PM',
  Pn = 'PN',
  Pr = 'PR',
  Ps = 'PS',
  Pt = 'PT',
  Pw = 'PW',
  Py = 'PY',
  Qa = 'QA',
  Re = 'RE',
  Ro = 'RO',
  Rs = 'RS',
  Ru = 'RU',
  Rw = 'RW',
  Sa = 'SA',
  Sb = 'SB',
  Sc = 'SC',
  Sd = 'SD',
  Se = 'SE',
  Sg = 'SG',
  Sh = 'SH',
  Si = 'SI',
  Sj = 'SJ',
  Sk = 'SK',
  Sl = 'SL',
  Sm = 'SM',
  Sn = 'SN',
  So = 'SO',
  Sr = 'SR',
  Ss = 'SS',
  St = 'ST',
  Sv = 'SV',
  Sx = 'SX',
  Sy = 'SY',
  Sz = 'SZ',
  Tc = 'TC',
  Td = 'TD',
  Tf = 'TF',
  Tg = 'TG',
  Th = 'TH',
  Tj = 'TJ',
  Tk = 'TK',
  Tl = 'TL',
  Tm = 'TM',
  Tn = 'TN',
  To = 'TO',
  Tr = 'TR',
  Tt = 'TT',
  Tv = 'TV',
  Tw = 'TW',
  Tz = 'TZ',
  Ua = 'UA',
  Ug = 'UG',
  Um = 'UM',
  Us = 'US',
  Uy = 'UY',
  Uz = 'UZ',
  Va = 'VA',
  Vc = 'VC',
  Ve = 'VE',
  Vg = 'VG',
  Vi = 'VI',
  Vn = 'VN',
  Vu = 'VU',
  Wf = 'WF',
  Ws = 'WS',
  Ye = 'YE',
  Yt = 'YT',
  Za = 'ZA',
  Zm = 'ZM',
  Zw = 'ZW'
}

export enum CourseIndexType {
  TokyoAirQuality = 'TokyoAirQuality'
}

export enum CrudType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Read = 'READ',
  Update = 'UPDATE'
}

export type DecodeIdToken = {
  __typename?: 'DecodeIdToken';
  aud: Scalars['String'];
  auth_time: Scalars['Int'];
  email?: Maybe<Scalars['String']>;
  email_verified?: Maybe<Scalars['Boolean']>;
  exp: Scalars['Int'];
  firebase: FirebaseInfo;
  iat: Scalars['Int'];
  isModalShown?: Maybe<Scalars['Boolean']>;
  isOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  isOnboardingOfCellsCompleted?: Maybe<Scalars['Boolean']>;
  isPasswordChanged?: Maybe<Scalars['Boolean']>;
  iss: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  role?: Maybe<CompanyUserRole>;
  sub: Scalars['String'];
  uid: Scalars['String'];
};

export type Department = {
  __typename?: 'Department';
  _count: DepartmentCount;
  company: Company;
  companyId: Scalars['String'];
  companyUsers?: Maybe<Array<CompanyUser>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type DepartmentCount = {
  __typename?: 'DepartmentCount';
  companyUsers: Scalars['Int'];
};

export type DepartmentInfoObject = {
  __typename?: 'DepartmentInfoObject';
  department: Department;
  id: Scalars['String'];
  rank: Scalars['Float'];
  value: Scalars['Float'];
};

export type DepartmentRankObject = {
  __typename?: 'DepartmentRankObject';
  id: Scalars['String'];
  info: DepartmentInfoObject;
  rank: Scalars['Float'];
};

export type DepartmentsInput = {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderType>;
};

export type DepartmentsPagination = {
  __typename?: 'DepartmentsPagination';
  hasNextPage: Scalars['Boolean'];
  nodes?: Maybe<Array<Department>>;
  totalCount: Scalars['Int'];
};

export type EcoAction = {
  __typename?: 'EcoAction';
  _count: EcoActionCount;
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  ecoActionLikes?: Maybe<Array<EcoActionLike>>;
  ecoActionTemplate?: Maybe<EcoActionTemplate>;
  ecoActionTemplateId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isTemplate: Scalars['Boolean'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type EcoActionCount = {
  __typename?: 'EcoActionCount';
  ecoActionLikes: Scalars['Int'];
};

export type EcoActionLike = {
  __typename?: 'EcoActionLike';
  createdAt: Scalars['DateTime'];
  ecoAction: EcoAction;
  ecoActionId: Scalars['String'];
  user: User;
  userId: Scalars['String'];
};

export type EcoActionLikeOutput = {
  __typename?: 'EcoActionLikeOutput';
  isLikedByMe: Scalars['Boolean'];
  totalLikesCount: Scalars['Int'];
};

export type EcoActionOutput = {
  __typename?: 'EcoActionOutput';
  content: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  ecoActionTemplate?: Maybe<EcoActionTemplate>;
  ecoActionTemplateId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isLikedByMe: Scalars['Boolean'];
  isTemplate: Scalars['Boolean'];
  totalLikesCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type EcoActionPercentagesByTemplate = {
  __typename?: 'EcoActionPercentagesByTemplate';
  templates: Array<Template>;
  totalCount: Scalars['Int'];
};

export type EcoActionSummary = {
  __typename?: 'EcoActionSummary';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  recalculateFlag: Scalars['Boolean'];
  totalConversionValue: Scalars['Decimal'];
  totalCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type EcoActionTemplate = {
  __typename?: 'EcoActionTemplate';
  _count: EcoActionTemplateCount;
  content: Scalars['String'];
  conversionValue?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  ecoActionTemplateCategory: EcoActionTemplateCategory;
  ecoActionTemplateCategoryId: Scalars['String'];
  ecoActions?: Maybe<Array<EcoAction>>;
  id: Scalars['ID'];
  postCount?: Maybe<Scalars['Int']>;
  status: EcoActionTemplateStatus;
  updatedAt: Scalars['DateTime'];
};

export type EcoActionTemplateCategory = {
  __typename?: 'EcoActionTemplateCategory';
  _count: EcoActionTemplateCategoryCount;
  company: Company;
  companyId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  ecoActionTemplates?: Maybe<Array<EcoActionTemplate>>;
  id: Scalars['ID'];
  name: Scalars['String'];
  status: EcoActionTemplateCategoryStatus;
  updatedAt: Scalars['DateTime'];
};

export type EcoActionTemplateCategoryCount = {
  __typename?: 'EcoActionTemplateCategoryCount';
  ecoActionTemplates: Scalars['Int'];
};

export enum EcoActionTemplateCategoryStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type EcoActionTemplateCount = {
  __typename?: 'EcoActionTemplateCount';
  ecoActions: Scalars['Int'];
};

export enum EcoActionTemplateStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE'
}

export type EcoActionsPaginationOutput = {
  __typename?: 'EcoActionsPaginationOutput';
  data: Array<EcoAction>;
  hasNextPage: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type Failures = {
  __typename?: 'Failures';
  /** Line number of the failure */
  line: Scalars['Float'];
  message: Scalars['String'];
};

export type FirebaseInfo = {
  __typename?: 'FirebaseInfo';
  identities: Array<Scalars['String']>;
  second_factor_identifier?: Maybe<Scalars['String']>;
  sign_in_provider: Scalars['String'];
  sign_in_second_factor?: Maybe<Scalars['String']>;
  tenant?: Maybe<Scalars['String']>;
};

export type GetInvestmentCoursesOutput = {
  __typename?: 'GetInvestmentCoursesOutput';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isActive: Scalars['Boolean'];
  name: Scalars['String'];
};

export type GiftCode = {
  __typename?: 'GiftCode';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  distributedAt?: Maybe<Scalars['DateTime']>;
  distributionAmount: Scalars['Decimal'];
  expiredAt: Scalars['DateTime'];
  id: Scalars['ID'];
  provider: GiftCodeProvider;
  requiredPoint: Scalars['Decimal'];
  status: GiftCodeStatus;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export enum GiftCodeProvider {
  Rakuten = 'rakuten'
}

export enum GiftCodeStatus {
  Distributed = 'DISTRIBUTED',
  Draft = 'DRAFT',
  Published = 'PUBLISHED'
}

export type GrantedPointOutput = {
  __typename?: 'GrantedPointOutput';
  grantedPoint: Scalars['Int'];
  pointLimitInDay?: Maybe<Scalars['Int']>;
};

export type IndividualInfoObject = {
  __typename?: 'IndividualInfoObject';
  companyUser: CompanyUser;
  id: Scalars['String'];
  rank: Scalars['Float'];
  user: User;
  value: Scalars['Float'];
};

export type IndividualRankObject = {
  __typename?: 'IndividualRankObject';
  id: Scalars['String'];
  info: IndividualInfoObject;
  rank: Scalars['Float'];
};

export enum Interval {
  Daily = 'DAILY',
  Monthly = 'MONTHLY',
  Weekly = 'WEEKLY',
  Yearly = 'YEARLY'
}

export type InvestmentCourse = {
  __typename?: 'InvestmentCourse';
  _count: InvestmentCourseCount;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  indexType: CourseIndexType;
  isReversePoint: Scalars['Boolean'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  users?: Maybe<Array<User>>;
  volatilityParam?: Maybe<Scalars['Decimal']>;
};

export type InvestmentCourseCount = {
  __typename?: 'InvestmentCourseCount';
  users: Scalars['Int'];
};

export type InvestmentLot = {
  __typename?: 'InvestmentLot';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  investmentPointSummary: InvestmentPointSummary;
  investmentPointSummaryId: Scalars['String'];
  orderIndex: Scalars['Decimal'];
  point: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
};

export type InvestmentPerformance = {
  __typename?: 'InvestmentPerformance';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  point: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type InvestmentPerformanceOutput = {
  __typename?: 'InvestmentPerformanceOutput';
  date: Scalars['DateTime'];
  point: Scalars['Float'];
};

export type InvestmentPerformancesInput = {
  term: Scalars['String'];
};

export enum InvestmentPointHistoriesActionType {
  Add = 'ADD',
  Reduce = 'REDUCE'
}

export type InvestmentPointHistory = {
  __typename?: 'InvestmentPointHistory';
  actionType: InvestmentPointHistoriesActionType;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  fee: Scalars['Decimal'];
  id: Scalars['ID'];
  investmentPointSummary: InvestmentPointSummary;
  investmentPointSummaryId: Scalars['String'];
  point: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
};

export type InvestmentPointSummary = {
  __typename?: 'InvestmentPointSummary';
  _count: InvestmentPointSummaryCount;
  balance: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  investmentLots?: Maybe<Array<InvestmentLot>>;
  investmentPointHistories?: Maybe<Array<InvestmentPointHistory>>;
  isActive: Scalars['Boolean'];
  totalPoint: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type InvestmentPointSummaryCount = {
  __typename?: 'InvestmentPointSummaryCount';
  investmentLots: Scalars['Int'];
  investmentPointHistories: Scalars['Int'];
};

export type Lot = {
  __typename?: 'Lot';
  _count: LotCount;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isForBonus: Scalars['Boolean'];
  isPickedUp: Scalars['Boolean'];
  name: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
  nfts?: Maybe<Array<Nft>>;
  product: Product;
  productId: Scalars['String'];
  publishedCount: Scalars['Int'];
  sellingPrice: Scalars['Int'];
  soldCount: Scalars['Int'];
  startAt: Scalars['DateTime'];
  startSerialNumber: Scalars['Int'];
  status: LotStatus;
  taxExcludedPrice: Scalars['Int'];
  taxPrice: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type LotCount = {
  __typename?: 'LotCount';
  nfts: Scalars['Int'];
};

export type LotExtends = {
  __typename?: 'LotExtends';
  _count: LotCount;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isForBonus: Scalars['Boolean'];
  isPickedUp: Scalars['Boolean'];
  name: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
  nfts?: Maybe<Array<Nft>>;
  product: ProductExtends;
  productId: Scalars['String'];
  publishedCount: Scalars['Int'];
  sellingPrice: Scalars['Int'];
  soldCount: Scalars['Int'];
  startAt: Scalars['DateTime'];
  startSerialNumber: Scalars['Int'];
  status: LotStatus;
  taxExcludedPrice: Scalars['Int'];
  taxPrice: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export enum LotStatus {
  Draft = 'DRAFT',
  NotForSale = 'NOT_FOR_SALE',
  OnSale = 'ON_SALE',
  SalesScheduled = 'SALES_SCHEDULED',
  SoldOut = 'SOLD_OUT'
}

export type MileSummary = {
  __typename?: 'MileSummary';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** CLIENT: テンプレートを使ったエコアクションの投稿 (付与したポイント数と1日の上限値を返す) */
  addEcoActionByTemplate: GrantedPointOutput;
  addPowerHistories: AddPowerHistoryOutput;
  /** CLIENT: クイズ回答 (正解ならyellPoint付与) */
  addQuizAnswer: AddQuizAnswerOutput;
  /** DASHBOARD: JSONデータで[企業独自クイズ]の複数作成 */
  addUniqueQuizzes: Array<AddQuizOutput>;
  /** POSTMAN: JSONデータで[企業独自クイズ]を代理で複数作成 */
  addUniqueQuizzesForDeveloper: Array<AddQuizOutput>;
  /** [POSTMAN: 一時的なmutationで使用後に削除予定] postCountを更新する */
  adminUpdatePostCount: Scalars['String'];
  /** for app：オンボーディング通過フラグのカスタムクレームを「完了」に変更する */
  appChangeIsOnboardingCompletedCustomClaim: Scalars['Boolean'];
  /** for app：Cellページのオンボーディング通過フラグのカスタムクレームを「完了」に変更する */
  appChangeIsOnboardingCustomClaimOfCells: Scalars['Boolean'];
  /** UserレコードのdeletedAtを更新する (nativeApp用) */
  appDeleteUser: Scalars['Boolean'];
  /** ユーザのメールアドレス変更 (nativeApp用) */
  appEmailChange: Scalars['String'];
  /** 認証コードのチェック & passwordResetToken返却 (nativeApp用) */
  appOTPCheckForPwdReset: Scalars['String'];
  /** Firebase上のユーザをコード認証する (nativeApp用) */
  appOnetimePassCheck: Scalars['String'];
  /** 認証コードのチェック & customToken返却 (nativeAppのパスワードリセット用) : appOTPCheckForPwdResetクエリに移行予定 */
  appOnetimePassCheckForPasswordReset: Scalars['String'];
  /** ユーザのパスワードリセット (nativeApp用) : appPwdResetクエリに移行予定 */
  appPasswordReset: Scalars['String'];
  /** ユーザのパスワードリセット (nativeApp用)  */
  appPwdReset: Scalars['String'];
  /** 認証コード発行 & メール送信まで (nativeAppのメールアドレス変更用) */
  appSendAuthCodeForEmailChange: Scalars['String'];
  /** 認証コード発行 & メール送信まで (nativeAppのパスワードリセット用) */
  appSendAuthCodeForPasswordReset: Scalars['String'];
  /** ユーザをBFFのDBに保存する用、appSignupFirebaseで登録後に1度だけ叩かれる (nativeApp用) */
  appSignupBff: Scalars['String'];
  /** ユーザをfirebaseに登録して、認証コード発行 & メール送信まで (nativeApp用) */
  appSignupFirebase: Scalars['String'];
  /** CLIENT: 指定日の企業アクティビティをチェックする */
  checkCompanyActionHistory: Scalars['String'];
  createActiveCellArea: Scalars['Int'];
  /** ADMIN (bajji) が企業のアクション履歴データを生成する */
  createPastCompanyActionHistories: Scalars['String'];
  dashboardCreateContributor: Scalars['Boolean'];
  dashboardCreateContributors: Scalars['Boolean'];
  /** DASHBOARD: companyUserを削除する */
  dashboardDeleteCompanyUser: Scalars['Boolean'];
  /** DASHBOARD: companyUserを無効にする */
  dashboardDisableCompanyUser: Scalars['Boolean'];
  /** DASHBOARD: companyUserを有効にする */
  dashboardEnableCompanyUser: Scalars['Boolean'];
  /** DASHBOARD: companyUserの情報を更新する */
  dashboardUpdateCompanyUser: Scalars['String'];
  /** DASHBOARD: emailを変更する */
  dashboardUpdateMyEmail: Scalars['Boolean'];
  /** CLIENT: 自分のエコアクションを削除 */
  deleteMyEcoAction: Scalars['String'];
  /** CustomerとPaymentMethodのデタッチ */
  detachPaymentMethod: PaymentMethodObject;
  makeSeedData: Scalars['String'];
  /** POSTMAN: 過去出題したクイズを再度出題する (一旦は選択肢の並び替えなし) */
  reassignQuizzesForDeveloper: Scalars['Int'];
  /** CLIENT: エコアクションのいいねを切り替え(付与/削除) */
  toggleEcoActionLike: EcoActionLikeOutput;
  /** [POSTMAN: 一時的なmutationで使用後に削除予定] 既存NFTのtokenIdを更新する */
  updateNftTokenIds: Scalars['String'];
  /** POSTMAN: JSONデータで[共通クイズ]の複数作成 */
  upsertQuizzesForDeveloper: Array<UpsertQuizOutput>;
  /** CLIENT: 招待期限チェック & ステータス更新 & 初期ユーザーデータ生成 (初回サインイン成功時に１度だけ使う) */
  webActivateEmployeeCompanyUser: Scalars['String'];
  /** DASHBOARD: 招待期限チェック & ステータス更新 & 初期ユーザーデータ生成 (初回サインイン成功時に１度だけ使う) */
  webActivateSupervisorCompanyUser: Scalars['String'];
  /** DASHBOARD: departmentの新規作成 */
  webAddDepartment: Department;
  /** 運用ポイント追加 */
  webAddInvestmentPoint: Scalars['Boolean'];
  /** WEB */
  webAddOrder: Order;
  /** for web：オンボーディング通過フラグのカスタムクレームを「完了」に変更する */
  webChangeIsOnboardingCompletedCustomClaim: Scalars['Boolean'];
  /** for web：Cellページのオンボーディング通過フラグのカスタムクレームを「完了」に変更する */
  webChangeIsOnboardingCustomClaimOfCells: Scalars['Boolean'];
  /** CLIENT: 自動生成されたパスワードをユーザー固有のパスワードに変更する */
  webChangePasswordForEmployee: Scalars['String'];
  /** DASHBOARD: 自動生成されたパスワードをユーザー固有のパスワードに変更する */
  webChangePasswordForSupervisor: Scalars['String'];
  /** WEB */
  webChangeToGiftCodes: Array<GiftCode>;
  /** 要認証 / 特定の施設データに関するチェックを実施する */
  webCheckContributorData: Scalars['Boolean'];
  /** DASHBOARD: departmentの削除 */
  webDeleteDepartment: Department;
  /** UserレコードのdeletedAtを更新する */
  webDeleteUser: Scalars['Boolean'];
  /** 要認証: ユーザのメールアドレス変更 */
  webEmailChange: Scalars['String'];
  /** DASHBOARD: [OWNERまたはMANAGER]が[EMPLOYEE]を作成し、ID/PASSをメール送付する */
  webInviteEmployee: Scalars['String'];
  /** DASHBOARD: [OWNER]が[MANAGER]を作成し、ID/PASSをメール送付する */
  webInviteManager: Scalars['String'];
  /** DASHBOARD: ADMIN (bajji) がOWNERを作成し、ID/PASSをメール送付する */
  webInviteOwner: Scalars['String'];
  /** 認証コードのチェック & passwordResetToken返却 */
  webOTPCheckForPwdReset: Scalars['String'];
  /** Firebase上のユーザをコード認証する */
  webOnetimePassCheck: Scalars['String'];
  /** 認証コードのチェック & customToken返却 : webOTPCheckForPwdResetクエリに移行予定 */
  webOnetimePassCheckForPasswordReset: Scalars['String'];
  /** ユーザのパスワードリセット : webPwdResetクエリに移行予定 */
  webPasswordReset: Scalars['String'];
  /** ユーザのパスワードリセット */
  webPwdReset: Scalars['String'];
  webRevealCell: Scalars['Boolean'];
  /** 認証コード発行 & メール送信まで */
  webSendAuthCodeForEmailChange: Scalars['String'];
  /** 認証コード発行 & メール送信まで */
  webSendAuthCodeForPasswordReset: Scalars['String'];
  /** 要認証 / 特定NFTの特定日付に対してエールを送る */
  webSendYell: YellNftHistoryOutput;
  /** ユーザをBFFのDBに保存する用、webSignupFirebaseで登録後に1度だけ叩かれる */
  webSignupBff: Scalars['String'];
  /** ユーザをfirebaseに登録して、認証コード発行 & メール送信まで */
  webSignupFirebase: Scalars['String'];
  /** DASHBOARD: departmentの更新 */
  webUpdateDepartment: Department;
  /** CLIENT: userのプロフィールを更新する */
  webUpdateProfile: Scalars['String'];
};


export type MutationAddEcoActionByTemplateArgs = {
  ecoActionTemplateId: Scalars['String'];
};


export type MutationAddPowerHistoriesArgs = {
  input: Array<AddPowerHistoryInput>;
};


export type MutationAddQuizAnswerArgs = {
  answer: Scalars['Int'];
  quizAssignmentId: Scalars['String'];
};


export type MutationAddUniqueQuizzesArgs = {
  inputs: Array<AddUniqueQuizInput>;
};


export type MutationAddUniqueQuizzesForDeveloperArgs = {
  companyId: Scalars['String'];
  inputs: Array<AddUniqueQuizInput>;
};


export type MutationAppDeleteUserArgs = {
  withdrawalReason: Scalars['String'];
};


export type MutationAppEmailChangeArgs = {
  authCode: Scalars['String'];
  newEmail: Scalars['String'];
};


export type MutationAppOtpCheckForPwdResetArgs = {
  authCode: Scalars['String'];
  email: Scalars['String'];
};


export type MutationAppOnetimePassCheckArgs = {
  authCode: Scalars['String'];
  email: Scalars['String'];
};


export type MutationAppOnetimePassCheckForPasswordResetArgs = {
  authCode: Scalars['String'];
  email: Scalars['String'];
};


export type MutationAppPasswordResetArgs = {
  authCode: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationAppPwdResetArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  passwordResetToken: Scalars['String'];
};


export type MutationAppSendAuthCodeForEmailChangeArgs = {
  email: Scalars['String'];
};


export type MutationAppSendAuthCodeForPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationAppSignupBffArgs = {
  deviceToken: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAppSignupFirebaseArgs = {
  email: Scalars['String'];
};


export type MutationCheckCompanyActionHistoryArgs = {
  companyActionHistoryId: Scalars['String'];
};


export type MutationCreateActiveCellAreaArgs = {
  bottomRightCoordinate: WebRevealCellInput;
  topLeftCoordinate: WebRevealCellInput;
};


export type MutationCreatePastCompanyActionHistoriesArgs = {
  companyId: Scalars['String'];
};


export type MutationDashboardCreateContributorArgs = {
  input: ContributorCreateInput;
};


export type MutationDashboardCreateContributorsArgs = {
  input: Array<Scalars['JSON']>;
};


export type MutationDashboardDeleteCompanyUserArgs = {
  id: Scalars['ID'];
};


export type MutationDashboardDisableCompanyUserArgs = {
  id: Scalars['ID'];
};


export type MutationDashboardEnableCompanyUserArgs = {
  id: Scalars['ID'];
};


export type MutationDashboardUpdateCompanyUserArgs = {
  departmentId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};


export type MutationDashboardUpdateMyEmailArgs = {
  email: Scalars['String'];
};


export type MutationDeleteMyEcoActionArgs = {
  id: Scalars['ID'];
};


export type MutationDetachPaymentMethodArgs = {
  paymentMethodId: Scalars['String'];
};


export type MutationMakeSeedDataArgs = {
  companyID: Scalars['String'];
};


export type MutationReassignQuizzesForDeveloperArgs = {
  companyId: Scalars['ID'];
};


export type MutationToggleEcoActionLikeArgs = {
  ecoActionId: Scalars['String'];
};


export type MutationUpdateNftTokenIdsArgs = {
  lotId: Scalars['String'];
  startTokenId: Scalars['Int'];
};


export type MutationUpsertQuizzesForDeveloperArgs = {
  inputs: Array<UpsertQuizInput>;
};


export type MutationWebAddDepartmentArgs = {
  name: Scalars['String'];
};


export type MutationWebAddInvestmentPointArgs = {
  input: AddInvestmentPointInput;
};


export type MutationWebAddOrderArgs = {
  orderCreateInput: OrderCreateInput;
  orderDetailCreateInput: Array<OrderDetailCreateInput>;
};


export type MutationWebChangePasswordForEmployeeArgs = {
  password: Scalars['String'];
};


export type MutationWebChangePasswordForSupervisorArgs = {
  password: Scalars['String'];
};


export type MutationWebChangeToGiftCodesArgs = {
  requestedPoints: Scalars['Int'];
};


export type MutationWebCheckContributorDataArgs = {
  targetDataId: Scalars['String'];
};


export type MutationWebDeleteDepartmentArgs = {
  id: Scalars['String'];
};


export type MutationWebDeleteUserArgs = {
  withdrawalReason: Scalars['String'];
};


export type MutationWebEmailChangeArgs = {
  authCode: Scalars['String'];
  newEmail: Scalars['String'];
};


export type MutationWebInviteEmployeeArgs = {
  departmentId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  name: Scalars['String'];
};


export type MutationWebInviteManagerArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
};


export type MutationWebInviteOwnerArgs = {
  companyId: Scalars['String'];
  email: Scalars['String'];
  name: Scalars['String'];
};


export type MutationWebOtpCheckForPwdResetArgs = {
  authCode: Scalars['String'];
  email: Scalars['String'];
};


export type MutationWebOnetimePassCheckArgs = {
  authCode: Scalars['String'];
  email: Scalars['String'];
};


export type MutationWebOnetimePassCheckForPasswordResetArgs = {
  authCode: Scalars['String'];
  email: Scalars['String'];
};


export type MutationWebPasswordResetArgs = {
  authCode: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationWebPwdResetArgs = {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  passwordResetToken: Scalars['String'];
};


export type MutationWebRevealCellArgs = {
  input: WebRevealCellInput;
};


export type MutationWebSendAuthCodeForEmailChangeArgs = {
  email: Scalars['String'];
};


export type MutationWebSendAuthCodeForPasswordResetArgs = {
  email: Scalars['String'];
};


export type MutationWebSendYellArgs = {
  contributorId: Scalars['ID'];
  date: Scalars['DateTime'];
  nftId: Scalars['ID'];
};


export type MutationWebSignupBffArgs = {
  password: Scalars['String'];
};


export type MutationWebSignupFirebaseArgs = {
  email: Scalars['String'];
};


export type MutationWebUpdateDepartmentArgs = {
  id: Scalars['String'];
  name: Scalars['String'];
};


export type MutationWebUpdateProfileArgs = {
  avatarUrl?: InputMaybe<Scalars['String']>;
  displayName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type MyCo2PointHistoriesPaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<OrderType>;
};

export type MyCo2PointHistoriesPaginationOutput = {
  __typename?: 'MyCo2PointHistoriesPaginationOutput';
  data: Array<Co2PointHistory>;
  hasNextPage: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type MyCustomClaimOutput = {
  __typename?: 'MyCustomClaimOutput';
  isModalShown?: Maybe<Scalars['Boolean']>;
  isOnboardingCompleted?: Maybe<Scalars['Boolean']>;
  isOnboardingOfCellsCompleted?: Maybe<Scalars['Boolean']>;
  isPasswordChanged?: Maybe<Scalars['Boolean']>;
  role?: Maybe<CompanyUserRole>;
};

export type MyRevealedCellPaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<OrderType>;
};

export type MyRevealedCellPaginationOutput = {
  __typename?: 'MyRevealedCellPaginationOutput';
  data: Array<RevealedCell>;
  hasNextPage: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type MyYellPointHistoriesPaginationInput = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  orderBy?: InputMaybe<OrderType>;
};

export type MyYellPointHistoriesPaginationOutput = {
  __typename?: 'MyYellPointHistoriesPaginationOutput';
  data: Array<YellPointHistory>;
  hasNextPage: Scalars['Boolean'];
  total: Scalars['Int'];
};

export type Nft = {
  __typename?: 'Nft';
  _count: NftCount;
  contentUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lot: Lot;
  lotId: Scalars['String'];
  orderDetail?: Maybe<Array<OrderDetail>>;
  originalContentUrl: Scalars['String'];
  serialNumber: Scalars['Int'];
  tokenId: Scalars['String'];
  type: NftType;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  yellNftSummary?: Maybe<YellNftSummary>;
};

export type NftCount = {
  __typename?: 'NftCount';
  orderDetail: Scalars['Int'];
};

export type NftObject = {
  __typename?: 'NftObject';
  _count: NftCount;
  contentUrl: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isOwned: Scalars['Boolean'];
  lot: LotExtends;
  lotId: Scalars['String'];
  orderDetail?: Maybe<Array<OrderDetail>>;
  originalContentUrl: Scalars['String'];
  serialNumber: Scalars['Int'];
  tokenId: Scalars['String'];
  type: NftType;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  yellNftSummary?: Maybe<YellNftSummary>;
};

export type NftObjectBiz = {
  __typename?: 'NftObjectBiz';
  _count: NftCount;
  checkCount: Scalars['Float'];
  contentUrl: Scalars['String'];
  contributor: Contributor;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lot: Lot;
  lotId: Scalars['String'];
  orderDetail?: Maybe<Array<OrderDetail>>;
  originalContentUrl: Scalars['String'];
  serialNumber: Scalars['Int'];
  tokenId: Scalars['String'];
  type: NftType;
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
  yellNftSummary?: Maybe<YellNftSummary>;
};

export enum NftType {
  Character = 'CHARACTER',
  Contributor = 'CONTRIBUTOR'
}

export type NonAuthUserObject = {
  __typename?: 'NonAuthUserObject';
  avatarUrl: Scalars['String'];
  displayName: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Notification = {
  __typename?: 'Notification';
  UserOnNotification?: Maybe<Array<UserOnNotification>>;
  _count: NotificationCount;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
};

export type NotificationCount = {
  __typename?: 'NotificationCount';
  UserOnNotification: Scalars['Int'];
};

export type OneOfRanksObject = {
  __typename?: 'OneOfRanksObject';
  /** 分母 */
  denominator: Scalars['Float'];
  rank: Scalars['Float'];
};

export type OnetimePass = {
  __typename?: 'OnetimePass';
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  expiredAt: Scalars['DateTime'];
  id: Scalars['ID'];
  updatedAt: Scalars['DateTime'];
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']>;
};

export type Order = {
  __typename?: 'Order';
  _count: OrderCount;
  amount: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  gmoTransactionId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  orderDetails?: Maybe<Array<OrderDetail>>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type OrderCount = {
  __typename?: 'OrderCount';
  orderDetails: Scalars['Int'];
};

export type OrderCreateInput = {
  point?: InputMaybe<Scalars['Int']>;
  token: Scalars['String'];
};

export type OrderDetail = {
  __typename?: 'OrderDetail';
  _count: OrderDetailCount;
  companySale?: Maybe<Array<CompanySale>>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  nft: Nft;
  nftId: Scalars['String'];
  noTax: Scalars['Int'];
  onlyTax: Scalars['Int'];
  order: Order;
  orderId: Scalars['String'];
  quantity: Scalars['Int'];
  sellingPrice: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type OrderDetailCount = {
  __typename?: 'OrderDetailCount';
  companySale: Scalars['Int'];
};

export type OrderDetailCreateInput = {
  nftId: Scalars['ID'];
};

/** Ordering options */
export enum OrderType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderType>;
};

export type PaymentMethodObject = {
  __typename?: 'PaymentMethodObject';
  brand: Scalars['String'];
  customer: Scalars['String'];
  expMonth: Scalars['Int'];
  expYear: Scalars['Int'];
  id: Scalars['String'];
  last4: Scalars['String'];
  name: Scalars['String'];
};

export enum PeriodType {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export type PowerDailyHistory = {
  __typename?: 'PowerDailyHistory';
  _count: PowerDailyHistoryCount;
  co2Power: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  fetchedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  power: Scalars['Decimal'];
  powerHourlyHistories?: Maybe<Array<PowerHourlyHistory>>;
  powerTotal: PowerTotal;
  powerTotalId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PowerDailyHistoryCount = {
  __typename?: 'PowerDailyHistoryCount';
  powerHourlyHistories: Scalars['Int'];
};

export type PowerDailyOrHourlyHistoryForGraphOutput = {
  __typename?: 'PowerDailyOrHourlyHistoryForGraphOutput';
  co2Power: Scalars['Decimal'];
  fetchedAt: Scalars['DateTime'];
  id?: Maybe<Scalars['ID']>;
  power: Scalars['Decimal'];
};

export type PowerHistory = {
  __typename?: 'PowerHistory';
  checkableFrom: Scalars['DateTime'];
  checkableTo: Scalars['DateTime'];
  co2Power: Scalars['Decimal'];
  collectionFrom: Scalars['DateTime'];
  collectionTo: Scalars['DateTime'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  power: Scalars['Decimal'];
  powerTotal: PowerTotal;
  powerTotalId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PowerHourlyHistory = {
  __typename?: 'PowerHourlyHistory';
  co2Power: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  fetchedAt: Scalars['DateTime'];
  id: Scalars['ID'];
  power: Scalars['Decimal'];
  powerDailyHistory: PowerDailyHistory;
  powerDailyHistoryId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type PowerTotal = {
  __typename?: 'PowerTotal';
  _count: PowerTotalCount;
  co2Power: Scalars['Decimal'];
  contributor: Contributor;
  contributorId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  power: Scalars['Decimal'];
  powerDailyHistories?: Maybe<Array<PowerDailyHistory>>;
  powerHistories?: Maybe<Array<PowerHistory>>;
  updatedAt: Scalars['DateTime'];
};

export type PowerTotalCount = {
  __typename?: 'PowerTotalCount';
  powerDailyHistories: Scalars['Int'];
  powerHistories: Scalars['Int'];
};

export type Product = {
  __typename?: 'Product';
  _count: ProductCount;
  chain: Chain;
  code: Scalars['String'];
  contractAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isDisplay: Scalars['Boolean'];
  lots?: Maybe<Array<Lot>>;
  name: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
  ownersCount: Scalars['Int'];
  productFavorite?: Maybe<Array<ProductFavorite>>;
  productImages?: Maybe<Array<ProductImage>>;
  productTagTargets?: Maybe<Array<ProductTagTarget>>;
  status: ProductStatus;
  targetId: Scalars['String'];
  targetType: ProductTargetType;
  tokenStandard: TokenStandard;
  updatedAt: Scalars['DateTime'];
};

export type ProductCount = {
  __typename?: 'ProductCount';
  lots: Scalars['Int'];
  productFavorite: Scalars['Int'];
  productImages: Scalars['Int'];
  productTagTargets: Scalars['Int'];
};

export type ProductExtends = {
  __typename?: 'ProductExtends';
  _count: ProductCount;
  chain: Chain;
  code: Scalars['String'];
  contractAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isDisplay: Scalars['Boolean'];
  lots?: Maybe<Array<Lot>>;
  name: Scalars['String'];
  nameEn?: Maybe<Scalars['String']>;
  ownersCount: Scalars['Int'];
  productFavorite?: Maybe<Array<ProductFavorite>>;
  productImages?: Maybe<Array<ProductImage>>;
  productTagTargets?: Maybe<Array<ProductTagTarget>>;
  status: ProductStatus;
  targetId: Scalars['String'];
  targetObject: TargetObjectUnion;
  targetType: ProductTargetType;
  tokenStandard: TokenStandard;
  updatedAt: Scalars['DateTime'];
};

export type ProductFavorite = {
  __typename?: 'ProductFavorite';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  product: Product;
  productId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type ProductImage = {
  __typename?: 'ProductImage';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  order: Scalars['Int'];
  product: Product;
  productId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  url: Scalars['String'];
};

export enum ProductStatus {
  Draft = 'DRAFT',
  NotForSale = 'NOT_FOR_SALE',
  OnSale = 'ON_SALE',
  SalesScheduled = 'SALES_SCHEDULED',
  SoldOut = 'SOLD_OUT'
}

export type ProductTag = {
  __typename?: 'ProductTag';
  _count: ProductTagCount;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  productTagCategory: ProductTagCategory;
  productTagCategoryId: Scalars['String'];
  productTagTargets?: Maybe<Array<ProductTagTarget>>;
  updatedAt: Scalars['DateTime'];
};

export type ProductTagCategory = {
  __typename?: 'ProductTagCategory';
  _count: ProductTagCategoryCount;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  productTags?: Maybe<Array<ProductTag>>;
  updatedAt: Scalars['DateTime'];
};

export type ProductTagCategoryCount = {
  __typename?: 'ProductTagCategoryCount';
  productTags: Scalars['Int'];
};

export type ProductTagCount = {
  __typename?: 'ProductTagCount';
  productTagTargets: Scalars['Int'];
};

export type ProductTagTarget = {
  __typename?: 'ProductTagTarget';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  product: Product;
  productId: Scalars['String'];
  productTag: ProductTag;
  productTagId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export enum ProductTargetType {
  Character = 'Character',
  Contributor = 'Contributor'
}

export type Query = {
  __typename?: 'Query';
  /** DASHBOARD: 部署別アクション数ランキング */
  actionDepartmentRanking: Array<DepartmentRankObject>;
  /** DASHBOARD: 個人アクション数ランキング */
  actionIndividualRanking: Array<IndividualRankObject>;
  /** 認証済みユーザのカスタムクレームを取得する (app) : appCustomClaimsクエリに移行予定 */
  appMyCustomClaim: Scalars['String'];
  /** 認証済みユーザのカスタムクレームを取得する (app) */
  appMyCustomClaims: MyCustomClaimOutput;
  /** NATIVE */
  appMyNfts: Array<NftObject>;
  /** 認証情報を使ってUserエンティティを取得する (nativeApp用) */
  appUserMe: User;
  /** CLIENT: 割り当てられたクイズ詳細 */
  assignedQuiz: AssignedQuizOutput;
  /** CLIENT: 割り当てられたクイズ一覧 */
  assignedQuizzes: Array<AssignedQuizzesOutput>;
  character: CharacterExtends;
  checkContributorDepartmentRanks: Array<DepartmentRankObject>;
  checkContributorIndividualRanks: Array<IndividualRankObject>;
  company: Company;
  contributor: ContributorExtends;
  /** CustomerのPaymentMethodsを一覧表示 */
  customerPaymentMethods: Array<PaymentMethodObject>;
  /** CLIENT: companyUserが所属しているcompanyが設定している定型エコアクションを一つランダム取得 */
  dailyEcoActionTemplate?: Maybe<EcoActionTemplate>;
  /** CLIENT: 企業に応じて1日1問のクイズを取得 */
  dailyQuiz?: Maybe<QuizAssignment>;
  dashboardContributorsPagination: ContributorsPaginationOutput;
  /** CLIENT: エコアクションの一件取得 */
  ecoAction: EcoActionOutput;
  /** CLIENT / DASHBOARD: テンプレートごとのエコアクション投稿割合を取得 */
  ecoActionPercentagesByTemplate: EcoActionPercentagesByTemplate;
  ecoActionSummaryRanks: Array<IndividualRankObject>;
  /** CLIENT: companyUserが所属しているcompanyが設定している定型エコアクションを一覧取得 */
  ecoActionTemplateCategories: Array<EcoActionTemplateCategory>;
  /** CLIENT: companyUserが所属しているcompanyが設定している定型エコアクションを一覧取得 */
  ecoActionTemplatesOrderByCount: Array<EcoActionTemplate>;
  /** CLIENT: companyUserが所属しているエコアクションを一覧取得 */
  ecoActionsPagination: EcoActionsPaginationOutput;
  /** APP: company単位でクイズ参加者数/チェック参加者数/エコアクション投稿数の集計 */
  getCompanyActionActivity: CompanyActionActivityOutput;
  /** DASHBOARD: company単位のアクション回数推移データ(拡張版) */
  getCompanyActionDataHistories: Array<CompanyActionDataHistory>;
  /** DASHBOARD: company単位のアクション回数推移データ */
  getCompanyActionHistories: Array<ActionHistory>;
  /** APP: company単位のアクション履歴データを自分がチェックしたかとともに返す */
  getCompanyActionHistoriesWithCheckedFlag: Array<CompanyActionHistoryWithCheckedFlag>;
  /** DASHBOARD: company単位のアクション統計値データ */
  getCompanyActionStats: CompanyActionStats;
  /** DASHBOARD: CompanyUser単位のアクション回数推移データ */
  getCompanyUserActionHistories: Array<ActionHistory>;
  /** 自分の企業アクティビティのチェック情報を取得 */
  myCheckCompanyActionSummary?: Maybe<CheckCompanyActionSummary>;
  /** CLIENT / DASHBOARD: テンプレートごとのエコアクション投稿割合を取得 */
  myEcoActionPercentagesByTemplate: EcoActionPercentagesByTemplate;
  oneOfYellSummaryPointRanks: OneOfRanksObject;
  /** DASHBOARD: クイズサマリーの部署ランキング */
  quizAnswerSummaryDepartmentRanks: Array<DepartmentRankObject>;
  /** DASHBOARD: クイズサマリーの個人ランキング */
  quizAnswerSummaryIndividualRanks: Array<IndividualRankObject>;
  /** DASHBOARD: 正答率のランキング部署単位 */
  quizAnswerTotalAccuracyDepartmentRanking: Array<DepartmentRankObject>;
  /** DASHBOARD: 正答率のランキング個人単位 */
  quizAnswerTotalAccuracyIndividualRanking: Array<IndividualRankObject>;
  /** DASHBOARD: クイズ結果一覧取得 */
  quizResults: Array<QuizResult>;
  /** エール総数を取得する */
  totalYellNftHistoriesCount: Scalars['Int'];
  webActiveCellAreas: Array<ActiveCellArea>;
  /** WEB_BIZ */
  webBizMyNfts: Array<NftObjectBiz>;
  /** WEB_BIZ */
  webBizNfts: Array<NftObjectBiz>;
  webCellsBySelfAndOthersRevealed: Array<CellWithIsSelfReveal>;
  /** DASHBOARD, CLIENT: user.nameの重複チェック */
  webCheckDuplicateUserName: Scalars['String'];
  /** APP: company単位のアクション回数集計データ */
  webCompanyActionHistoriesSummary: CompanyActionHistoriesSummary;
  webCompanyLots: Array<LotExtends>;
  webCompanyNfts: Array<Nft>;
  webCompanyTotalYells: Scalars['Float'];
  /** DASHBOARD: companyUser1件取得 */
  webCompanyUser: CompanyUser;
  /** CLIENT: MyPageのStats情報取得 */
  webCompanyUserMyStats: CompanyUserStats;
  /** CLIENT: UserPageのStats情報取得 */
  webCompanyUserStats: CompanyUserStats;
  /** DASHBOARD: ページネーションでcompanyUser複数取得 */
  webCompanyUsers: CompanyUsersPagination;
  webCompanyYellHistories: CompanyYellHistoriesOutput;
  /** 認証済みユーザのカスタムトークンを生成し、返す */
  webCustomToken: Scalars['String'];
  /** DASHBOARD: department1件取得 */
  webDepartment: Department;
  /** DASHBOARD: ページネーションでdepartment複数取得 */
  webDepartments: DepartmentsPagination;
  /** 累積寄附金額 */
  webDonationAmount: Scalars['Float'];
  /** 運用コースと各コースでユーザーが運用中かどうかを一覧取得する */
  webGetInvestmentCourses: Array<GetInvestmentCoursesOutput>;
  /** WEB */
  webGiftCodes: Array<GiftCode>;
  webInvestmentPerformances: Array<InvestmentPerformanceOutput>;
  webInvestmentPointHistories: Array<InvestmentPointHistory>;
  webInvestmentPointSummary?: Maybe<InvestmentPointSummary>;
  /** WEB */
  webLot: LotExtends;
  /** WEB */
  webLots: Array<LotExtends>;
  /** 要認証 / 自分(User)に紐づくCheckContributorHistoryを返す */
  webMyCheckContributorHistories: Array<CheckContributorHistory>;
  /** 要認証 / 自分(User)に紐づくCheckContributorSummaryを返す */
  webMyCheckContributorSummary: WebMyCheckContributorSummaryOutput;
  /** 要認証 / 自分(User)に紐づくCheckSummaryを返す */
  webMyCheckSummary: CheckSummary;
  /** 1ユーザーが獲得したCO2ポイントを取得する(ページネーション仕様) */
  webMyCo2PointHistoriesPagination: MyCo2PointHistoriesPaginationOutput;
  /** 要認証 / 自分(User)に紐づくCo2PointSummaryを返す */
  webMyCo2PointSummary: Co2PointSummaryOutput;
  /** 要認証 / 自分(User)に紐づくCo2PointSummary＋グラフ用のCo2PointHistoryを返す */
  webMyCo2PointSummaryForGraph: Co2PointSummaryOutputForGraph;
  /** 認証済みユーザのカスタムクレームを取得する */
  webMyCustomClaims: MyCustomClaimOutput;
  /** ユーザーの累積寄附金額 */
  webMyDonationAmount: Scalars['Float'];
  /** WEB */
  webMyNfts: Array<NftObject>;
  /** 1ユーザーがリビールした絶対値とセルのユニーク数を取得する */
  webMyNumOfRevealedCells: RevealedCellCount;
  /** WEB */
  webMyOrder?: Maybe<Order>;
  /** WEB */
  webMyOrders: Array<Order>;
  /** 1ユーザーでリビールしたcellを取得する(ページネーション仕様) */
  webMyRevealCellsPagination: MyRevealedCellPaginationOutput;
  /** 1ユーザーでリビールしたcellを全て取得する */
  webMyRevealedCells: Array<RevealedCell>;
  /** 認証済みユーザの連続チェック日数を取得する */
  webMySequentialCount: WebMySequentialCountOutput;
  /** 自分のNFTごとのエール履歴を取得する, データのない日付はpower/co2powerが-1で返却 */
  webMyYellNftHistories: Array<YellNftHistoryOutput>;
  /** 要認証 / あるNFTに対してのYellNftSummaryを取得する */
  webMyYellNftSummary: WebMyYellNftSummaryFromNftIdOutput;
  /** 1ユーザーが獲得したエールポイントを取得する(ページネーション仕様) */
  webMyYellPointHistoriesPagination: MyYellPointHistoriesPaginationOutput;
  /** 要認証 / 自分(User)に紐づくYellPointSummaryを返す */
  webMyYellPointSummary: YellPointSummaryOutput;
  /** 要認証 / 自分(User)に紐づくYellPointSummary＋グラフ用のYellPointHistoryを返す */
  webMyYellPointSummaryForGraph: YellPointSummaryOutputForGraph;
  /** 要認証 / 自分(User)に紐づくYellSummaryを返す */
  webMyYellSummary: YellSummary;
  /** WEB */
  webNft: NftObject;
  /** 全ユーザーによってリビールされたcellの総数を取得する */
  webNumOfRevealedCells: Scalars['Int'];
  webPowerDailyHistories: Array<PowerDailyHistory>;
  webPowerDailyHistoriesForGraph: Array<PowerDailyOrHourlyHistoryForGraphOutput>;
  webPowerHistories: Array<PowerHistory>;
  /** WEB */
  webProduct: ProductExtends;
  /** WEB */
  webProducts: Array<ProductExtends>;
  /** 1ユーザーがリビールしたcellを取得する */
  webRevealedCell: RevealedCellWithIsSelfReveal;
  /** 要認証 / 自分(User)が特定のNFTに対して特定の日付にエールを送ったかチェック */
  webSentYellOrNot: Scalars['Boolean'];
  webTotalActiveCellCount: Scalars['Int'];
  webTotalCellCount: Scalars['Float'];
  webTotalCompanyDetail: TotalCompanyDetailResponse;
  /** userIdから部分的なUserエンティティを取得する */
  webUser: NonAuthUserObject;
  /** 認証情報を使ってUserエンティティを取得する */
  webUserMe: User;
  /** 認証情報を使ってcompanyUserエンティティを取得する（toBサービス用） */
  webUserMeBiz: CompanyUser;
  /** WEB */
  webUserNfts: Array<NftObject>;
  /** idTokenを検証してデコードしたユーザー情報を返す */
  webVerifyIdToken: DecodeIdToken;
  /** CLIENT: 他ユーザーのYellPointSummaryを返す */
  webYellPointSummary: YellPointSummaryOutput;
  yellPointDepartmentRanks: Array<DepartmentRankObject>;
  /** DASHBOARD: 指定した期間の従業員のyellPointHistoryを集計する */
  yellPointHistoryRankingOfEmployeePerWeek: Array<YellPointSummaryRankingOutput>;
  yellPointIndividualRanks: Array<IndividualRankObject>;
  /** 指定したcompanyIdを持つユーザーのyellPointSummaryをtotalAmountで並び替える */
  yellPointSummaryRanking: Array<YellPointSummaryRankingOutput>;
  /** CLIENT: 指定したcompanyIdを持つユーザーのtotalAmountを部署ごとにまとめ、部署人数で割った1人あたりのポイント数で部署自体のランキングを作成する */
  yellPointSummaryRankingOfDepartmentPerPerson: Array<YellPointSummaryOutputOfDepartment>;
};


export type QueryAssignedQuizArgs = {
  quizAssignmentId: Scalars['String'];
};


export type QueryAssignedQuizzesArgs = {
  input?: InputMaybe<PaginationInput>;
};


export type QueryCharacterArgs = {
  id: Scalars['ID'];
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryContributorArgs = {
  id: Scalars['ID'];
};


export type QueryDashboardContributorsPaginationArgs = {
  input: ContributorsPaginationInput;
};


export type QueryEcoActionArgs = {
  id: Scalars['String'];
};


export type QueryEcoActionPercentagesByTemplateArgs = {
  aggregateTarget: AggregateTargetType;
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  targetId?: InputMaybe<Scalars['ID']>;
};


export type QueryEcoActionsPaginationArgs = {
  input: PaginationInput;
};


export type QueryGetCompanyActionActivityArgs = {
  input: CompanyActionActivityInput;
};


export type QueryGetCompanyActionDataHistoriesArgs = {
  input: CompanyActionHistoryInput;
};


export type QueryGetCompanyActionHistoriesArgs = {
  input: CompanyActionHistoryInput;
};


export type QueryGetCompanyActionHistoriesWithCheckedFlagArgs = {
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
};


export type QueryGetCompanyActionStatsArgs = {
  input: CompanyActionStatsInput;
};


export type QueryGetCompanyUserActionHistoriesArgs = {
  input: CompanyUserActionHistoryInput;
};


export type QueryMyEcoActionPercentagesByTemplateArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};


export type QueryOneOfYellSummaryPointRanksArgs = {
  targetId: Scalars['ID'];
};


export type QueryQuizResultsArgs = {
  input?: InputMaybe<QuizResultInput>;
};


export type QueryWebCellsBySelfAndOthersRevealedArgs = {
  input: RectangleOnMapInput;
};


export type QueryWebCheckDuplicateUserNameArgs = {
  name: Scalars['String'];
};


export type QueryWebCompanyLotsArgs = {
  id: Scalars['ID'];
};


export type QueryWebCompanyNftsArgs = {
  id: Scalars['ID'];
};


export type QueryWebCompanyTotalYellsArgs = {
  id: Scalars['ID'];
};


export type QueryWebCompanyUserArgs = {
  id: Scalars['String'];
};


export type QueryWebCompanyUserStatsArgs = {
  companyUserId: Scalars['String'];
};


export type QueryWebCompanyUsersArgs = {
  input: CompanyUsersInput;
};


export type QueryWebCompanyYellHistoriesArgs = {
  input: CompanyYellHistoriesInput;
};


export type QueryWebDepartmentArgs = {
  id: Scalars['String'];
};


export type QueryWebDepartmentsArgs = {
  input: DepartmentsInput;
};


export type QueryWebInvestmentPerformancesArgs = {
  input: InvestmentPerformancesInput;
};


export type QueryWebLotArgs = {
  id: Scalars['ID'];
};


export type QueryWebLotsArgs = {
  input: WebLotsInput;
};


export type QueryWebMyCheckContributorHistoriesArgs = {
  contributorId: Scalars['String'];
  dateFrom: Scalars['DateTime'];
  dateTo: Scalars['DateTime'];
};


export type QueryWebMyCheckContributorSummaryArgs = {
  contributorId: Scalars['String'];
};


export type QueryWebMyCo2PointHistoriesPaginationArgs = {
  input: MyCo2PointHistoriesPaginationInput;
};


export type QueryWebMyCo2PointSummaryForGraphArgs = {
  historyEndDate: Scalars['DateTime'];
  historyStartDate: Scalars['DateTime'];
};


export type QueryWebMyOrderArgs = {
  id: Scalars['ID'];
};


export type QueryWebMyRevealCellsPaginationArgs = {
  input: MyRevealedCellPaginationInput;
};


export type QueryWebMyYellNftHistoriesArgs = {
  endDate: Scalars['DateTime'];
  nftId: Scalars['String'];
  startDate: Scalars['DateTime'];
};


export type QueryWebMyYellNftSummaryArgs = {
  nftId: Scalars['ID'];
};


export type QueryWebMyYellPointHistoriesPaginationArgs = {
  input: MyYellPointHistoriesPaginationInput;
};


export type QueryWebMyYellPointSummaryForGraphArgs = {
  historyEndDate: Scalars['DateTime'];
  historyStartDate: Scalars['DateTime'];
};


export type QueryWebNftArgs = {
  id: Scalars['ID'];
};


export type QueryWebPowerDailyHistoriesArgs = {
  input: WebPowerDailyHistoryInput;
};


export type QueryWebPowerDailyHistoriesForGraphArgs = {
  input: WebPowerDailyHistoryInput;
};


export type QueryWebPowerHistoriesArgs = {
  input: WebPowerHistoriesInput;
};


export type QueryWebProductArgs = {
  id: Scalars['ID'];
};


export type QueryWebProductsArgs = {
  input: WebProductsInput;
};


export type QueryWebRevealedCellArgs = {
  input: WebRevealedCellInput;
};


export type QueryWebSentYellOrNotArgs = {
  date: Scalars['DateTime'];
  nftId: Scalars['ID'];
};


export type QueryWebTotalCompanyDetailArgs = {
  id: Scalars['ID'];
};


export type QueryWebUserArgs = {
  userId: Scalars['ID'];
};


export type QueryWebUserNftsArgs = {
  userId: Scalars['ID'];
};


export type QueryWebVerifyIdTokenArgs = {
  idToken: Scalars['String'];
};


export type QueryWebYellPointSummaryArgs = {
  companyUserId: Scalars['String'];
};


export type QueryYellPointHistoryRankingOfEmployeePerWeekArgs = {
  endAt: Scalars['DateTime'];
  startAt: Scalars['DateTime'];
};

export type Quiz = {
  __typename?: 'Quiz';
  _count: QuizCount;
  answer: Scalars['Int'];
  answerSource: Scalars['String'];
  category: QuizCategory;
  choice1: Scalars['String'];
  choice2: Scalars['String'];
  choice3?: Maybe<Scalars['String']>;
  choice4?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isGeneral: Scalars['Boolean'];
  question: Scalars['String'];
  quizAssignments?: Maybe<Array<QuizAssignment>>;
  status: QuizStatus;
  updatedAt: Scalars['DateTime'];
};

export type QuizAnswer = {
  __typename?: 'QuizAnswer';
  answer: Scalars['Int'];
  companyUser: CompanyUser;
  companyUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isCorrect: Scalars['Boolean'];
  quizAssignment: QuizAssignment;
  quizAssignmentId: Scalars['String'];
};

export type QuizAnswerSummary = {
  __typename?: 'QuizAnswerSummary';
  companyId: Scalars['String'];
  companyUser: CompanyUser;
  companyUserId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  totalAccuracy: Scalars['Decimal'];
  totalCorrectCount: Scalars['Int'];
  totalCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
};

export type QuizAssignment = {
  __typename?: 'QuizAssignment';
  _count: QuizAssignmentCount;
  answerCount?: Maybe<Scalars['Int']>;
  answerRate?: Maybe<Scalars['Decimal']>;
  choiceOrder: Scalars['String'];
  company: Company;
  companyId: Scalars['String'];
  correctAnswerCount?: Maybe<Scalars['Int']>;
  correctAnswerRate?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  dispatchAt: Scalars['DateTime'];
  id: Scalars['ID'];
  isShuffle: Scalars['Boolean'];
  point: Scalars['Int'];
  quiz: Quiz;
  quizAnswers?: Maybe<Array<QuizAnswer>>;
  quizAssignedCount: Scalars['Int'];
  quizId: Scalars['String'];
  status: QuizAssignmentStatus;
  updatedAt: Scalars['DateTime'];
};

export type QuizAssignmentCount = {
  __typename?: 'QuizAssignmentCount';
  quizAnswers: Scalars['Int'];
};

export enum QuizAssignmentStatus {
  Active = 'Active',
  Dispatched = 'Dispatched',
  Inactive = 'Inactive',
  Pending = 'Pending',
  ToBeFixed = 'ToBeFixed'
}

export enum QuizCategory {
  Agriculture = 'Agriculture',
  Air = 'Air',
  Climate = 'Climate',
  Food = 'Food',
  Forest = 'Forest',
  GreenProducts = 'GreenProducts',
  RenewableEnergy = 'RenewableEnergy',
  Transportation = 'Transportation',
  UpcyclingAndRecycling = 'UpcyclingAndRecycling',
  WasteReduction = 'WasteReduction',
  Water = 'Water'
}

export type QuizCount = {
  __typename?: 'QuizCount';
  quizAssignments: Scalars['Int'];
};

export type QuizResult = {
  __typename?: 'QuizResult';
  /** 回答数 */
  answeredCount: Scalars['Int'];
  /** 回答率 (回答数 / 社員数) */
  answeredPercentage: Scalars['Float'];
  /** 正答数 */
  correctCount: Scalars['Int'];
  /** 正答率 (正答数 / 回答数) */
  correctPercentage: Scalars['Float'];
  /** [YYYY-MM-DD or YYYY-MM or YYYY] の文字列 */
  position: Scalars['String'];
};

export type QuizResultInput = {
  aggregateTarget: AggregateTargetType;
  endDate?: InputMaybe<Scalars['DateTime']>;
  period: PeriodType;
  startDate?: InputMaybe<Scalars['DateTime']>;
  /** departmentId or companyUserId */
  targetId?: InputMaybe<Scalars['String']>;
};

export enum QuizStatus {
  Active = 'Active',
  Created = 'Created',
  Inactive = 'Inactive'
}

export type RectangleOnMapInput = {
  downRightLat: Scalars['Decimal'];
  downRightLng: Scalars['Decimal'];
  upLeftLat: Scalars['Decimal'];
  upLeftLng: Scalars['Decimal'];
};

export type RevealedCell = {
  __typename?: 'RevealedCell';
  cell: Cell;
  cellId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  imageURL?: Maybe<Scalars['String']>;
  prompt: Scalars['String'];
  status: RevealedCellStatus;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  year: Scalars['Int'];
};

export type RevealedCellCount = {
  __typename?: 'RevealedCellCount';
  total: Scalars['Int'];
  unique: Scalars['Int'];
};

export enum RevealedCellStatus {
  Done = 'DONE',
  Generating = 'GENERATING',
  Resizing = 'RESIZING'
}

export type RevealedCellWithIsSelfReveal = {
  __typename?: 'RevealedCellWithIsSelfReveal';
  cell: Cell;
  cellId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  imageURL?: Maybe<Scalars['String']>;
  isSelfRevealed: Scalars['Boolean'];
  prompt: Scalars['String'];
  status: RevealedCellStatus;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  year: Scalars['Int'];
};

export type SolarPlantSpec = {
  __typename?: 'SolarPlantSpec';
  contributor: Contributor;
  contributorId: Scalars['String'];
  id: Scalars['ID'];
  panelEfficiency: Scalars['Decimal'];
  panelTilt: Scalars['Decimal'];
};

export type TargetObjectUnion = Character | Contributor;

export type Template = {
  __typename?: 'Template';
  content: Scalars['String'];
  count: Scalars['Int'];
  percentage: Scalars['Float'];
};

export enum TokenStandard {
  Erc_721 = 'ERC_721',
  Erc_1155 = 'ERC_1155'
}

export type TotalCompanyDetailResponse = {
  __typename?: 'TotalCompanyDetailResponse';
  co2ReductionPower: Scalars['Float'];
  totalCO2Reduction: Scalars['Float'];
  totalCompanyContributors: Scalars['Float'];
  totalCompanyNfts: Scalars['Float'];
  totalCompanyYells: Scalars['Float'];
  uniqueNftUsers: Scalars['Float'];
};

export type UpsertQuizInput = {
  answer: Scalars['Int'];
  answerSource: Scalars['String'];
  category: QuizCategory;
  choice1: Scalars['String'];
  choice2: Scalars['String'];
  choice3?: InputMaybe<Scalars['String']>;
  choice4?: InputMaybe<Scalars['String']>;
  companyId?: InputMaybe<Scalars['String']>;
  dispatchAt?: InputMaybe<Scalars['DateTime']>;
  index?: InputMaybe<Scalars['Int']>;
  point?: InputMaybe<Scalars['Int']>;
  question: Scalars['String'];
  quizAssignmentId?: InputMaybe<Scalars['String']>;
  quizAssignmentStatus?: InputMaybe<QuizAssignmentStatus>;
  quizId?: InputMaybe<Scalars['String']>;
  quizStatus: QuizStatus;
};

export type UpsertQuizOutput = {
  __typename?: 'UpsertQuizOutput';
  companyIds?: Maybe<Array<Scalars['String']>>;
  crud?: Maybe<CrudType>;
  error?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  question: Scalars['String'];
  success: Scalars['Boolean'];
};

export type User = {
  __typename?: 'User';
  AchievementHistory?: Maybe<Array<AchievementHistory>>;
  AnnouncementOnUser?: Maybe<Array<UserOnAnnouncement>>;
  CheckSummary?: Maybe<Array<CheckSummary>>;
  Co2PointSummary?: Maybe<Array<Co2PointSummary>>;
  CompanyUser?: Maybe<CompanyUser>;
  ContributorHeart?: Maybe<Array<ContributorHeart>>;
  GiftCode?: Maybe<Array<GiftCode>>;
  Nft?: Maybe<Array<Nft>>;
  NotificationOnUser?: Maybe<Array<UserOnNotification>>;
  OnetimePass?: Maybe<OnetimePass>;
  ProductFavorite?: Maybe<Array<ProductFavorite>>;
  RevealedCells?: Maybe<Array<RevealedCell>>;
  YellPointSummary?: Maybe<Array<YellPointSummary>>;
  YellSummary?: Maybe<Array<YellSummary>>;
  _count: UserCount;
  avatarUrl?: Maybe<Scalars['String']>;
  bin?: Maybe<Bin>;
  city?: Maybe<Scalars['String']>;
  country: Country;
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deviceToken: Scalars['String'];
  displayName: Scalars['String'];
  ecoActionLikes?: Maybe<Array<EcoActionLike>>;
  ecoActionSummary?: Maybe<EcoActionSummary>;
  ecoActions?: Maybe<Array<EcoAction>>;
  email: Scalars['String'];
  firebaseUid: Scalars['String'];
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  investmentCourse?: Maybe<InvestmentCourse>;
  investmentCourseId?: Maybe<Scalars['String']>;
  investmentPerformances?: Maybe<Array<InvestmentPerformance>>;
  investmentPointSummary?: Maybe<InvestmentPointSummary>;
  isEmailSend: Scalars['Boolean'];
  lastName?: Maybe<Scalars['String']>;
  mileSummary?: Maybe<MileSummary>;
  name: Scalars['String'];
  orders?: Maybe<Array<Order>>;
  passwordResetToken?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
  sequentialCheckCount: Scalars['Int'];
  sequentialCheckStartedAt?: Maybe<Scalars['DateTime']>;
  status: UserStatus;
  street1?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  walletAddress?: Maybe<Scalars['String']>;
  withdrawalReason?: Maybe<Scalars['String']>;
};

export type UserCount = {
  __typename?: 'UserCount';
  AchievementHistory: Scalars['Int'];
  AnnouncementOnUser: Scalars['Int'];
  CheckSummary: Scalars['Int'];
  Co2PointSummary: Scalars['Int'];
  ContributorHeart: Scalars['Int'];
  GiftCode: Scalars['Int'];
  Nft: Scalars['Int'];
  NotificationOnUser: Scalars['Int'];
  ProductFavorite: Scalars['Int'];
  RevealedCells: Scalars['Int'];
  YellPointSummary: Scalars['Int'];
  YellSummary: Scalars['Int'];
  ecoActionLikes: Scalars['Int'];
  ecoActions: Scalars['Int'];
  investmentPerformances: Scalars['Int'];
  orders: Scalars['Int'];
};

export type UserOnAnnouncement = {
  __typename?: 'UserOnAnnouncement';
  announcement: Announcement;
  announcementId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export type UserOnNotification = {
  __typename?: 'UserOnNotification';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  notification: Notification;
  notificationId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
};

export enum UserStatus {
  Deleted = 'DELETED',
  Registered = 'REGISTERED'
}

export enum Weather {
  Atmosphere = 'ATMOSPHERE',
  Clear = 'CLEAR',
  Clouds = 'CLOUDS',
  Drizzle = 'DRIZZLE',
  Rain = 'RAIN',
  Snow = 'SNOW',
  Thunderstorm = 'THUNDERSTORM'
}

export type WeatherHistory = {
  __typename?: 'WeatherHistory';
  contributor: Contributor;
  contributorId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  fetchedAt: Scalars['DateTime'];
  humidity: Scalars['Int'];
  id: Scalars['ID'];
  pressure: Scalars['Int'];
  temperature: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  weather: Weather;
  windSpeed: Scalars['Float'];
};

export type WebLotsInput = {
  isForBonus?: InputMaybe<Scalars['Boolean']>;
  isPickedUp?: InputMaybe<Scalars['Boolean']>;
  productId?: InputMaybe<Scalars['ID']>;
};

export type WebMyCheckContributorSummaryOutput = {
  __typename?: 'WebMyCheckContributorSummaryOutput';
  checkContributorSummary: CheckContributorSummary;
  /** 今年のチェック回数 */
  thisYearCount: Scalars['Int'];
};

export type WebMySequentialCountOutput = {
  __typename?: 'WebMySequentialCountOutput';
  sequentialCount: Scalars['Float'];
  sequentialStartedAt?: Maybe<Scalars['DateTime']>;
};

export type WebMyYellNftSummaryFromNftIdOutput = {
  __typename?: 'WebMyYellNftSummaryFromNftIdOutput';
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  nftId: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  yellCount: Scalars['Int'];
  yellSummaryId: Scalars['String'];
  yellTotalCountOfThisYear: Scalars['Int'];
};

export type WebPowerDailyHistoryInput = {
  endAt: Scalars['DateTime'];
  powerTotalId: Scalars['ID'];
  startAt: Scalars['DateTime'];
};

export type WebPowerHistoriesInput = {
  contributorId: Scalars['String'];
  limit?: Scalars['Float'];
  offset?: Scalars['Float'];
};

export type WebProductsInput = {
  status?: InputMaybe<Array<ProductStatus>>;
};

export type WebRevealCellInput = {
  lat: Scalars['Decimal'];
  lng: Scalars['Decimal'];
};

export type WebRevealedCellInput = {
  lat: Scalars['Decimal'];
  lng: Scalars['Decimal'];
};

export type YellNftHistory = {
  __typename?: 'YellNftHistory';
  co2Power: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  power: Scalars['Decimal'];
  targetDate: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  yellNftSummary: YellNftSummary;
  yellNftSummaryId: Scalars['String'];
};

export type YellNftHistoryOutput = {
  __typename?: 'YellNftHistoryOutput';
  co2Power: Scalars['Float'];
  id?: Maybe<Scalars['String']>;
  power: Scalars['Float'];
  targetDate: Scalars['DateTime'];
};

export type YellNftSummary = {
  __typename?: 'YellNftSummary';
  _count: YellNftSummaryCount;
  co2Power: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  nft: Nft;
  nftId: Scalars['String'];
  power: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
  yellCount: Scalars['Decimal'];
  yellNftHistory?: Maybe<Array<YellNftHistory>>;
  yellSummary: YellSummary;
  yellSummaryId: Scalars['String'];
};

export type YellNftSummaryCount = {
  __typename?: 'YellNftSummaryCount';
  yellNftHistory: Scalars['Int'];
};

export type YellPointHistoriesOutput = {
  __typename?: 'YellPointHistoriesOutput';
  amount?: Maybe<Scalars['Decimal']>;
  balance: Scalars['Decimal'];
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  targetDate: Scalars['DateTime'];
  totalAmount: Scalars['Decimal'];
  type?: Maybe<YellPointHistoryType>;
};

export type YellPointHistory = {
  __typename?: 'YellPointHistory';
  amount: Scalars['Decimal'];
  balance?: Maybe<Scalars['Decimal']>;
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  targetId: Scalars['String'];
  targetType: YellPointHistoryTargetType;
  totalAmount: Scalars['Decimal'];
  type: YellPointHistoryType;
  updatedAt: Scalars['DateTime'];
  yellPointSummary: YellPointSummary;
  yellPointSummaryId: Scalars['String'];
};

export enum YellPointHistoryTargetType {
  CheckCompanyActionHistory = 'CheckCompanyActionHistory',
  CheckContributorHistory = 'CheckContributorHistory',
  EcoAction = 'EcoAction',
  GiftCode = 'GiftCode',
  InvestmentPointHistory = 'InvestmentPointHistory',
  Order = 'Order',
  QuizAnswer = 'QuizAnswer',
  YellNftHistory = 'YellNftHistory'
}

export enum YellPointHistoryType {
  Add = 'ADD',
  Reduce = 'REDUCE',
  Refund = 'REFUND'
}

export type YellPointSummary = {
  __typename?: 'YellPointSummary';
  _count: YellPointSummaryCount;
  balance: Scalars['Decimal'];
  id: Scalars['ID'];
  totalAmount: Scalars['Decimal'];
  user: User;
  userId: Scalars['String'];
  yellPointHistory?: Maybe<Array<YellPointHistory>>;
};

export type YellPointSummaryCount = {
  __typename?: 'YellPointSummaryCount';
  yellPointHistory: Scalars['Int'];
};

export type YellPointSummaryOutput = {
  __typename?: 'YellPointSummaryOutput';
  amountOfThisYear: Scalars['Decimal'];
  balance: Scalars['Decimal'];
  id: Scalars['String'];
  totalAmount: Scalars['Decimal'];
  userId: Scalars['String'];
};

export type YellPointSummaryOutputForGraph = {
  __typename?: 'YellPointSummaryOutputForGraph';
  amountOfThisYear: Scalars['Decimal'];
  balance: Scalars['Decimal'];
  id: Scalars['String'];
  totalAmount: Scalars['Decimal'];
  userId: Scalars['String'];
  yellPointHistories: Array<YellPointHistoriesOutput>;
};

export type YellPointSummaryOutputOfDepartment = {
  __typename?: 'YellPointSummaryOutputOfDepartment';
  averageAmount: Scalars['Float'];
  department: Department;
  rank: Scalars['Float'];
};

export type YellPointSummaryRankingOutput = {
  __typename?: 'YellPointSummaryRankingOutput';
  rank: Scalars['Float'];
  totalAmount: Scalars['Decimal'];
  user: User;
};

export type YellSummary = {
  __typename?: 'YellSummary';
  _count: YellSummaryCount;
  co2power: Scalars['Decimal'];
  createdAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  power: Scalars['Decimal'];
  updatedAt: Scalars['DateTime'];
  user: User;
  userId: Scalars['String'];
  yellCount: Scalars['Decimal'];
  yellNftSummary?: Maybe<Array<YellNftSummary>>;
};

export type YellSummaryCount = {
  __typename?: 'YellSummaryCount';
  yellNftSummary: Scalars['Int'];
};

export type YellsForDate = {
  __typename?: 'YellsForDate';
  date: Scalars['DateTime'];
  numOfYells: Scalars['Float'];
};

export type VerifyIdTokenQueryVariables = Exact<{
  idToken: Scalars['String'];
}>;


export type VerifyIdTokenQuery = { __typename?: 'Query', webVerifyIdToken: { __typename?: 'DecodeIdToken', aud: string, auth_time: number, email?: string | null, email_verified?: boolean | null, exp: number, iat: number, iss: string, phone_number?: string | null, picture?: string | null, sub: string, uid: string, isOnboardingCompleted?: boolean | null, isOnboardingOfCellsCompleted?: boolean | null, isModalShown?: boolean | null } };

export type TotalYellCountQueryVariables = Exact<{ [key: string]: never; }>;


export type TotalYellCountQuery = { __typename?: 'Query', totalYellNftHistoriesCount: number };

export type CustomClaimAuthContextFragment = { __typename?: 'MyCustomClaimOutput', isOnboardingCompleted?: boolean | null };

export type CustomClaimAuthContextQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomClaimAuthContextQuery = { __typename?: 'Query', webMyCustomClaims: { __typename?: 'MyCustomClaimOutput', isOnboardingCompleted?: boolean | null } };

export type ChartContentsLotFragment = { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', operationStartedAt?: DateString | null, createdAt: DateString, powerTotal?: { __typename?: 'PowerTotal', id: string } | null } } };

export type ChartContentPowerDailyHistoryFragment = { __typename?: 'PowerDailyHistory', power: number, fetchedAt: DateString, co2Power: number, powerHourlyHistories?: Array<{ __typename?: 'PowerHourlyHistory', power: number, fetchedAt: DateString, co2Power: number }> | null };

export type ChartContentPowerDailyHistoriesQueryVariables = Exact<{
  input: WebPowerDailyHistoryInput;
}>;


export type ChartContentPowerDailyHistoriesQuery = { __typename?: 'Query', webPowerDailyHistories: Array<{ __typename?: 'PowerDailyHistory', power: number, fetchedAt: DateString, co2Power: number, powerHourlyHistories?: Array<{ __typename?: 'PowerHourlyHistory', power: number, fetchedAt: DateString, co2Power: number }> | null }> };

export type ContributorDetailContentLotFragment = { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', status: ContributorStatus, groundArea?: number | null, operationStartedAt?: DateString | null, country: Country, prefecture: string, prefectureEn?: string | null, city?: string | null, cityEn?: string | null, street1?: string | null, street1En?: string | null, street2?: string | null, street2En?: string | null, lat: number, lng: number, createdAt: DateString } } };

export type ContributorMapContentLotFragment = { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', lat: number, lng: number } } };

export type ContributorPowerContentLotFragment = { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null } } };

export type OverviewContentsLotFragment = { __typename?: 'LotExtends', sellingPrice: number, product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, description?: string | null, descriptionEn?: string | null, company?: { __typename?: 'Company', description?: string | null, descriptionEn?: string | null, logoUrl?: string | null } | null } } };

export type OverviewContentsLotCountFragment = { __typename?: 'LotExtends', publishedCount: number, soldCount: number };

export type DetailLotFragment = { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', name: string, nameEn?: string | null, productImages?: Array<{ __typename?: 'ProductImage', url: string }> | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', status: ContributorStatus } }, nfts?: Array<{ __typename?: 'Nft', contentUrl: string }> | null };

export type WebLotCountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WebLotCountQuery = { __typename?: 'Query', webLot: { __typename?: 'LotExtends', publishedCount: number, soldCount: number } };

export type WebSignupBffMutationVariables = Exact<{
  password: Scalars['String'];
}>;


export type WebSignupBffMutation = { __typename?: 'Mutation', webSignupBff: string };

export type LotsPageWebProductFragment = { __typename?: 'ProductExtends', lots?: Array<{ __typename?: 'Lot', id: string, sellingPrice: number, publishedCount: number, soldCount: number, nfts?: Array<{ __typename?: 'Nft', contentUrl: string }> | null }> | null };

export type LotsWebProductFragment = { __typename?: 'ProductExtends', name: string, nameEn?: string | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null } };

export type LotsPageWebProductsQueryVariables = Exact<{
  input: WebProductsInput;
}>;


export type LotsPageWebProductsQuery = { __typename?: 'Query', webProducts: Array<{ __typename?: 'ProductExtends', name: string, nameEn?: string | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null }, lots?: Array<{ __typename?: 'Lot', id: string, sellingPrice: number, publishedCount: number, soldCount: number, nfts?: Array<{ __typename?: 'Nft', contentUrl: string }> | null }> | null }> };

export type Section5LotFragment = { __typename?: 'LotExtends', id: string, sellingPrice: number, publishedCount: number, soldCount: number, product: { __typename?: 'ProductExtends', id: string, name: string, nameEn?: string | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', status: ContributorStatus, groundArea?: number | null, operationStartedAt?: DateString | null, country: Country, prefecture: string, prefectureEn?: string | null, city?: string | null, cityEn?: string | null, street1?: string | null, street1En?: string | null, street2?: string | null, street2En?: string | null, lat: number, lng: number, createdAt: DateString, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null } }, nfts?: Array<{ __typename?: 'Nft', contentUrl: string }> | null };

export type Section5OtherLotFragment = { __typename?: 'LotExtends', id: string, sellingPrice: number, publishedCount: number, soldCount: number, nfts?: Array<{ __typename?: 'Nft', contentUrl: string }> | null };

export type WebLotsQueryVariables = Exact<{
  input: WebLotsInput;
}>;


export type WebLotsQuery = { __typename?: 'Query', webLots: Array<{ __typename?: 'LotExtends', id: string, sellingPrice: number, publishedCount: number, soldCount: number, product: { __typename?: 'ProductExtends', id: string, name: string, nameEn?: string | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', status: ContributorStatus, groundArea?: number | null, operationStartedAt?: DateString | null, country: Country, prefecture: string, prefectureEn?: string | null, city?: string | null, cityEn?: string | null, street1?: string | null, street1En?: string | null, street2?: string | null, street2En?: string | null, lat: number, lng: number, createdAt: DateString, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null } }, nfts?: Array<{ __typename?: 'Nft', contentUrl: string }> | null }> };

export type MyPageNftPageFragment = { __typename?: 'NftObject', id: string, contentUrl: string, yellNftSummary?: { __typename?: 'YellNftSummary', yellCount: number } | null, lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', name: string, nameEn?: string | null, targetObject: { __typename?: 'Character', id: string } | { __typename?: 'Contributor', id: string, plantCapacityNumber: number, co2PowerPerUnit: number, name: string, nameEn?: string | null, daylightHours?: number | null, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null } } } };

export type MyPageYellSummaryFragment = { __typename?: 'YellSummary', yellCount: number };

export type MyPageYellPointSummaryFragment = { __typename?: 'YellPointSummaryOutput', balance: number, totalAmount: number };

export type MyPageNftFragment = { __typename?: 'NftObject', updatedAt: DateString, lot: { __typename?: 'LotExtends', isForBonus: boolean, product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character', id: string } | { __typename?: 'Contributor', id: string, powerTotal?: { __typename?: 'PowerTotal', id: string } | null } } } };

export type WebMyNftsQueryVariables = Exact<{ [key: string]: never; }>;


export type WebMyNftsQuery = { __typename?: 'Query', webMyNfts: Array<{ __typename?: 'NftObject', updatedAt: DateString, id: string, contentUrl: string, lot: { __typename?: 'LotExtends', isForBonus: boolean, product: { __typename?: 'ProductExtends', name: string, nameEn?: string | null, targetObject: { __typename?: 'Character', id: string } | { __typename?: 'Contributor', id: string, plantCapacityNumber: number, co2PowerPerUnit: number, name: string, nameEn?: string | null, daylightHours?: number | null, powerTotal?: { __typename?: 'PowerTotal', id: string } | null, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null } } }, yellNftSummary?: { __typename?: 'YellNftSummary', yellCount: number } | null }> };

export type WebMyYellSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type WebMyYellSummaryQuery = { __typename?: 'Query', webMyYellSummary: { __typename?: 'YellSummary', yellCount: number } };

export type MyPageMyYellPointSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type MyPageMyYellPointSummaryQuery = { __typename?: 'Query', webMyYellPointSummary: { __typename?: 'YellPointSummaryOutput', balance: number, totalAmount: number } };

export type WebDonationAmountQueryVariables = Exact<{ [key: string]: never; }>;


export type WebDonationAmountQuery = { __typename?: 'Query', webDonationAmount: number };

export type WebMyDonationAmountQueryVariables = Exact<{ [key: string]: never; }>;


export type WebMyDonationAmountQuery = { __typename?: 'Query', webMyDonationAmount: number };

export type MyPagePowerDailyHistoryFragment = { __typename?: 'PowerDailyHistory', co2Power: number, powerTotal: { __typename?: 'PowerTotal', updatedAt: DateString } };

export type MyPagePowerDailyHistoriesQueryVariables = Exact<{
  input: WebPowerDailyHistoryInput;
}>;


export type MyPagePowerDailyHistoriesQuery = { __typename?: 'Query', webPowerDailyHistories: Array<{ __typename?: 'PowerDailyHistory', co2Power: number, powerTotal: { __typename?: 'PowerTotal', updatedAt: DateString } }> };

export type MyCustomClaimFragment = { __typename?: 'MyCustomClaimOutput', isModalShown?: boolean | null };

export type WebMyCustomClaimsQueryVariables = Exact<{ [key: string]: never; }>;


export type WebMyCustomClaimsQuery = { __typename?: 'Query', webMyCustomClaims: { __typename?: 'MyCustomClaimOutput', isModalShown?: boolean | null } };

export type PurchaseCheckPageLotFragment = { __typename?: 'LotExtends', sellingPrice: number, product: { __typename?: 'ProductExtends', name: string, contractAddress?: string | null, tokenStandard: TokenStandard, chain: Chain, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', name: string, plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, status: ContributorStatus, groundArea?: number | null, operationStartedAt?: DateString | null, createdAt: DateString, country: Country, prefecture: string, city?: string | null, street1?: string | null, street2?: string | null, lat: number, lng: number } } };

export type PurchaseConfirmPageLotFragment = { __typename?: 'LotExtends', sellingPrice: number, product: { __typename?: 'ProductExtends', name: string, contractAddress?: string | null, tokenStandard: TokenStandard, chain: Chain, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', name: string, plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, status: ContributorStatus, groundArea?: number | null, operationStartedAt?: DateString | null, createdAt: DateString, country: Country, prefecture: string, city?: string | null, street1?: string | null, street2?: string | null, lat: number, lng: number } } };

export type PurchasePaymentPageLotFragment = { __typename?: 'LotExtends', sellingPrice: number };

export type PurchaseYellPointSummaryFragment = { __typename?: 'YellPointSummaryOutput', balance: number };

export type PurchaseRightContentLotFragment = { __typename?: 'LotExtends', sellingPrice: number, taxPrice: number, product: { __typename?: 'ProductExtends', name: string, targetObject: { __typename?: 'Character', name: string } | { __typename?: 'Contributor', name: string } } };

export type PurchaseSelectPageLotFragment = { __typename?: 'LotExtends', sellingPrice: number, nfts?: Array<{ __typename?: 'Nft', serialNumber: number, userId?: string | null, contentUrl: string, id: string, tokenId: string, originalContentUrl: string }> | null, product: { __typename?: 'ProductExtends', name: string, targetObject: { __typename?: 'Character', name: string } | { __typename?: 'Contributor', name: string } } };

export type PurchaseMyYellPointSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type PurchaseMyYellPointSummaryQuery = { __typename?: 'Query', webMyYellPointSummary: { __typename?: 'YellPointSummaryOutput', balance: number } };

export type PurchaseLotNftsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type PurchaseLotNftsQuery = { __typename?: 'Query', webLot: { __typename?: 'LotExtends', sellingPrice: number, taxPrice: number, nfts?: Array<{ __typename?: 'Nft', serialNumber: number, userId?: string | null, contentUrl: string, id: string, tokenId: string, originalContentUrl: string }> | null, product: { __typename?: 'ProductExtends', name: string, contractAddress?: string | null, tokenStandard: TokenStandard, chain: Chain, targetObject: { __typename?: 'Character', name: string } | { __typename?: 'Contributor', name: string, plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, status: ContributorStatus, groundArea?: number | null, operationStartedAt?: DateString | null, createdAt: DateString, country: Country, prefecture: string, city?: string | null, street1?: string | null, street2?: string | null, lat: number, lng: number } } } };

export type PurchaseWebAddOrderMutationVariables = Exact<{
  orderCreateInput: OrderCreateInput;
  orderDetailCreateInput: Array<OrderDetailCreateInput> | OrderDetailCreateInput;
}>;


export type PurchaseWebAddOrderMutation = { __typename?: 'Mutation', webAddOrder: { __typename?: 'Order', id: string } };

export type PasswordOnetimePassCheckMutationVariables = Exact<{
  authCode: Scalars['String'];
  email: Scalars['String'];
}>;


export type PasswordOnetimePassCheckMutation = { __typename?: 'Mutation', webOnetimePassCheckForPasswordReset: string };

export type WebPasswordResetMutationVariables = Exact<{
  authCode: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type WebPasswordResetMutation = { __typename?: 'Mutation', webPasswordReset: string };

export type WebSendAuthCodeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type WebSendAuthCodeMutation = { __typename?: 'Mutation', webSendAuthCodeForPasswordReset: string };

export type SendYellPageNftFragment = { __typename?: 'NftObject', updatedAt: DateString, contentUrl: string, serialNumber: number, lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', name: string, nameEn?: string | null, productImages?: Array<{ __typename?: 'ProductImage', url: string }> | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', status: ContributorStatus } } }, yellNftSummary?: { __typename?: 'YellNftSummary', yellCount: number } | null };

export type SendYellPagePowerDailyHistoryFragment = { __typename?: 'PowerDailyHistory', co2Power: number, power: number };

export type SendYellPageBottomLeftInfoFragment = { __typename?: 'NftObject', lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', status: ContributorStatus, name: string, nameEn?: string | null, groundArea?: number | null, operationStartedAt?: DateString | null, createdAt: DateString, country: Country, prefecture: string, prefectureEn?: string | null, city?: string | null, cityEn?: string | null, street1?: string | null, street1En?: string | null, street2?: string | null, street2En?: string | null, lat: number, lng: number, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null } } } };

export type SendYellPageDescriptionFragment = { __typename?: 'NftObject', lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', name: string, nameEn?: string | null, description?: string | null, descriptionEn?: string | null } } } };

export type SendYellPageContributorDetailContentFragment = { __typename?: 'NftObject', lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', status: ContributorStatus, groundArea?: number | null, operationStartedAt?: DateString | null, country: Country, prefecture: string, prefectureEn?: string | null, city?: string | null, cityEn?: string | null, street1?: string | null, street1En?: string | null, street2?: string | null, street2En?: string | null, lat: number, lng: number, createdAt: DateString } } } };

export type SendYellPageContributorInformationFragment = { __typename?: 'NftObject', lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', name: string, nameEn?: string | null, prefecture: string, prefectureEn?: string | null, city?: string | null, cityEn?: string | null, street1?: string | null, street1En?: string | null, street2?: string | null, street2En?: string | null, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null } } } };

export type SendYellPageContributorMapContentFragment = { __typename?: 'NftObject', lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', lat: number, lng: number } } } };

export type SendYellPageContributorOperatorFragment = { __typename?: 'NftObject', lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', company?: { __typename?: 'Company', id: string, description?: string | null, descriptionEn?: string | null, logoUrl?: string | null } | null } } } };

export type SendYellPageContributorPowerContentFragment = { __typename?: 'NftObject', lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null } } } };

export type SendYellPageNftDetailContentFragment = { __typename?: 'NftObject', serialNumber: number, tokenId: string, createdAt: DateString, lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', code: string, contractAddress?: string | null, tokenStandard: TokenStandard, chain: Chain } } };

export type YellChartContentPowerDailyHistoryFragment = { __typename?: 'PowerDailyHistory', power: number, fetchedAt: DateString, co2Power: number, powerHourlyHistories?: Array<{ __typename?: 'PowerHourlyHistory', power: number, fetchedAt: DateString, co2Power: number }> | null, powerTotal: { __typename?: 'PowerTotal', updatedAt: DateString } };

export type YellChartContentPowerDailyHistoriesQueryVariables = Exact<{
  input: WebPowerDailyHistoryInput;
}>;


export type YellChartContentPowerDailyHistoriesQuery = { __typename?: 'Query', webPowerDailyHistories: Array<{ __typename?: 'PowerDailyHistory', power: number, fetchedAt: DateString, co2Power: number, powerHourlyHistories?: Array<{ __typename?: 'PowerHourlyHistory', power: number, fetchedAt: DateString, co2Power: number }> | null, powerTotal: { __typename?: 'PowerTotal', updatedAt: DateString } }> };

export type SendYellNftFragment = { __typename?: 'NftObject', lot: { __typename?: 'LotExtends', isForBonus: boolean, product: { __typename?: 'ProductExtends', targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', id: string, createdAt: DateString, operationStartedAt?: DateString | null, plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, powerTotal?: { __typename?: 'PowerTotal', id: string } | null } } } };

export type GetContributorFromNftQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetContributorFromNftQuery = { __typename?: 'Query', webNft: { __typename?: 'NftObject', updatedAt: DateString, contentUrl: string, serialNumber: number, tokenId: string, createdAt: DateString, lot: { __typename?: 'LotExtends', isForBonus: boolean, product: { __typename?: 'ProductExtends', name: string, nameEn?: string | null, code: string, contractAddress?: string | null, tokenStandard: TokenStandard, chain: Chain, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', id: string, createdAt: DateString, operationStartedAt?: DateString | null, plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, status: ContributorStatus, name: string, nameEn?: string | null, groundArea?: number | null, country: Country, prefecture: string, prefectureEn?: string | null, city?: string | null, cityEn?: string | null, street1?: string | null, street1En?: string | null, street2?: string | null, street2En?: string | null, lat: number, lng: number, description?: string | null, descriptionEn?: string | null, powerTotal?: { __typename?: 'PowerTotal', id: string } | null, company?: { __typename?: 'Company', name: string, nameEn?: string | null, id: string, description?: string | null, descriptionEn?: string | null, logoUrl?: string | null } | null }, productImages?: Array<{ __typename?: 'ProductImage', url: string }> | null } }, yellNftSummary?: { __typename?: 'YellNftSummary', yellCount: number } | null } };

export type WebSentYellOrNotQueryVariables = Exact<{
  nftId: Scalars['ID'];
  date: Scalars['DateTime'];
}>;


export type WebSentYellOrNotQuery = { __typename?: 'Query', webSentYellOrNot: boolean };

export type WebSendYellMutationVariables = Exact<{
  nftId: Scalars['ID'];
  date: Scalars['DateTime'];
  contributorId: Scalars['ID'];
}>;


export type WebSendYellMutation = { __typename?: 'Mutation', webSendYell: { __typename?: 'YellNftHistoryOutput', id?: string | null } };

export type SendYellPowerDailyHistoryFragment = { __typename?: 'PowerDailyHistory', co2Power: number };

export type SendYellPowerDailyHistoriesQueryVariables = Exact<{
  input: WebPowerDailyHistoryInput;
}>;


export type SendYellPowerDailyHistoriesQuery = { __typename?: 'Query', webPowerDailyHistories: Array<{ __typename?: 'PowerDailyHistory', co2Power: number, power: number }> };

export type SettingsUserFragment = { __typename?: 'User', name: string, email: string, createdAt: DateString };

export type SettingsWebUserMeQueryVariables = Exact<{ [key: string]: never; }>;


export type SettingsWebUserMeQuery = { __typename?: 'Query', webUserMe: { __typename?: 'User', name: string, email: string, createdAt: DateString } };

export type WebEmailChangeMutationVariables = Exact<{
  newEmail: Scalars['String'];
  authCode: Scalars['String'];
}>;


export type WebEmailChangeMutation = { __typename?: 'Mutation', webEmailChange: string };

export type OrderHistoryWebMyOrderFragment = { __typename?: 'Order', createdAt: DateString, orderDetails?: Array<{ __typename?: 'OrderDetail', sellingPrice: number, nft: { __typename?: 'Nft', id: string, contentUrl: string, serialNumber: number, lot: { __typename?: 'Lot', product: { __typename?: 'Product', name: string, nameEn?: string | null, code: string } } } }> | null };

export type OrderHistoryWebMyOrdersQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderHistoryWebMyOrdersQuery = { __typename?: 'Query', webMyOrders: Array<{ __typename?: 'Order', createdAt: DateString, orderDetails?: Array<{ __typename?: 'OrderDetail', sellingPrice: number, nft: { __typename?: 'Nft', id: string, contentUrl: string, serialNumber: number, lot: { __typename?: 'Lot', product: { __typename?: 'Product', name: string, nameEn?: string | null, code: string } } } }> | null }> };

export type PointExchangeChangeToGiftCodeFragment = { __typename?: 'GiftCode', id: string };

export type PointExchangeChangeToGiftCodesMutationVariables = Exact<{
  requestedPoints: Scalars['Int'];
}>;


export type PointExchangeChangeToGiftCodesMutation = { __typename?: 'Mutation', webChangeToGiftCodes: Array<{ __typename?: 'GiftCode', id: string }> };

export type PointExchangeMyYellPointSummaryFragment = { __typename?: 'YellPointSummaryOutput', balance: number };

export type PointExchangeMyYellPointSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type PointExchangeMyYellPointSummaryQuery = { __typename?: 'Query', webMyYellPointSummary: { __typename?: 'YellPointSummaryOutput', balance: number } };

export type PointExchangeHistoryGiftCodeFragment = { __typename?: 'GiftCode', id: string, distributedAt?: DateString | null, distributionAmount: number, requiredPoint: number, code: string };

export type PointExchangeHistoryGiftCodesQueryVariables = Exact<{ [key: string]: never; }>;


export type PointExchangeHistoryGiftCodesQuery = { __typename?: 'Query', webGiftCodes: Array<{ __typename?: 'GiftCode', id: string, distributedAt?: DateString | null, distributionAmount: number, requiredPoint: number, code: string }> };

export type WebOnetimePassCheckMutationVariables = Exact<{
  email: Scalars['String'];
  authCode: Scalars['String'];
}>;


export type WebOnetimePassCheckMutation = { __typename?: 'Mutation', webOnetimePassCheck: string };

export type WebSignupFirebaseMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type WebSignupFirebaseMutation = { __typename?: 'Mutation', webSignupFirebase: string };

export type StoreProductsLotsIdsQueryVariables = Exact<{
  input: WebProductsInput;
}>;


export type StoreProductsLotsIdsQuery = { __typename?: 'Query', webProducts: Array<{ __typename?: 'ProductExtends', lots?: Array<{ __typename?: 'Lot', id: string }> | null }> };

export type StoreProductsLotQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StoreProductsLotQuery = { __typename?: 'Query', webLot: { __typename?: 'LotExtends', sellingPrice: number, product: { __typename?: 'ProductExtends', name: string, nameEn?: string | null, productImages?: Array<{ __typename?: 'ProductImage', url: string }> | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, description?: string | null, descriptionEn?: string | null, status: ContributorStatus, groundArea?: number | null, operationStartedAt?: DateString | null, country: Country, prefecture: string, prefectureEn?: string | null, city?: string | null, cityEn?: string | null, street1?: string | null, street1En?: string | null, street2?: string | null, street2En?: string | null, lat: number, lng: number, createdAt: DateString, company?: { __typename?: 'Company', description?: string | null, descriptionEn?: string | null, logoUrl?: string | null } | null } }, nfts?: Array<{ __typename?: 'Nft', contentUrl: string }> | null } };

export type StoreLotCountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StoreLotCountQuery = { __typename?: 'Query', webLot: { __typename?: 'LotExtends', publishedCount: number, soldCount: number } };

export type StoresPageWebProductsQueryVariables = Exact<{
  input: WebProductsInput;
}>;


export type StoresPageWebProductsQuery = { __typename?: 'Query', webProducts: Array<{ __typename?: 'ProductExtends', name: string, nameEn?: string | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null }, lots?: Array<{ __typename?: 'Lot', id: string, sellingPrice: number, publishedCount: number, soldCount: number, nfts?: Array<{ __typename?: 'Nft', contentUrl: string }> | null }> | null }> };

export type AccountPageUserFragment = { __typename?: 'User', id: string, email: string };

export type MyAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type MyAccountQuery = { __typename?: 'Query', webUserMe: { __typename?: 'User', id: string, email: string } };

export type RevealedCellInfoFragment = { __typename?: 'RevealedCellWithIsSelfReveal', status: RevealedCellStatus, imageURL?: string | null, createdAt: DateString, cell: { __typename?: 'Cell', addressJa: string, addressEn: string } };

export type WebRevealCellMutationVariables = Exact<{
  input: WebRevealCellInput;
}>;


export type WebRevealCellMutation = { __typename?: 'Mutation', webRevealCell: boolean };

export type WebRevealedCellQueryVariables = Exact<{
  input: WebRevealedCellInput;
}>;


export type WebRevealedCellQuery = { __typename?: 'Query', webRevealedCell: { __typename?: 'RevealedCellWithIsSelfReveal', status: RevealedCellStatus, imageURL?: string | null, createdAt: DateString, cell: { __typename?: 'Cell', addressJa: string, addressEn: string } } };

export type MyCo2PointsFragment = { __typename?: 'Co2PointSummaryOutput', balance: number };

export type MyRevealedCellCountFragment = { __typename?: 'RevealedCellCount', total: number };

export type CardRevealedCellInfoFragment = { __typename?: 'RevealedCellWithIsSelfReveal', imageURL?: string | null, createdAt: DateString, cell: { __typename?: 'Cell', addressJa: string, addressEn: string } };

export type MapActiveCellAreaFragment = { __typename?: 'ActiveCellArea', id: string, upLeftLat: number, upLeftLng: number, upRightLat: number, upRightLng: number, downLeftLat: number, downLeftLng: number, downRightLat: number, downRightLng: number };

export type MapActiveCellAreasQueryVariables = Exact<{ [key: string]: never; }>;


export type MapActiveCellAreasQuery = { __typename?: 'Query', webActiveCellAreas: Array<{ __typename?: 'ActiveCellArea', id: string, upLeftLat: number, upLeftLng: number, upRightLat: number, upRightLng: number, downLeftLat: number, downLeftLng: number, downRightLat: number, downRightLng: number }> };

export type MyCo2PointSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCo2PointSummaryQuery = { __typename?: 'Query', webMyCo2PointSummary: { __typename?: 'Co2PointSummaryOutput', balance: number } };

export type CustomClaimCellsPageFragment = { __typename?: 'MyCustomClaimOutput', isOnboardingOfCellsCompleted?: boolean | null };

export type CustomClaimCellsPageQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomClaimCellsPageQuery = { __typename?: 'Query', webMyCustomClaims: { __typename?: 'MyCustomClaimOutput', isOnboardingOfCellsCompleted?: boolean | null } };

export type NumOfRevealedCellsQueryVariables = Exact<{ [key: string]: never; }>;


export type NumOfRevealedCellsQuery = { __typename?: 'Query', webNumOfRevealedCells: number };

export type TotalActiveCellCountQueryVariables = Exact<{ [key: string]: never; }>;


export type TotalActiveCellCountQuery = { __typename?: 'Query', webTotalActiveCellCount: number };

export type MyNumOfRevealedCellsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyNumOfRevealedCellsQuery = { __typename?: 'Query', webMyNumOfRevealedCells: { __typename?: 'RevealedCellCount', total: number } };

export type CellsRevealedInAreaFragment = { __typename?: 'CellWithIsSelfReveal', lat: number, lng: number, isSelfRevealed: boolean, id: string };

export type CellsRevealedInAreaQueryVariables = Exact<{
  input: RectangleOnMapInput;
}>;


export type CellsRevealedInAreaQuery = { __typename?: 'Query', webCellsBySelfAndOthersRevealed: Array<{ __typename?: 'CellWithIsSelfReveal', lat: number, lng: number, isSelfRevealed: boolean, id: string }> };

export type CardRevealedCellQueryVariables = Exact<{
  input: WebRevealedCellInput;
}>;


export type CardRevealedCellQuery = { __typename?: 'Query', webRevealedCell: { __typename?: 'RevealedCellWithIsSelfReveal', imageURL?: string | null, createdAt: DateString, cell: { __typename?: 'Cell', addressJa: string, addressEn: string } } };

export type UpdateCustomClaimMutationVariables = Exact<{ [key: string]: never; }>;


export type UpdateCustomClaimMutation = { __typename?: 'Mutation', webChangeIsOnboardingCustomClaimOfCells: boolean };

export type WebSendAuthCodeForEmailChangeMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type WebSendAuthCodeForEmailChangeMutation = { __typename?: 'Mutation', webSendAuthCodeForEmailChange: string };

export type CompanyInfoFragment = { __typename?: 'Company', id: string, name: string, nameEn?: string | null, country: Country, prefecture: string, prefectureEn?: string | null, city: string, cityEn?: string | null, street1: string, street1En?: string | null, street2?: string | null, street2En?: string | null, createdAt: DateString, description?: string | null, descriptionEn?: string | null };

export type CompanyInfoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyInfoQuery = { __typename?: 'Query', company: { __typename?: 'Company', id: string, name: string, nameEn?: string | null, country: Country, prefecture: string, prefectureEn?: string | null, city: string, cityEn?: string | null, street1: string, street1En?: string | null, street2?: string | null, street2En?: string | null, createdAt: DateString, description?: string | null, descriptionEn?: string | null } };

export type TotalCompanyDetailFragment = { __typename?: 'TotalCompanyDetailResponse', totalCompanyContributors: number, totalCompanyNfts: number, uniqueNftUsers: number, totalCompanyYells: number, co2ReductionPower: number, totalCO2Reduction: number };

export type DetailsWebTotalCompanyDetailQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DetailsWebTotalCompanyDetailQuery = { __typename?: 'Query', webTotalCompanyDetail: { __typename?: 'TotalCompanyDetailResponse', totalCompanyContributors: number, totalCompanyNfts: number, uniqueNftUsers: number, totalCompanyYells: number, co2ReductionPower: number, totalCO2Reduction: number } };

export type NftOverviewFragment = { __typename?: 'LotExtends', id: string, nfts?: Array<{ __typename?: 'Nft', id: string, contentUrl: string }> | null };

export type NftsDetailWebCompanyLotsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NftsDetailWebCompanyLotsQuery = { __typename?: 'Query', webCompanyLots: Array<{ __typename?: 'LotExtends', id: string, nfts?: Array<{ __typename?: 'Nft', id: string, contentUrl: string }> | null }> };

export type CompanySocialImpactHistoriesFragment = { __typename?: 'CompanyYellHistoriesOutput', latestNumOfYells: number, latestDate?: DateString | null, comparisonToYesterdayRate: number, histories: Array<{ __typename?: 'YellsForDate', date: DateString, numOfYells: number }> };

export type SocialImpactWebCompanyTotalYellsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SocialImpactWebCompanyTotalYellsQuery = { __typename?: 'Query', webCompanyTotalYells: number };

export type SocialImpactWebCompanyYellHistoriesQueryVariables = Exact<{
  input: CompanyYellHistoriesInput;
}>;


export type SocialImpactWebCompanyYellHistoriesQuery = { __typename?: 'Query', webCompanyYellHistories: { __typename?: 'CompanyYellHistoriesOutput', latestNumOfYells: number, latestDate?: DateString | null, comparisonToYesterdayRate: number, histories: Array<{ __typename?: 'YellsForDate', date: DateString, numOfYells: number }> } };

export type CompanyNftsPageFragment = { __typename?: 'LotExtends', id: string, nfts?: Array<{ __typename?: 'Nft', id: string, contentUrl: string }> | null, product: { __typename?: 'ProductExtends', name: string, nameEn?: string | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', prefecture: string, prefectureEn?: string | null, city?: string | null, cityEn?: string | null, street1?: string | null, street1En?: string | null, street2?: string | null, street2En?: string | null, plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null } } };

export type CompanyLotsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyLotsQuery = { __typename?: 'Query', webCompanyLots: Array<{ __typename?: 'LotExtends', id: string, nfts?: Array<{ __typename?: 'Nft', id: string, contentUrl: string }> | null, product: { __typename?: 'ProductExtends', name: string, nameEn?: string | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', prefecture: string, prefectureEn?: string | null, city?: string | null, cityEn?: string | null, street1?: string | null, street1En?: string | null, street2?: string | null, street2En?: string | null, plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null } } }> };

export type MyCo2PointHistoriesPaginationFragment = { __typename?: 'MyCo2PointHistoriesPaginationOutput', total: number, hasNextPage: boolean, data: Array<{ __typename?: 'Co2PointHistory', id: string, amount: number, type: Co2PointHistoryType, createdAt: DateString }> };

export type MyCo2PointHistoriesQueryVariables = Exact<{
  input: MyCo2PointHistoriesPaginationInput;
}>;


export type MyCo2PointHistoriesQuery = { __typename?: 'Query', webMyCo2PointHistoriesPagination: { __typename?: 'MyCo2PointHistoriesPaginationOutput', total: number, hasNextPage: boolean, data: Array<{ __typename?: 'Co2PointHistory', id: string, amount: number, type: Co2PointHistoryType, createdAt: DateString }> } };

export type HomeMyRevealedCellCountFragment = { __typename?: 'RevealedCellCount', total: number };

export type HomePageYellSummaryFragment = { __typename?: 'YellSummary', yellCount: number, co2power: number };

export type HomePageMyYellPointSummaryFragment = { __typename?: 'YellPointSummaryOutput', balance: number, totalAmount: number, amountOfThisYear: number };

export type HomeMyNumOfRevealedCellsQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeMyNumOfRevealedCellsQuery = { __typename?: 'Query', webMyNumOfRevealedCells: { __typename?: 'RevealedCellCount', total: number } };

export type HomeNumOfRevealedCellsQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeNumOfRevealedCellsQuery = { __typename?: 'Query', webNumOfRevealedCells: number };

export type HomeMyYellSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeMyYellSummaryQuery = { __typename?: 'Query', webMyYellSummary: { __typename?: 'YellSummary', yellCount: number, co2power: number } };

export type HomeMyYellPointSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeMyYellPointSummaryQuery = { __typename?: 'Query', webMyYellPointSummary: { __typename?: 'YellPointSummaryOutput', balance: number, totalAmount: number, amountOfThisYear: number } };

export type HomeMyCo2PointSummaryFragment = { __typename?: 'Co2PointSummaryOutput', totalAmount: number, balance: number, amountOfThisYear: number };

export type HomeMyCo2PointSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type HomeMyCo2PointSummaryQuery = { __typename?: 'Query', webMyCo2PointSummary: { __typename?: 'Co2PointSummaryOutput', totalAmount: number, balance: number, amountOfThisYear: number } };

export type HomeMyStampImgFragment = { __typename?: 'MyRevealedCellPaginationOutput', data: Array<{ __typename?: 'RevealedCell', imageURL?: string | null }> };

export type HomeMyStampImgQueryVariables = Exact<{
  input: MyRevealedCellPaginationInput;
}>;


export type HomeMyStampImgQuery = { __typename?: 'Query', webMyRevealCellsPagination: { __typename?: 'MyRevealedCellPaginationOutput', data: Array<{ __typename?: 'RevealedCell', imageURL?: string | null }> } };

export type PointDetailMyYellSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type PointDetailMyYellSummaryQuery = { __typename?: 'Query', webMyYellSummary: { __typename?: 'YellSummary', yellCount: number, co2power: number } };

export type PointDetailMyYellPointSummaryQueryVariables = Exact<{ [key: string]: never; }>;


export type PointDetailMyYellPointSummaryQuery = { __typename?: 'Query', webMyYellPointSummary: { __typename?: 'YellPointSummaryOutput', balance: number, totalAmount: number, amountOfThisYear: number } };

export type HomePageMyCo2PointSummaryFragment = { __typename?: 'Co2PointSummaryOutputForGraph', amountOfThisYear: number, totalAmount: number, balance: number, co2PointHistories: Array<{ __typename?: 'Co2PointHistoriesOutput', id?: string | null, balance: number, targetDate: DateString }> };

export type PointDetailMyCo2PointSummaryQueryVariables = Exact<{
  historyStartDate: Scalars['DateTime'];
  historyEndDate: Scalars['DateTime'];
}>;


export type PointDetailMyCo2PointSummaryQuery = { __typename?: 'Query', webMyCo2PointSummaryForGraph: { __typename?: 'Co2PointSummaryOutputForGraph', amountOfThisYear: number, totalAmount: number, balance: number, co2PointHistories: Array<{ __typename?: 'Co2PointHistoriesOutput', id?: string | null, balance: number, targetDate: DateString }> } };

export type CalendarYellNftHistoryOutputFragment = { __typename?: 'YellNftHistoryOutput', co2Power: number, power: number, targetDate: DateString };

export type CalendarMyYellNftHistoriesQueryVariables = Exact<{
  nftId: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type CalendarMyYellNftHistoriesQuery = { __typename?: 'Query', webMyYellNftHistories: Array<{ __typename?: 'YellNftHistoryOutput', co2Power: number, power: number, targetDate: DateString }> };

export type ChartPowerDailyHistoryFragment = { __typename?: 'PowerDailyOrHourlyHistoryForGraphOutput', co2Power: number, power: number, fetchedAt: DateString };

export type ChartPowerDailyHistoriesQueryVariables = Exact<{
  input: WebPowerDailyHistoryInput;
}>;


export type ChartPowerDailyHistoriesQuery = { __typename?: 'Query', webPowerDailyHistoriesForGraph: Array<{ __typename?: 'PowerDailyOrHourlyHistoryForGraphOutput', co2Power: number, power: number, fetchedAt: DateString }> };

export type MyNftUpdatedAtPowerDailyHistoriesQueryVariables = Exact<{
  input: WebPowerDailyHistoryInput;
}>;


export type MyNftUpdatedAtPowerDailyHistoriesQuery = { __typename?: 'Query', webPowerDailyHistories: Array<{ __typename?: 'PowerDailyHistory', powerTotal: { __typename?: 'PowerTotal', updatedAt: DateString } }> };

export type WeekYellNftHistoryOutputFragment = { __typename?: 'YellNftHistoryOutput', co2Power: number, power: number, targetDate: DateString };

export type WeekMyYellNftHistoriesQueryVariables = Exact<{
  nftId: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
}>;


export type WeekMyYellNftHistoriesQuery = { __typename?: 'Query', webMyYellNftHistories: Array<{ __typename?: 'YellNftHistoryOutput', co2Power: number, power: number, targetDate: DateString }> };

export type MyNftsPageItemFragment = { __typename?: 'NftObject', id: string, contentUrl: string, lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', name: string, nameEn?: string | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', prefecture: string, prefectureEn?: string | null, plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null } } }, yellNftSummary?: { __typename?: 'YellNftSummary', yellCount: number } | null };

export type MyNftsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyNftsQuery = { __typename?: 'Query', webMyNfts: Array<{ __typename?: 'NftObject', id: string, contentUrl: string, lot: { __typename?: 'LotExtends', product: { __typename?: 'ProductExtends', name: string, nameEn?: string | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', prefecture: string, prefectureEn?: string | null, plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, company?: { __typename?: 'Company', name: string, nameEn?: string | null } | null } } }, yellNftSummary?: { __typename?: 'YellNftSummary', yellCount: number } | null }> };

export type WebChangeOnboardingCustomClaimMutationVariables = Exact<{ [key: string]: never; }>;


export type WebChangeOnboardingCustomClaimMutation = { __typename?: 'Mutation', webChangeIsOnboardingCompletedCustomClaim: boolean };

export type StampsPaginationQueryVariables = Exact<{
  input: MyRevealedCellPaginationInput;
}>;


export type StampsPaginationQuery = { __typename?: 'Query', webMyRevealCellsPagination: { __typename?: 'MyRevealedCellPaginationOutput', total: number, hasNextPage: boolean, data: Array<{ __typename?: 'RevealedCell', id: string, imageURL?: string | null, createdAt: DateString, cell: { __typename?: 'Cell', lat: number, lng: number } }> } };

export type WalletLinkagePageUserFragment = { __typename?: 'User', id: string, walletAddress?: string | null };

export type MyWalletQueryVariables = Exact<{ [key: string]: never; }>;


export type MyWalletQuery = { __typename?: 'Query', webUserMe: { __typename?: 'User', id: string, walletAddress?: string | null } };

export type DeleteUserMutationVariables = Exact<{
  withdrawalReason: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', webDeleteUser: boolean };

export type AuthContextUserFragment = { __typename?: 'User', id: string };

export type AuthContextWebUserMeQueryVariables = Exact<{ [key: string]: never; }>;


export type AuthContextWebUserMeQuery = { __typename?: 'Query', webUserMe: { __typename?: 'User', id: string } };

export type GetCheckedNftIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCheckedNftIdQuery = { __typename?: 'Query', webMyNfts: Array<{ __typename?: 'NftObject', id: string }> };

export type DetailProductFragment = { __typename?: 'ProductExtends', lots?: Array<{ __typename?: 'Lot', id: string }> | null };

export type WebProductsLotsIdsQueryVariables = Exact<{
  input: WebProductsInput;
}>;


export type WebProductsLotsIdsQuery = { __typename?: 'Query', webProducts: Array<{ __typename?: 'ProductExtends', lots?: Array<{ __typename?: 'Lot', id: string }> | null }> };

export type WebProductsLotQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WebProductsLotQuery = { __typename?: 'Query', webLot: { __typename?: 'LotExtends', sellingPrice: number, product: { __typename?: 'ProductExtends', name: string, nameEn?: string | null, productImages?: Array<{ __typename?: 'ProductImage', url: string }> | null, targetObject: { __typename?: 'Character' } | { __typename?: 'Contributor', status: ContributorStatus, plantCapacityNumber: number, co2PowerPerUnit: number, daylightHours?: number | null, groundArea?: number | null, operationStartedAt?: DateString | null, country: Country, prefecture: string, prefectureEn?: string | null, city?: string | null, cityEn?: string | null, street1?: string | null, street1En?: string | null, street2?: string | null, street2En?: string | null, lat: number, lng: number, createdAt: DateString, description?: string | null, descriptionEn?: string | null, powerTotal?: { __typename?: 'PowerTotal', id: string } | null, company?: { __typename?: 'Company', description?: string | null, descriptionEn?: string | null, logoUrl?: string | null } | null } }, nfts?: Array<{ __typename?: 'Nft', contentUrl: string }> | null } };

export const CustomClaimAuthContextFragmentDoc = gql`
    fragment CustomClaimAuthContext on MyCustomClaimOutput {
  isOnboardingCompleted
}
    `;
export const ChartContentsLotFragmentDoc = gql`
    fragment ChartContentsLot on LotExtends {
  product {
    targetObject {
      ... on Contributor {
        powerTotal {
          id
        }
        operationStartedAt
        createdAt
      }
    }
  }
}
    `;
export const ChartContentPowerDailyHistoryFragmentDoc = gql`
    fragment ChartContentPowerDailyHistory on PowerDailyHistory {
  power
  fetchedAt
  co2Power
  powerHourlyHistories {
    power
    fetchedAt
    co2Power
  }
}
    `;
export const ContributorDetailContentLotFragmentDoc = gql`
    fragment ContributorDetailContentLot on LotExtends {
  product {
    targetObject {
      ... on Contributor {
        status
        groundArea
        operationStartedAt
        country
        prefecture
        prefectureEn
        city
        cityEn
        street1
        street1En
        street2
        street2En
        lat
        lng
        createdAt
      }
    }
  }
}
    `;
export const ContributorMapContentLotFragmentDoc = gql`
    fragment ContributorMapContentLot on LotExtends {
  product {
    targetObject {
      ... on Contributor {
        lat
        lng
      }
    }
  }
}
    `;
export const ContributorPowerContentLotFragmentDoc = gql`
    fragment ContributorPowerContentLot on LotExtends {
  product {
    targetObject {
      ... on Contributor {
        plantCapacityNumber
        co2PowerPerUnit
        daylightHours
      }
    }
  }
}
    `;
export const OverviewContentsLotFragmentDoc = gql`
    fragment OverviewContentsLot on LotExtends {
  product {
    targetObject {
      ... on Contributor {
        plantCapacityNumber
        co2PowerPerUnit
        daylightHours
        description
        descriptionEn
        company {
          description
          descriptionEn
          logoUrl
        }
      }
    }
  }
  sellingPrice
}
    `;
export const OverviewContentsLotCountFragmentDoc = gql`
    fragment OverviewContentsLotCount on LotExtends {
  publishedCount
  soldCount
}
    `;
export const DetailLotFragmentDoc = gql`
    fragment DetailLot on LotExtends {
  product {
    name
    nameEn
    productImages {
      url
    }
    targetObject {
      ... on Contributor {
        status
      }
    }
  }
  nfts {
    contentUrl
  }
}
    `;
export const LotsPageWebProductFragmentDoc = gql`
    fragment LotsPageWebProduct on ProductExtends {
  lots {
    id
    sellingPrice
    publishedCount
    soldCount
    nfts {
      contentUrl
    }
  }
}
    `;
export const LotsWebProductFragmentDoc = gql`
    fragment LotsWebProduct on ProductExtends {
  name
  nameEn
  targetObject {
    ... on Contributor {
      company {
        name
        nameEn
      }
      plantCapacityNumber
      co2PowerPerUnit
      daylightHours
    }
  }
}
    `;
export const Section5LotFragmentDoc = gql`
    fragment Section5Lot on LotExtends {
  id
  sellingPrice
  publishedCount
  soldCount
  product {
    id
    name
    nameEn
    targetObject {
      ... on Contributor {
        status
        groundArea
        operationStartedAt
        country
        prefecture
        prefectureEn
        city
        cityEn
        street1
        street1En
        street2
        street2En
        lat
        lng
        createdAt
        company {
          name
          nameEn
        }
      }
    }
  }
  nfts {
    contentUrl
  }
}
    `;
export const Section5OtherLotFragmentDoc = gql`
    fragment Section5OtherLot on LotExtends {
  id
  sellingPrice
  publishedCount
  soldCount
  nfts {
    contentUrl
  }
}
    `;
export const MyPageNftPageFragmentDoc = gql`
    fragment MyPageNftPage on NftObject {
  id
  contentUrl
  yellNftSummary {
    yellCount
  }
  lot {
    product {
      targetObject {
        ... on Contributor {
          id
          plantCapacityNumber
          co2PowerPerUnit
          name
          nameEn
          daylightHours
          company {
            name
            nameEn
          }
        }
        ... on Character {
          id
        }
      }
      name
      nameEn
    }
  }
}
    `;
export const MyPageYellSummaryFragmentDoc = gql`
    fragment MyPageYellSummary on YellSummary {
  yellCount
}
    `;
export const MyPageYellPointSummaryFragmentDoc = gql`
    fragment MyPageYellPointSummary on YellPointSummaryOutput {
  balance
  totalAmount
}
    `;
export const MyPageNftFragmentDoc = gql`
    fragment MyPageNft on NftObject {
  lot {
    isForBonus
    product {
      targetObject {
        ... on Contributor {
          id
          powerTotal {
            id
          }
        }
        ... on Character {
          id
        }
      }
    }
  }
  updatedAt
}
    `;
export const MyPagePowerDailyHistoryFragmentDoc = gql`
    fragment MyPagePowerDailyHistory on PowerDailyHistory {
  co2Power
  powerTotal {
    updatedAt
  }
}
    `;
export const MyCustomClaimFragmentDoc = gql`
    fragment MyCustomClaim on MyCustomClaimOutput {
  isModalShown
}
    `;
export const PurchaseCheckPageLotFragmentDoc = gql`
    fragment PurchaseCheckPageLot on LotExtends {
  product {
    name
    contractAddress
    tokenStandard
    chain
    targetObject {
      ... on Contributor {
        name
        plantCapacityNumber
        co2PowerPerUnit
        daylightHours
        status
        groundArea
        operationStartedAt
        createdAt
        country
        prefecture
        city
        street1
        street2
        lat
        lng
      }
    }
  }
  sellingPrice
}
    `;
export const PurchaseConfirmPageLotFragmentDoc = gql`
    fragment PurchaseConfirmPageLot on LotExtends {
  sellingPrice
  product {
    name
    contractAddress
    tokenStandard
    chain
    targetObject {
      ... on Contributor {
        name
        plantCapacityNumber
        co2PowerPerUnit
        daylightHours
        status
        groundArea
        operationStartedAt
        createdAt
        country
        prefecture
        city
        street1
        street2
        lat
        lng
      }
    }
  }
}
    `;
export const PurchasePaymentPageLotFragmentDoc = gql`
    fragment PurchasePaymentPageLot on LotExtends {
  sellingPrice
}
    `;
export const PurchaseYellPointSummaryFragmentDoc = gql`
    fragment PurchaseYellPointSummary on YellPointSummaryOutput {
  balance
}
    `;
export const PurchaseRightContentLotFragmentDoc = gql`
    fragment PurchaseRightContentLot on LotExtends {
  sellingPrice
  taxPrice
  product {
    name
    targetObject {
      ... on Contributor {
        name
      }
      ... on Character {
        name
      }
    }
  }
}
    `;
export const PurchaseSelectPageLotFragmentDoc = gql`
    fragment PurchaseSelectPageLot on LotExtends {
  nfts {
    serialNumber
    userId
    contentUrl
    id
    tokenId
    originalContentUrl
  }
  product {
    name
    targetObject {
      ... on Contributor {
        name
      }
      ... on Character {
        name
      }
    }
  }
  sellingPrice
}
    `;
export const SendYellPageNftFragmentDoc = gql`
    fragment SendYellPageNft on NftObject {
  lot {
    product {
      productImages {
        url
      }
      name
      nameEn
      targetObject {
        ... on Contributor {
          status
        }
      }
    }
  }
  yellNftSummary {
    yellCount
  }
  updatedAt
  contentUrl
  serialNumber
}
    `;
export const SendYellPagePowerDailyHistoryFragmentDoc = gql`
    fragment SendYellPagePowerDailyHistory on PowerDailyHistory {
  co2Power
  power
}
    `;
export const SendYellPageBottomLeftInfoFragmentDoc = gql`
    fragment SendYellPageBottomLeftInfo on NftObject {
  lot {
    product {
      targetObject {
        ... on Contributor {
          company {
            name
            nameEn
          }
          status
          name
          nameEn
          groundArea
          operationStartedAt
          createdAt
          country
          prefecture
          prefectureEn
          city
          cityEn
          street1
          street1En
          street2
          street2En
          lat
          lng
        }
      }
    }
  }
}
    `;
export const SendYellPageDescriptionFragmentDoc = gql`
    fragment SendYellPageDescription on NftObject {
  lot {
    product {
      targetObject {
        ... on Contributor {
          name
          nameEn
          description
          descriptionEn
        }
      }
    }
  }
}
    `;
export const SendYellPageContributorDetailContentFragmentDoc = gql`
    fragment SendYellPageContributorDetailContent on NftObject {
  lot {
    product {
      targetObject {
        ... on Contributor {
          status
          groundArea
          operationStartedAt
          country
          prefecture
          prefectureEn
          city
          cityEn
          street1
          street1En
          street2
          street2En
          lat
          lng
          createdAt
        }
      }
    }
  }
}
    `;
export const SendYellPageContributorInformationFragmentDoc = gql`
    fragment SendYellPageContributorInformation on NftObject {
  lot {
    product {
      targetObject {
        ... on Contributor {
          company {
            name
            nameEn
          }
          name
          nameEn
          prefecture
          prefectureEn
          city
          cityEn
          street1
          street1En
          street2
          street2En
        }
      }
    }
  }
}
    `;
export const SendYellPageContributorMapContentFragmentDoc = gql`
    fragment SendYellPageContributorMapContent on NftObject {
  lot {
    product {
      targetObject {
        ... on Contributor {
          lat
          lng
        }
      }
    }
  }
}
    `;
export const SendYellPageContributorOperatorFragmentDoc = gql`
    fragment SendYellPageContributorOperator on NftObject {
  lot {
    product {
      targetObject {
        ... on Contributor {
          company {
            id
            description
            descriptionEn
            logoUrl
          }
        }
      }
    }
  }
}
    `;
export const SendYellPageContributorPowerContentFragmentDoc = gql`
    fragment SendYellPageContributorPowerContent on NftObject {
  lot {
    product {
      targetObject {
        ... on Contributor {
          plantCapacityNumber
          co2PowerPerUnit
          daylightHours
        }
      }
    }
  }
}
    `;
export const SendYellPageNftDetailContentFragmentDoc = gql`
    fragment SendYellPageNftDetailContent on NftObject {
  serialNumber
  tokenId
  createdAt
  lot {
    product {
      code
      contractAddress
      tokenStandard
      chain
    }
  }
}
    `;
export const YellChartContentPowerDailyHistoryFragmentDoc = gql`
    fragment YellChartContentPowerDailyHistory on PowerDailyHistory {
  power
  fetchedAt
  co2Power
  powerHourlyHistories {
    power
    fetchedAt
    co2Power
  }
  powerTotal {
    updatedAt
  }
}
    `;
export const SendYellNftFragmentDoc = gql`
    fragment SendYellNft on NftObject {
  lot {
    isForBonus
    product {
      targetObject {
        ... on Contributor {
          id
          powerTotal {
            id
          }
          createdAt
          operationStartedAt
          plantCapacityNumber
          co2PowerPerUnit
          daylightHours
        }
      }
    }
  }
}
    `;
export const SendYellPowerDailyHistoryFragmentDoc = gql`
    fragment SendYellPowerDailyHistory on PowerDailyHistory {
  co2Power
}
    `;
export const SettingsUserFragmentDoc = gql`
    fragment SettingsUser on User {
  name
  email
  createdAt
}
    `;
export const OrderHistoryWebMyOrderFragmentDoc = gql`
    fragment OrderHistoryWebMyOrder on Order {
  orderDetails {
    sellingPrice
    nft {
      id
      contentUrl
      serialNumber
      lot {
        product {
          name
          nameEn
          code
        }
      }
    }
  }
  createdAt
}
    `;
export const PointExchangeChangeToGiftCodeFragmentDoc = gql`
    fragment PointExchangeChangeToGiftCode on GiftCode {
  id
}
    `;
export const PointExchangeMyYellPointSummaryFragmentDoc = gql`
    fragment PointExchangeMyYellPointSummary on YellPointSummaryOutput {
  balance
}
    `;
export const PointExchangeHistoryGiftCodeFragmentDoc = gql`
    fragment PointExchangeHistoryGiftCode on GiftCode {
  id
  distributedAt
  distributionAmount
  requiredPoint
  code
}
    `;
export const AccountPageUserFragmentDoc = gql`
    fragment AccountPageUser on User {
  id
  email
}
    `;
export const RevealedCellInfoFragmentDoc = gql`
    fragment RevealedCellInfo on RevealedCellWithIsSelfReveal {
  status
  imageURL
  createdAt
  cell {
    addressJa
    addressEn
  }
}
    `;
export const MyCo2PointsFragmentDoc = gql`
    fragment MyCo2Points on Co2PointSummaryOutput {
  balance
}
    `;
export const MyRevealedCellCountFragmentDoc = gql`
    fragment MyRevealedCellCount on RevealedCellCount {
  total
}
    `;
export const CardRevealedCellInfoFragmentDoc = gql`
    fragment CardRevealedCellInfo on RevealedCellWithIsSelfReveal {
  imageURL
  createdAt
  cell {
    addressJa
    addressEn
  }
}
    `;
export const MapActiveCellAreaFragmentDoc = gql`
    fragment MapActiveCellArea on ActiveCellArea {
  id
  upLeftLat
  upLeftLng
  upRightLat
  upRightLng
  downLeftLat
  downLeftLng
  downRightLat
  downRightLng
}
    `;
export const CustomClaimCellsPageFragmentDoc = gql`
    fragment CustomClaimCellsPage on MyCustomClaimOutput {
  isOnboardingOfCellsCompleted
}
    `;
export const CellsRevealedInAreaFragmentDoc = gql`
    fragment CellsRevealedInArea on CellWithIsSelfReveal {
  lat
  lng
  isSelfRevealed
  id
}
    `;
export const CompanyInfoFragmentDoc = gql`
    fragment CompanyInfo on Company {
  id
  name
  nameEn
  country
  prefecture
  prefectureEn
  city
  cityEn
  street1
  street1En
  street2
  street2En
  createdAt
  description
  descriptionEn
}
    `;
export const TotalCompanyDetailFragmentDoc = gql`
    fragment TotalCompanyDetail on TotalCompanyDetailResponse {
  totalCompanyContributors
  totalCompanyNfts
  uniqueNftUsers
  totalCompanyYells
  co2ReductionPower
  totalCO2Reduction
}
    `;
export const NftOverviewFragmentDoc = gql`
    fragment NftOverview on LotExtends {
  id
  nfts {
    id
    contentUrl
  }
}
    `;
export const CompanySocialImpactHistoriesFragmentDoc = gql`
    fragment CompanySocialImpactHistories on CompanyYellHistoriesOutput {
  latestNumOfYells
  latestDate
  comparisonToYesterdayRate
  histories {
    date
    numOfYells
  }
}
    `;
export const CompanyNftsPageFragmentDoc = gql`
    fragment CompanyNftsPage on LotExtends {
  id
  nfts {
    id
    contentUrl
  }
  product {
    name
    nameEn
    targetObject {
      ... on Contributor {
        company {
          name
          nameEn
        }
        prefecture
        prefectureEn
        city
        cityEn
        street1
        street1En
        street2
        street2En
        plantCapacityNumber
        co2PowerPerUnit
        daylightHours
      }
    }
  }
}
    `;
export const MyCo2PointHistoriesPaginationFragmentDoc = gql`
    fragment MyCo2PointHistoriesPagination on MyCo2PointHistoriesPaginationOutput {
  data {
    id
    amount
    type
    createdAt
  }
  total
  hasNextPage
}
    `;
export const HomeMyRevealedCellCountFragmentDoc = gql`
    fragment HomeMyRevealedCellCount on RevealedCellCount {
  total
}
    `;
export const HomePageYellSummaryFragmentDoc = gql`
    fragment HomePageYellSummary on YellSummary {
  yellCount
  co2power
}
    `;
export const HomePageMyYellPointSummaryFragmentDoc = gql`
    fragment HomePageMyYellPointSummary on YellPointSummaryOutput {
  balance
  totalAmount
  amountOfThisYear
}
    `;
export const HomeMyCo2PointSummaryFragmentDoc = gql`
    fragment HomeMyCo2PointSummary on Co2PointSummaryOutput {
  totalAmount
  balance
  amountOfThisYear
}
    `;
export const HomeMyStampImgFragmentDoc = gql`
    fragment HomeMyStampImg on MyRevealedCellPaginationOutput {
  data {
    imageURL
  }
}
    `;
export const HomePageMyCo2PointSummaryFragmentDoc = gql`
    fragment HomePageMyCo2PointSummary on Co2PointSummaryOutputForGraph {
  co2PointHistories {
    id
    balance
    targetDate
  }
  amountOfThisYear
  totalAmount
  balance
}
    `;
export const CalendarYellNftHistoryOutputFragmentDoc = gql`
    fragment CalendarYellNftHistoryOutput on YellNftHistoryOutput {
  co2Power
  power
  targetDate
}
    `;
export const ChartPowerDailyHistoryFragmentDoc = gql`
    fragment ChartPowerDailyHistory on PowerDailyOrHourlyHistoryForGraphOutput {
  co2Power
  power
  fetchedAt
}
    `;
export const WeekYellNftHistoryOutputFragmentDoc = gql`
    fragment WeekYellNftHistoryOutput on YellNftHistoryOutput {
  co2Power
  power
  targetDate
}
    `;
export const MyNftsPageItemFragmentDoc = gql`
    fragment MyNftsPageItem on NftObject {
  id
  contentUrl
  lot {
    product {
      name
      nameEn
      targetObject {
        ... on Contributor {
          company {
            name
            nameEn
          }
          prefecture
          prefectureEn
          plantCapacityNumber
          co2PowerPerUnit
          daylightHours
        }
      }
    }
  }
  yellNftSummary {
    yellCount
  }
}
    `;
export const WalletLinkagePageUserFragmentDoc = gql`
    fragment WalletLinkagePageUser on User {
  id
  walletAddress
}
    `;
export const AuthContextUserFragmentDoc = gql`
    fragment AuthContextUser on User {
  id
}
    `;
export const DetailProductFragmentDoc = gql`
    fragment DetailProduct on ProductExtends {
  lots {
    id
  }
}
    `;
export const VerifyIdTokenDocument = gql`
    query verifyIdToken($idToken: String!) {
  webVerifyIdToken(idToken: $idToken) {
    aud
    auth_time
    email
    email_verified
    exp
    iat
    iss
    phone_number
    picture
    sub
    uid
    isOnboardingCompleted
    isOnboardingOfCellsCompleted
    isModalShown
  }
}
    `;

export function useVerifyIdTokenQuery(options: Omit<Urql.UseQueryArgs<VerifyIdTokenQueryVariables>, 'query'>) {
  return Urql.useQuery<VerifyIdTokenQuery, VerifyIdTokenQueryVariables>({ query: VerifyIdTokenDocument, ...options });
};
export const TotalYellCountDocument = gql`
    query totalYellCount {
  totalYellNftHistoriesCount
}
    `;

export function useTotalYellCountQuery(options?: Omit<Urql.UseQueryArgs<TotalYellCountQueryVariables>, 'query'>) {
  return Urql.useQuery<TotalYellCountQuery, TotalYellCountQueryVariables>({ query: TotalYellCountDocument, ...options });
};
export const CustomClaimAuthContextDocument = gql`
    query customClaimAuthContext {
  webMyCustomClaims {
    ...CustomClaimAuthContext
  }
}
    ${CustomClaimAuthContextFragmentDoc}`;

export function useCustomClaimAuthContextQuery(options?: Omit<Urql.UseQueryArgs<CustomClaimAuthContextQueryVariables>, 'query'>) {
  return Urql.useQuery<CustomClaimAuthContextQuery, CustomClaimAuthContextQueryVariables>({ query: CustomClaimAuthContextDocument, ...options });
};
export const ChartContentPowerDailyHistoriesDocument = gql`
    query chartContentPowerDailyHistories($input: WebPowerDailyHistoryInput!) {
  webPowerDailyHistories(input: $input) {
    ...ChartContentPowerDailyHistory
  }
}
    ${ChartContentPowerDailyHistoryFragmentDoc}`;

export function useChartContentPowerDailyHistoriesQuery(options: Omit<Urql.UseQueryArgs<ChartContentPowerDailyHistoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<ChartContentPowerDailyHistoriesQuery, ChartContentPowerDailyHistoriesQueryVariables>({ query: ChartContentPowerDailyHistoriesDocument, ...options });
};
export const WebLotCountDocument = gql`
    query webLotCount($id: ID!) {
  webLot(id: $id) {
    ...OverviewContentsLotCount
  }
}
    ${OverviewContentsLotCountFragmentDoc}`;

export function useWebLotCountQuery(options: Omit<Urql.UseQueryArgs<WebLotCountQueryVariables>, 'query'>) {
  return Urql.useQuery<WebLotCountQuery, WebLotCountQueryVariables>({ query: WebLotCountDocument, ...options });
};
export const WebSignupBffDocument = gql`
    mutation webSignupBff($password: String!) {
  webSignupBff(password: $password)
}
    `;

export function useWebSignupBffMutation() {
  return Urql.useMutation<WebSignupBffMutation, WebSignupBffMutationVariables>(WebSignupBffDocument);
};
export const LotsPageWebProductsDocument = gql`
    query lotsPageWebProducts($input: WebProductsInput!) {
  webProducts(input: $input) {
    ...LotsWebProduct
    ...LotsPageWebProduct
  }
}
    ${LotsWebProductFragmentDoc}
${LotsPageWebProductFragmentDoc}`;

export function useLotsPageWebProductsQuery(options: Omit<Urql.UseQueryArgs<LotsPageWebProductsQueryVariables>, 'query'>) {
  return Urql.useQuery<LotsPageWebProductsQuery, LotsPageWebProductsQueryVariables>({ query: LotsPageWebProductsDocument, ...options });
};
export const WebLotsDocument = gql`
    query webLots($input: WebLotsInput!) {
  webLots(input: $input) {
    ...Section5Lot
    ...Section5OtherLot
  }
}
    ${Section5LotFragmentDoc}
${Section5OtherLotFragmentDoc}`;

export function useWebLotsQuery(options: Omit<Urql.UseQueryArgs<WebLotsQueryVariables>, 'query'>) {
  return Urql.useQuery<WebLotsQuery, WebLotsQueryVariables>({ query: WebLotsDocument, ...options });
};
export const WebMyNftsDocument = gql`
    query webMyNfts {
  webMyNfts {
    ...MyPageNft
    ...MyPageNftPage
  }
}
    ${MyPageNftFragmentDoc}
${MyPageNftPageFragmentDoc}`;

export function useWebMyNftsQuery(options?: Omit<Urql.UseQueryArgs<WebMyNftsQueryVariables>, 'query'>) {
  return Urql.useQuery<WebMyNftsQuery, WebMyNftsQueryVariables>({ query: WebMyNftsDocument, ...options });
};
export const WebMyYellSummaryDocument = gql`
    query webMyYellSummary {
  webMyYellSummary {
    ...MyPageYellSummary
  }
}
    ${MyPageYellSummaryFragmentDoc}`;

export function useWebMyYellSummaryQuery(options?: Omit<Urql.UseQueryArgs<WebMyYellSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<WebMyYellSummaryQuery, WebMyYellSummaryQueryVariables>({ query: WebMyYellSummaryDocument, ...options });
};
export const MyPageMyYellPointSummaryDocument = gql`
    query myPageMyYellPointSummary {
  webMyYellPointSummary {
    ...MyPageYellPointSummary
  }
}
    ${MyPageYellPointSummaryFragmentDoc}`;

export function useMyPageMyYellPointSummaryQuery(options?: Omit<Urql.UseQueryArgs<MyPageMyYellPointSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<MyPageMyYellPointSummaryQuery, MyPageMyYellPointSummaryQueryVariables>({ query: MyPageMyYellPointSummaryDocument, ...options });
};
export const WebDonationAmountDocument = gql`
    query webDonationAmount {
  webDonationAmount
}
    `;

export function useWebDonationAmountQuery(options?: Omit<Urql.UseQueryArgs<WebDonationAmountQueryVariables>, 'query'>) {
  return Urql.useQuery<WebDonationAmountQuery, WebDonationAmountQueryVariables>({ query: WebDonationAmountDocument, ...options });
};
export const WebMyDonationAmountDocument = gql`
    query webMyDonationAmount {
  webMyDonationAmount
}
    `;

export function useWebMyDonationAmountQuery(options?: Omit<Urql.UseQueryArgs<WebMyDonationAmountQueryVariables>, 'query'>) {
  return Urql.useQuery<WebMyDonationAmountQuery, WebMyDonationAmountQueryVariables>({ query: WebMyDonationAmountDocument, ...options });
};
export const MyPagePowerDailyHistoriesDocument = gql`
    query myPagePowerDailyHistories($input: WebPowerDailyHistoryInput!) {
  webPowerDailyHistories(input: $input) {
    ...MyPagePowerDailyHistory
  }
}
    ${MyPagePowerDailyHistoryFragmentDoc}`;

export function useMyPagePowerDailyHistoriesQuery(options: Omit<Urql.UseQueryArgs<MyPagePowerDailyHistoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<MyPagePowerDailyHistoriesQuery, MyPagePowerDailyHistoriesQueryVariables>({ query: MyPagePowerDailyHistoriesDocument, ...options });
};
export const WebMyCustomClaimsDocument = gql`
    query webMyCustomClaims {
  webMyCustomClaims {
    ...MyCustomClaim
  }
}
    ${MyCustomClaimFragmentDoc}`;

export function useWebMyCustomClaimsQuery(options?: Omit<Urql.UseQueryArgs<WebMyCustomClaimsQueryVariables>, 'query'>) {
  return Urql.useQuery<WebMyCustomClaimsQuery, WebMyCustomClaimsQueryVariables>({ query: WebMyCustomClaimsDocument, ...options });
};
export const PurchaseMyYellPointSummaryDocument = gql`
    query purchaseMyYellPointSummary {
  webMyYellPointSummary {
    ...PurchaseYellPointSummary
  }
}
    ${PurchaseYellPointSummaryFragmentDoc}`;

export function usePurchaseMyYellPointSummaryQuery(options?: Omit<Urql.UseQueryArgs<PurchaseMyYellPointSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<PurchaseMyYellPointSummaryQuery, PurchaseMyYellPointSummaryQueryVariables>({ query: PurchaseMyYellPointSummaryDocument, ...options });
};
export const PurchaseLotNftsDocument = gql`
    query purchaseLotNfts($id: ID!) {
  webLot(id: $id) {
    ...PurchaseSelectPageLot
    ...PurchaseCheckPageLot
    ...PurchasePaymentPageLot
    ...PurchaseConfirmPageLot
    ...PurchaseRightContentLot
  }
}
    ${PurchaseSelectPageLotFragmentDoc}
${PurchaseCheckPageLotFragmentDoc}
${PurchasePaymentPageLotFragmentDoc}
${PurchaseConfirmPageLotFragmentDoc}
${PurchaseRightContentLotFragmentDoc}`;

export function usePurchaseLotNftsQuery(options: Omit<Urql.UseQueryArgs<PurchaseLotNftsQueryVariables>, 'query'>) {
  return Urql.useQuery<PurchaseLotNftsQuery, PurchaseLotNftsQueryVariables>({ query: PurchaseLotNftsDocument, ...options });
};
export const PurchaseWebAddOrderDocument = gql`
    mutation purchaseWebAddOrder($orderCreateInput: OrderCreateInput!, $orderDetailCreateInput: [OrderDetailCreateInput!]!) {
  webAddOrder(
    orderCreateInput: $orderCreateInput
    orderDetailCreateInput: $orderDetailCreateInput
  ) {
    id
  }
}
    `;

export function usePurchaseWebAddOrderMutation() {
  return Urql.useMutation<PurchaseWebAddOrderMutation, PurchaseWebAddOrderMutationVariables>(PurchaseWebAddOrderDocument);
};
export const PasswordOnetimePassCheckDocument = gql`
    mutation passwordOnetimePassCheck($authCode: String!, $email: String!) {
  webOnetimePassCheckForPasswordReset(authCode: $authCode, email: $email)
}
    `;

export function usePasswordOnetimePassCheckMutation() {
  return Urql.useMutation<PasswordOnetimePassCheckMutation, PasswordOnetimePassCheckMutationVariables>(PasswordOnetimePassCheckDocument);
};
export const WebPasswordResetDocument = gql`
    mutation webPasswordReset($authCode: String!, $newPassword: String!) {
  webPasswordReset(authCode: $authCode, newPassword: $newPassword)
}
    `;

export function useWebPasswordResetMutation() {
  return Urql.useMutation<WebPasswordResetMutation, WebPasswordResetMutationVariables>(WebPasswordResetDocument);
};
export const WebSendAuthCodeDocument = gql`
    mutation webSendAuthCode($email: String!) {
  webSendAuthCodeForPasswordReset(email: $email)
}
    `;

export function useWebSendAuthCodeMutation() {
  return Urql.useMutation<WebSendAuthCodeMutation, WebSendAuthCodeMutationVariables>(WebSendAuthCodeDocument);
};
export const YellChartContentPowerDailyHistoriesDocument = gql`
    query yellChartContentPowerDailyHistories($input: WebPowerDailyHistoryInput!) {
  webPowerDailyHistories(input: $input) {
    ...YellChartContentPowerDailyHistory
  }
}
    ${YellChartContentPowerDailyHistoryFragmentDoc}`;

export function useYellChartContentPowerDailyHistoriesQuery(options: Omit<Urql.UseQueryArgs<YellChartContentPowerDailyHistoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<YellChartContentPowerDailyHistoriesQuery, YellChartContentPowerDailyHistoriesQueryVariables>({ query: YellChartContentPowerDailyHistoriesDocument, ...options });
};
export const GetContributorFromNftDocument = gql`
    query getContributorFromNFT($id: ID!) {
  webNft(id: $id) {
    ...SendYellNft
    ...SendYellPageNft
    ...SendYellPageBottomLeftInfo
    ...SendYellPageDescription
    ...SendYellPageContributorDetailContent
    ...SendYellPageContributorInformation
    ...SendYellPageContributorMapContent
    ...SendYellPageContributorOperator
    ...SendYellPageContributorPowerContent
    ...SendYellPageNftDetailContent
  }
}
    ${SendYellNftFragmentDoc}
${SendYellPageNftFragmentDoc}
${SendYellPageBottomLeftInfoFragmentDoc}
${SendYellPageDescriptionFragmentDoc}
${SendYellPageContributorDetailContentFragmentDoc}
${SendYellPageContributorInformationFragmentDoc}
${SendYellPageContributorMapContentFragmentDoc}
${SendYellPageContributorOperatorFragmentDoc}
${SendYellPageContributorPowerContentFragmentDoc}
${SendYellPageNftDetailContentFragmentDoc}`;

export function useGetContributorFromNftQuery(options: Omit<Urql.UseQueryArgs<GetContributorFromNftQueryVariables>, 'query'>) {
  return Urql.useQuery<GetContributorFromNftQuery, GetContributorFromNftQueryVariables>({ query: GetContributorFromNftDocument, ...options });
};
export const WebSentYellOrNotDocument = gql`
    query webSentYellOrNot($nftId: ID!, $date: DateTime!) {
  webSentYellOrNot(nftId: $nftId, date: $date)
}
    `;

export function useWebSentYellOrNotQuery(options: Omit<Urql.UseQueryArgs<WebSentYellOrNotQueryVariables>, 'query'>) {
  return Urql.useQuery<WebSentYellOrNotQuery, WebSentYellOrNotQueryVariables>({ query: WebSentYellOrNotDocument, ...options });
};
export const WebSendYellDocument = gql`
    mutation webSendYell($nftId: ID!, $date: DateTime!, $contributorId: ID!) {
  webSendYell(nftId: $nftId, date: $date, contributorId: $contributorId) {
    id
  }
}
    `;

export function useWebSendYellMutation() {
  return Urql.useMutation<WebSendYellMutation, WebSendYellMutationVariables>(WebSendYellDocument);
};
export const SendYellPowerDailyHistoriesDocument = gql`
    query sendYellPowerDailyHistories($input: WebPowerDailyHistoryInput!) {
  webPowerDailyHistories(input: $input) {
    ...SendYellPowerDailyHistory
    ...SendYellPagePowerDailyHistory
  }
}
    ${SendYellPowerDailyHistoryFragmentDoc}
${SendYellPagePowerDailyHistoryFragmentDoc}`;

export function useSendYellPowerDailyHistoriesQuery(options: Omit<Urql.UseQueryArgs<SendYellPowerDailyHistoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<SendYellPowerDailyHistoriesQuery, SendYellPowerDailyHistoriesQueryVariables>({ query: SendYellPowerDailyHistoriesDocument, ...options });
};
export const SettingsWebUserMeDocument = gql`
    query settingsWebUserMe {
  webUserMe {
    ...SettingsUser
  }
}
    ${SettingsUserFragmentDoc}`;

export function useSettingsWebUserMeQuery(options?: Omit<Urql.UseQueryArgs<SettingsWebUserMeQueryVariables>, 'query'>) {
  return Urql.useQuery<SettingsWebUserMeQuery, SettingsWebUserMeQueryVariables>({ query: SettingsWebUserMeDocument, ...options });
};
export const WebEmailChangeDocument = gql`
    mutation webEmailChange($newEmail: String!, $authCode: String!) {
  webEmailChange(newEmail: $newEmail, authCode: $authCode)
}
    `;

export function useWebEmailChangeMutation() {
  return Urql.useMutation<WebEmailChangeMutation, WebEmailChangeMutationVariables>(WebEmailChangeDocument);
};
export const OrderHistoryWebMyOrdersDocument = gql`
    query orderHistoryWebMyOrders {
  webMyOrders {
    ...OrderHistoryWebMyOrder
  }
}
    ${OrderHistoryWebMyOrderFragmentDoc}`;

export function useOrderHistoryWebMyOrdersQuery(options?: Omit<Urql.UseQueryArgs<OrderHistoryWebMyOrdersQueryVariables>, 'query'>) {
  return Urql.useQuery<OrderHistoryWebMyOrdersQuery, OrderHistoryWebMyOrdersQueryVariables>({ query: OrderHistoryWebMyOrdersDocument, ...options });
};
export const PointExchangeChangeToGiftCodesDocument = gql`
    mutation pointExchangeChangeToGiftCodes($requestedPoints: Int!) {
  webChangeToGiftCodes(requestedPoints: $requestedPoints) {
    ...PointExchangeChangeToGiftCode
  }
}
    ${PointExchangeChangeToGiftCodeFragmentDoc}`;

export function usePointExchangeChangeToGiftCodesMutation() {
  return Urql.useMutation<PointExchangeChangeToGiftCodesMutation, PointExchangeChangeToGiftCodesMutationVariables>(PointExchangeChangeToGiftCodesDocument);
};
export const PointExchangeMyYellPointSummaryDocument = gql`
    query pointExchangeMyYellPointSummary {
  webMyYellPointSummary {
    ...PointExchangeMyYellPointSummary
  }
}
    ${PointExchangeMyYellPointSummaryFragmentDoc}`;

export function usePointExchangeMyYellPointSummaryQuery(options?: Omit<Urql.UseQueryArgs<PointExchangeMyYellPointSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<PointExchangeMyYellPointSummaryQuery, PointExchangeMyYellPointSummaryQueryVariables>({ query: PointExchangeMyYellPointSummaryDocument, ...options });
};
export const PointExchangeHistoryGiftCodesDocument = gql`
    query pointExchangeHistoryGiftCodes {
  webGiftCodes {
    ...PointExchangeHistoryGiftCode
  }
}
    ${PointExchangeHistoryGiftCodeFragmentDoc}`;

export function usePointExchangeHistoryGiftCodesQuery(options?: Omit<Urql.UseQueryArgs<PointExchangeHistoryGiftCodesQueryVariables>, 'query'>) {
  return Urql.useQuery<PointExchangeHistoryGiftCodesQuery, PointExchangeHistoryGiftCodesQueryVariables>({ query: PointExchangeHistoryGiftCodesDocument, ...options });
};
export const WebOnetimePassCheckDocument = gql`
    mutation webOnetimePassCheck($email: String!, $authCode: String!) {
  webOnetimePassCheck(email: $email, authCode: $authCode)
}
    `;

export function useWebOnetimePassCheckMutation() {
  return Urql.useMutation<WebOnetimePassCheckMutation, WebOnetimePassCheckMutationVariables>(WebOnetimePassCheckDocument);
};
export const WebSignupFirebaseDocument = gql`
    mutation webSignupFirebase($email: String!) {
  webSignupFirebase(email: $email)
}
    `;

export function useWebSignupFirebaseMutation() {
  return Urql.useMutation<WebSignupFirebaseMutation, WebSignupFirebaseMutationVariables>(WebSignupFirebaseDocument);
};
export const StoreProductsLotsIdsDocument = gql`
    query storeProductsLotsIds($input: WebProductsInput!) {
  webProducts(input: $input) {
    lots {
      id
    }
  }
}
    `;

export function useStoreProductsLotsIdsQuery(options: Omit<Urql.UseQueryArgs<StoreProductsLotsIdsQueryVariables>, 'query'>) {
  return Urql.useQuery<StoreProductsLotsIdsQuery, StoreProductsLotsIdsQueryVariables>({ query: StoreProductsLotsIdsDocument, ...options });
};
export const StoreProductsLotDocument = gql`
    query storeProductsLot($id: ID!) {
  webLot(id: $id) {
    product {
      name
      nameEn
      productImages {
        url
      }
      targetObject {
        ... on Contributor {
          plantCapacityNumber
          co2PowerPerUnit
          daylightHours
          description
          descriptionEn
          company {
            description
            descriptionEn
            logoUrl
          }
          status
          groundArea
          operationStartedAt
          country
          prefecture
          prefectureEn
          city
          cityEn
          street1
          street1En
          street2
          street2En
          lat
          lng
          createdAt
          status
        }
      }
    }
    sellingPrice
    nfts {
      contentUrl
    }
  }
}
    `;

export function useStoreProductsLotQuery(options: Omit<Urql.UseQueryArgs<StoreProductsLotQueryVariables>, 'query'>) {
  return Urql.useQuery<StoreProductsLotQuery, StoreProductsLotQueryVariables>({ query: StoreProductsLotDocument, ...options });
};
export const StoreLotCountDocument = gql`
    query storeLotCount($id: ID!) {
  webLot(id: $id) {
    publishedCount
    soldCount
  }
}
    `;

export function useStoreLotCountQuery(options: Omit<Urql.UseQueryArgs<StoreLotCountQueryVariables>, 'query'>) {
  return Urql.useQuery<StoreLotCountQuery, StoreLotCountQueryVariables>({ query: StoreLotCountDocument, ...options });
};
export const StoresPageWebProductsDocument = gql`
    query storesPageWebProducts($input: WebProductsInput!) {
  webProducts(input: $input) {
    name
    nameEn
    targetObject {
      ... on Contributor {
        company {
          name
          nameEn
        }
        plantCapacityNumber
        co2PowerPerUnit
        daylightHours
      }
    }
    lots {
      id
      sellingPrice
      publishedCount
      soldCount
      nfts {
        contentUrl
      }
    }
  }
}
    `;

export function useStoresPageWebProductsQuery(options: Omit<Urql.UseQueryArgs<StoresPageWebProductsQueryVariables>, 'query'>) {
  return Urql.useQuery<StoresPageWebProductsQuery, StoresPageWebProductsQueryVariables>({ query: StoresPageWebProductsDocument, ...options });
};
export const MyAccountDocument = gql`
    query myAccount {
  webUserMe {
    ...AccountPageUser
  }
}
    ${AccountPageUserFragmentDoc}`;

export function useMyAccountQuery(options?: Omit<Urql.UseQueryArgs<MyAccountQueryVariables>, 'query'>) {
  return Urql.useQuery<MyAccountQuery, MyAccountQueryVariables>({ query: MyAccountDocument, ...options });
};
export const WebRevealCellDocument = gql`
    mutation webRevealCell($input: WebRevealCellInput!) {
  webRevealCell(input: $input)
}
    `;

export function useWebRevealCellMutation() {
  return Urql.useMutation<WebRevealCellMutation, WebRevealCellMutationVariables>(WebRevealCellDocument);
};
export const WebRevealedCellDocument = gql`
    query webRevealedCell($input: WebRevealedCellInput!) {
  webRevealedCell(input: $input) {
    ...RevealedCellInfo
  }
}
    ${RevealedCellInfoFragmentDoc}`;

export function useWebRevealedCellQuery(options: Omit<Urql.UseQueryArgs<WebRevealedCellQueryVariables>, 'query'>) {
  return Urql.useQuery<WebRevealedCellQuery, WebRevealedCellQueryVariables>({ query: WebRevealedCellDocument, ...options });
};
export const MapActiveCellAreasDocument = gql`
    query mapActiveCellAreas {
  webActiveCellAreas {
    ...MapActiveCellArea
  }
}
    ${MapActiveCellAreaFragmentDoc}`;

export function useMapActiveCellAreasQuery(options?: Omit<Urql.UseQueryArgs<MapActiveCellAreasQueryVariables>, 'query'>) {
  return Urql.useQuery<MapActiveCellAreasQuery, MapActiveCellAreasQueryVariables>({ query: MapActiveCellAreasDocument, ...options });
};
export const MyCo2PointSummaryDocument = gql`
    query myCo2PointSummary {
  webMyCo2PointSummary {
    ...MyCo2Points
  }
}
    ${MyCo2PointsFragmentDoc}`;

export function useMyCo2PointSummaryQuery(options?: Omit<Urql.UseQueryArgs<MyCo2PointSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<MyCo2PointSummaryQuery, MyCo2PointSummaryQueryVariables>({ query: MyCo2PointSummaryDocument, ...options });
};
export const CustomClaimCellsPageDocument = gql`
    query customClaimCellsPage {
  webMyCustomClaims {
    ...CustomClaimCellsPage
  }
}
    ${CustomClaimCellsPageFragmentDoc}`;

export function useCustomClaimCellsPageQuery(options?: Omit<Urql.UseQueryArgs<CustomClaimCellsPageQueryVariables>, 'query'>) {
  return Urql.useQuery<CustomClaimCellsPageQuery, CustomClaimCellsPageQueryVariables>({ query: CustomClaimCellsPageDocument, ...options });
};
export const NumOfRevealedCellsDocument = gql`
    query numOfRevealedCells {
  webNumOfRevealedCells
}
    `;

export function useNumOfRevealedCellsQuery(options?: Omit<Urql.UseQueryArgs<NumOfRevealedCellsQueryVariables>, 'query'>) {
  return Urql.useQuery<NumOfRevealedCellsQuery, NumOfRevealedCellsQueryVariables>({ query: NumOfRevealedCellsDocument, ...options });
};
export const TotalActiveCellCountDocument = gql`
    query totalActiveCellCount {
  webTotalActiveCellCount
}
    `;

export function useTotalActiveCellCountQuery(options?: Omit<Urql.UseQueryArgs<TotalActiveCellCountQueryVariables>, 'query'>) {
  return Urql.useQuery<TotalActiveCellCountQuery, TotalActiveCellCountQueryVariables>({ query: TotalActiveCellCountDocument, ...options });
};
export const MyNumOfRevealedCellsDocument = gql`
    query myNumOfRevealedCells {
  webMyNumOfRevealedCells {
    ...MyRevealedCellCount
  }
}
    ${MyRevealedCellCountFragmentDoc}`;

export function useMyNumOfRevealedCellsQuery(options?: Omit<Urql.UseQueryArgs<MyNumOfRevealedCellsQueryVariables>, 'query'>) {
  return Urql.useQuery<MyNumOfRevealedCellsQuery, MyNumOfRevealedCellsQueryVariables>({ query: MyNumOfRevealedCellsDocument, ...options });
};
export const CellsRevealedInAreaDocument = gql`
    query cellsRevealedInArea($input: RectangleOnMapInput!) {
  webCellsBySelfAndOthersRevealed(input: $input) {
    ...CellsRevealedInArea
  }
}
    ${CellsRevealedInAreaFragmentDoc}`;

export function useCellsRevealedInAreaQuery(options: Omit<Urql.UseQueryArgs<CellsRevealedInAreaQueryVariables>, 'query'>) {
  return Urql.useQuery<CellsRevealedInAreaQuery, CellsRevealedInAreaQueryVariables>({ query: CellsRevealedInAreaDocument, ...options });
};
export const CardRevealedCellDocument = gql`
    query cardRevealedCell($input: WebRevealedCellInput!) {
  webRevealedCell(input: $input) {
    ...CardRevealedCellInfo
  }
}
    ${CardRevealedCellInfoFragmentDoc}`;

export function useCardRevealedCellQuery(options: Omit<Urql.UseQueryArgs<CardRevealedCellQueryVariables>, 'query'>) {
  return Urql.useQuery<CardRevealedCellQuery, CardRevealedCellQueryVariables>({ query: CardRevealedCellDocument, ...options });
};
export const UpdateCustomClaimDocument = gql`
    mutation updateCustomClaim {
  webChangeIsOnboardingCustomClaimOfCells
}
    `;

export function useUpdateCustomClaimMutation() {
  return Urql.useMutation<UpdateCustomClaimMutation, UpdateCustomClaimMutationVariables>(UpdateCustomClaimDocument);
};
export const WebSendAuthCodeForEmailChangeDocument = gql`
    mutation webSendAuthCodeForEmailChange($email: String!) {
  webSendAuthCodeForEmailChange(email: $email)
}
    `;

export function useWebSendAuthCodeForEmailChangeMutation() {
  return Urql.useMutation<WebSendAuthCodeForEmailChangeMutation, WebSendAuthCodeForEmailChangeMutationVariables>(WebSendAuthCodeForEmailChangeDocument);
};
export const CompanyInfoDocument = gql`
    query companyInfo($id: ID!) {
  company(id: $id) {
    ...CompanyInfo
  }
}
    ${CompanyInfoFragmentDoc}`;

export function useCompanyInfoQuery(options: Omit<Urql.UseQueryArgs<CompanyInfoQueryVariables>, 'query'>) {
  return Urql.useQuery<CompanyInfoQuery, CompanyInfoQueryVariables>({ query: CompanyInfoDocument, ...options });
};
export const DetailsWebTotalCompanyDetailDocument = gql`
    query detailsWebTotalCompanyDetail($id: ID!) {
  webTotalCompanyDetail(id: $id) {
    ...TotalCompanyDetail
  }
}
    ${TotalCompanyDetailFragmentDoc}`;

export function useDetailsWebTotalCompanyDetailQuery(options: Omit<Urql.UseQueryArgs<DetailsWebTotalCompanyDetailQueryVariables>, 'query'>) {
  return Urql.useQuery<DetailsWebTotalCompanyDetailQuery, DetailsWebTotalCompanyDetailQueryVariables>({ query: DetailsWebTotalCompanyDetailDocument, ...options });
};
export const NftsDetailWebCompanyLotsDocument = gql`
    query nftsDetailWebCompanyLots($id: ID!) {
  webCompanyLots(id: $id) {
    ...NftOverview
  }
}
    ${NftOverviewFragmentDoc}`;

export function useNftsDetailWebCompanyLotsQuery(options: Omit<Urql.UseQueryArgs<NftsDetailWebCompanyLotsQueryVariables>, 'query'>) {
  return Urql.useQuery<NftsDetailWebCompanyLotsQuery, NftsDetailWebCompanyLotsQueryVariables>({ query: NftsDetailWebCompanyLotsDocument, ...options });
};
export const SocialImpactWebCompanyTotalYellsDocument = gql`
    query socialImpactWebCompanyTotalYells($id: ID!) {
  webCompanyTotalYells(id: $id)
}
    `;

export function useSocialImpactWebCompanyTotalYellsQuery(options: Omit<Urql.UseQueryArgs<SocialImpactWebCompanyTotalYellsQueryVariables>, 'query'>) {
  return Urql.useQuery<SocialImpactWebCompanyTotalYellsQuery, SocialImpactWebCompanyTotalYellsQueryVariables>({ query: SocialImpactWebCompanyTotalYellsDocument, ...options });
};
export const SocialImpactWebCompanyYellHistoriesDocument = gql`
    query socialImpactWebCompanyYellHistories($input: CompanyYellHistoriesInput!) {
  webCompanyYellHistories(input: $input) {
    ...CompanySocialImpactHistories
  }
}
    ${CompanySocialImpactHistoriesFragmentDoc}`;

export function useSocialImpactWebCompanyYellHistoriesQuery(options: Omit<Urql.UseQueryArgs<SocialImpactWebCompanyYellHistoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<SocialImpactWebCompanyYellHistoriesQuery, SocialImpactWebCompanyYellHistoriesQueryVariables>({ query: SocialImpactWebCompanyYellHistoriesDocument, ...options });
};
export const CompanyLotsDocument = gql`
    query companyLots($id: ID!) {
  webCompanyLots(id: $id) {
    ...CompanyNftsPage
  }
}
    ${CompanyNftsPageFragmentDoc}`;

export function useCompanyLotsQuery(options: Omit<Urql.UseQueryArgs<CompanyLotsQueryVariables>, 'query'>) {
  return Urql.useQuery<CompanyLotsQuery, CompanyLotsQueryVariables>({ query: CompanyLotsDocument, ...options });
};
export const MyCo2PointHistoriesDocument = gql`
    query myCo2PointHistories($input: MyCo2PointHistoriesPaginationInput!) {
  webMyCo2PointHistoriesPagination(input: $input) {
    ...MyCo2PointHistoriesPagination
  }
}
    ${MyCo2PointHistoriesPaginationFragmentDoc}`;

export function useMyCo2PointHistoriesQuery(options: Omit<Urql.UseQueryArgs<MyCo2PointHistoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<MyCo2PointHistoriesQuery, MyCo2PointHistoriesQueryVariables>({ query: MyCo2PointHistoriesDocument, ...options });
};
export const HomeMyNumOfRevealedCellsDocument = gql`
    query homeMyNumOfRevealedCells {
  webMyNumOfRevealedCells {
    ...HomeMyRevealedCellCount
  }
}
    ${HomeMyRevealedCellCountFragmentDoc}`;

export function useHomeMyNumOfRevealedCellsQuery(options?: Omit<Urql.UseQueryArgs<HomeMyNumOfRevealedCellsQueryVariables>, 'query'>) {
  return Urql.useQuery<HomeMyNumOfRevealedCellsQuery, HomeMyNumOfRevealedCellsQueryVariables>({ query: HomeMyNumOfRevealedCellsDocument, ...options });
};
export const HomeNumOfRevealedCellsDocument = gql`
    query homeNumOfRevealedCells {
  webNumOfRevealedCells
}
    `;

export function useHomeNumOfRevealedCellsQuery(options?: Omit<Urql.UseQueryArgs<HomeNumOfRevealedCellsQueryVariables>, 'query'>) {
  return Urql.useQuery<HomeNumOfRevealedCellsQuery, HomeNumOfRevealedCellsQueryVariables>({ query: HomeNumOfRevealedCellsDocument, ...options });
};
export const HomeMyYellSummaryDocument = gql`
    query homeMyYellSummary {
  webMyYellSummary {
    ...HomePageYellSummary
  }
}
    ${HomePageYellSummaryFragmentDoc}`;

export function useHomeMyYellSummaryQuery(options?: Omit<Urql.UseQueryArgs<HomeMyYellSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<HomeMyYellSummaryQuery, HomeMyYellSummaryQueryVariables>({ query: HomeMyYellSummaryDocument, ...options });
};
export const HomeMyYellPointSummaryDocument = gql`
    query homeMyYellPointSummary {
  webMyYellPointSummary {
    ...HomePageMyYellPointSummary
  }
}
    ${HomePageMyYellPointSummaryFragmentDoc}`;

export function useHomeMyYellPointSummaryQuery(options?: Omit<Urql.UseQueryArgs<HomeMyYellPointSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<HomeMyYellPointSummaryQuery, HomeMyYellPointSummaryQueryVariables>({ query: HomeMyYellPointSummaryDocument, ...options });
};
export const HomeMyCo2PointSummaryDocument = gql`
    query homeMyCo2PointSummary {
  webMyCo2PointSummary {
    ...HomeMyCo2PointSummary
  }
}
    ${HomeMyCo2PointSummaryFragmentDoc}`;

export function useHomeMyCo2PointSummaryQuery(options?: Omit<Urql.UseQueryArgs<HomeMyCo2PointSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<HomeMyCo2PointSummaryQuery, HomeMyCo2PointSummaryQueryVariables>({ query: HomeMyCo2PointSummaryDocument, ...options });
};
export const HomeMyStampImgDocument = gql`
    query homeMyStampImg($input: MyRevealedCellPaginationInput!) {
  webMyRevealCellsPagination(input: $input) {
    ...HomeMyStampImg
  }
}
    ${HomeMyStampImgFragmentDoc}`;

export function useHomeMyStampImgQuery(options: Omit<Urql.UseQueryArgs<HomeMyStampImgQueryVariables>, 'query'>) {
  return Urql.useQuery<HomeMyStampImgQuery, HomeMyStampImgQueryVariables>({ query: HomeMyStampImgDocument, ...options });
};
export const PointDetailMyYellSummaryDocument = gql`
    query pointDetailMyYellSummary {
  webMyYellSummary {
    ...HomePageYellSummary
  }
}
    ${HomePageYellSummaryFragmentDoc}`;

export function usePointDetailMyYellSummaryQuery(options?: Omit<Urql.UseQueryArgs<PointDetailMyYellSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<PointDetailMyYellSummaryQuery, PointDetailMyYellSummaryQueryVariables>({ query: PointDetailMyYellSummaryDocument, ...options });
};
export const PointDetailMyYellPointSummaryDocument = gql`
    query pointDetailMyYellPointSummary {
  webMyYellPointSummary {
    ...HomePageMyYellPointSummary
  }
}
    ${HomePageMyYellPointSummaryFragmentDoc}`;

export function usePointDetailMyYellPointSummaryQuery(options?: Omit<Urql.UseQueryArgs<PointDetailMyYellPointSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<PointDetailMyYellPointSummaryQuery, PointDetailMyYellPointSummaryQueryVariables>({ query: PointDetailMyYellPointSummaryDocument, ...options });
};
export const PointDetailMyCo2PointSummaryDocument = gql`
    query pointDetailMyCo2PointSummary($historyStartDate: DateTime!, $historyEndDate: DateTime!) {
  webMyCo2PointSummaryForGraph(
    historyStartDate: $historyStartDate
    historyEndDate: $historyEndDate
  ) {
    ...HomePageMyCo2PointSummary
  }
}
    ${HomePageMyCo2PointSummaryFragmentDoc}`;

export function usePointDetailMyCo2PointSummaryQuery(options: Omit<Urql.UseQueryArgs<PointDetailMyCo2PointSummaryQueryVariables>, 'query'>) {
  return Urql.useQuery<PointDetailMyCo2PointSummaryQuery, PointDetailMyCo2PointSummaryQueryVariables>({ query: PointDetailMyCo2PointSummaryDocument, ...options });
};
export const CalendarMyYellNftHistoriesDocument = gql`
    query calendarMyYellNftHistories($nftId: String!, $startDate: DateTime!, $endDate: DateTime!) {
  webMyYellNftHistories(nftId: $nftId, startDate: $startDate, endDate: $endDate) {
    ...CalendarYellNftHistoryOutput
  }
}
    ${CalendarYellNftHistoryOutputFragmentDoc}`;

export function useCalendarMyYellNftHistoriesQuery(options: Omit<Urql.UseQueryArgs<CalendarMyYellNftHistoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<CalendarMyYellNftHistoriesQuery, CalendarMyYellNftHistoriesQueryVariables>({ query: CalendarMyYellNftHistoriesDocument, ...options });
};
export const ChartPowerDailyHistoriesDocument = gql`
    query chartPowerDailyHistories($input: WebPowerDailyHistoryInput!) {
  webPowerDailyHistoriesForGraph(input: $input) {
    ...ChartPowerDailyHistory
  }
}
    ${ChartPowerDailyHistoryFragmentDoc}`;

export function useChartPowerDailyHistoriesQuery(options: Omit<Urql.UseQueryArgs<ChartPowerDailyHistoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<ChartPowerDailyHistoriesQuery, ChartPowerDailyHistoriesQueryVariables>({ query: ChartPowerDailyHistoriesDocument, ...options });
};
export const MyNftUpdatedAtPowerDailyHistoriesDocument = gql`
    query myNftUpdatedAtPowerDailyHistories($input: WebPowerDailyHistoryInput!) {
  webPowerDailyHistories(input: $input) {
    powerTotal {
      updatedAt
    }
  }
}
    `;

export function useMyNftUpdatedAtPowerDailyHistoriesQuery(options: Omit<Urql.UseQueryArgs<MyNftUpdatedAtPowerDailyHistoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<MyNftUpdatedAtPowerDailyHistoriesQuery, MyNftUpdatedAtPowerDailyHistoriesQueryVariables>({ query: MyNftUpdatedAtPowerDailyHistoriesDocument, ...options });
};
export const WeekMyYellNftHistoriesDocument = gql`
    query weekMyYellNftHistories($nftId: String!, $startDate: DateTime!, $endDate: DateTime!) {
  webMyYellNftHistories(nftId: $nftId, startDate: $startDate, endDate: $endDate) {
    ...WeekYellNftHistoryOutput
  }
}
    ${WeekYellNftHistoryOutputFragmentDoc}`;

export function useWeekMyYellNftHistoriesQuery(options: Omit<Urql.UseQueryArgs<WeekMyYellNftHistoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<WeekMyYellNftHistoriesQuery, WeekMyYellNftHistoriesQueryVariables>({ query: WeekMyYellNftHistoriesDocument, ...options });
};
export const MyNftsDocument = gql`
    query myNfts {
  webMyNfts {
    ...MyNftsPageItem
  }
}
    ${MyNftsPageItemFragmentDoc}`;

export function useMyNftsQuery(options?: Omit<Urql.UseQueryArgs<MyNftsQueryVariables>, 'query'>) {
  return Urql.useQuery<MyNftsQuery, MyNftsQueryVariables>({ query: MyNftsDocument, ...options });
};
export const WebChangeOnboardingCustomClaimDocument = gql`
    mutation webChangeOnboardingCustomClaim {
  webChangeIsOnboardingCompletedCustomClaim
}
    `;

export function useWebChangeOnboardingCustomClaimMutation() {
  return Urql.useMutation<WebChangeOnboardingCustomClaimMutation, WebChangeOnboardingCustomClaimMutationVariables>(WebChangeOnboardingCustomClaimDocument);
};
export const StampsPaginationDocument = gql`
    query StampsPagination($input: MyRevealedCellPaginationInput!) {
  webMyRevealCellsPagination(input: $input) {
    total
    data {
      id
      imageURL
      cell {
        lat
        lng
      }
      createdAt
    }
    hasNextPage
  }
}
    `;

export function useStampsPaginationQuery(options: Omit<Urql.UseQueryArgs<StampsPaginationQueryVariables>, 'query'>) {
  return Urql.useQuery<StampsPaginationQuery, StampsPaginationQueryVariables>({ query: StampsPaginationDocument, ...options });
};
export const MyWalletDocument = gql`
    query myWallet {
  webUserMe {
    ...WalletLinkagePageUser
  }
}
    ${WalletLinkagePageUserFragmentDoc}`;

export function useMyWalletQuery(options?: Omit<Urql.UseQueryArgs<MyWalletQueryVariables>, 'query'>) {
  return Urql.useQuery<MyWalletQuery, MyWalletQueryVariables>({ query: MyWalletDocument, ...options });
};
export const DeleteUserDocument = gql`
    mutation deleteUser($withdrawalReason: String!) {
  webDeleteUser(withdrawalReason: $withdrawalReason)
}
    `;

export function useDeleteUserMutation() {
  return Urql.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument);
};
export const AuthContextWebUserMeDocument = gql`
    query authContextWebUserMe {
  webUserMe {
    ...AuthContextUser
  }
}
    ${AuthContextUserFragmentDoc}`;

export function useAuthContextWebUserMeQuery(options?: Omit<Urql.UseQueryArgs<AuthContextWebUserMeQueryVariables>, 'query'>) {
  return Urql.useQuery<AuthContextWebUserMeQuery, AuthContextWebUserMeQueryVariables>({ query: AuthContextWebUserMeDocument, ...options });
};
export const GetCheckedNftIdDocument = gql`
    query getCheckedNftId {
  webMyNfts {
    id
  }
}
    `;

export function useGetCheckedNftIdQuery(options?: Omit<Urql.UseQueryArgs<GetCheckedNftIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetCheckedNftIdQuery, GetCheckedNftIdQueryVariables>({ query: GetCheckedNftIdDocument, ...options });
};
export const WebProductsLotsIdsDocument = gql`
    query webProductsLotsIds($input: WebProductsInput!) {
  webProducts(input: $input) {
    ...DetailProduct
  }
}
    ${DetailProductFragmentDoc}`;

export function useWebProductsLotsIdsQuery(options: Omit<Urql.UseQueryArgs<WebProductsLotsIdsQueryVariables>, 'query'>) {
  return Urql.useQuery<WebProductsLotsIdsQuery, WebProductsLotsIdsQueryVariables>({ query: WebProductsLotsIdsDocument, ...options });
};
export const WebProductsLotDocument = gql`
    query webProductsLot($id: ID!) {
  webLot(id: $id) {
    ...DetailLot
    ...ContributorPowerContentLot
    ...ContributorDetailContentLot
    ...ChartContentsLot
    ...OverviewContentsLot
  }
}
    ${DetailLotFragmentDoc}
${ContributorPowerContentLotFragmentDoc}
${ContributorDetailContentLotFragmentDoc}
${ChartContentsLotFragmentDoc}
${OverviewContentsLotFragmentDoc}`;

export function useWebProductsLotQuery(options: Omit<Urql.UseQueryArgs<WebProductsLotQueryVariables>, 'query'>) {
  return Urql.useQuery<WebProductsLotQuery, WebProductsLotQueryVariables>({ query: WebProductsLotDocument, ...options });
};