export const GOOGLE_MAP_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAP_KEY || '';

export const GRID_SIZE = 0.0025;
export const CELL_DIFF = 0.0025029209873;

const MAPTILER_API_KEY = process.env.NEXT_PUBLIC_MAPTILER_API_KEY || '';
export const MAPTILER_STYLE_API_URL = `https://api.maptiler.com/maps/positron/style.json?key=${MAPTILER_API_KEY}`;

export const AUTH_CODE_LENGTH = 6 as const;
export const BOTTOM_NAV_HEIGHT = '88px' as const;
