import { languages } from '@/app/i18n/settings';

const lngStr = languages.join('|');

/** ログイン状態でのみ閲覧可能なURL */
export const authUrlPatterns = [
  new RegExp(`^(/(${lngStr}))?/purchase\/\w+$`),
  new RegExp(`^(/(${lngStr}))?/mypage$`),
  new RegExp(`^(/(${lngStr}))?/settings\/\w+$`),
  new RegExp(`^(/(${lngStr}))?/order-history$`),
  new RegExp(`^(/(${lngStr}))?/settings$`),
  new RegExp(`^(/(${lngStr}))?/change-password$`),
  new RegExp(`^(/(${lngStr}))?/send-yell\/\w+$`),
  new RegExp(
    `^(/(${lngStr}))?/v2(?!\/settings\/withdrawal\/completed)(\/.+)?$`
  ),
];
