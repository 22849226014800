import { Flex } from '@chakra-ui/react';
import { FC } from 'react';

import { Loading } from '@/components/common/Loading';

const LoadingScreen: FC = () => {
  return (
    <Flex w="100%" h="100vh" justifyContent="center" alignItems="center">
      <Loading />
    </Flex>
  );
};

export { LoadingScreen };
