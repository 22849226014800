import { authExchange } from '@urql/exchange-auth';
import { cacheExchange, createClient, fetchExchange } from 'urql';

import { authConfig } from '@/graphql/urql-exchange-auth';

export const urqlClient = () =>
  createClient({
    url: '/api/graphql',
    exchanges: [cacheExchange, authExchange(authConfig), fetchExchange],
  });
