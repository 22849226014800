'use client';

import { Box } from '@chakra-ui/react';
import Image from 'next/image';

import { useEarth } from '@/components/common/Layout/V2BGLayout/useEarth';

export const Earth = () => {
  const { imageBoxRef } = useEarth();
  return (
    <Box
      ref={imageBoxRef}
      position="absolute"
      left={0}
      top="50%"
      transform="translate(0, -50%)"
      w="full"
      maxW="300px"
      h="full"
      maxH="600px"
      textAlign="left"
    >
      <Image
        alt="earth"
        src="/dotEarth.png"
        style={{ objectFit: 'scale-down' }}
        fill
      />
    </Box>
  );
};
