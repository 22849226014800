import i18next from 'i18next';

export const fallbackLng = 'ja';
export const languages = [fallbackLng, 'en'];
export const defaultNS = 'common';
export const cookieName = 'i18next';

type Init = typeof i18next.init<unknown>;

export function getOptions({
  lng = fallbackLng,
  ns = defaultNS,
}: {
  lng?: string;
  ns?: string | Array<string>;
}): Parameters<Init>[0] {
  return {
    supportedLngs: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns,
  };
}
