// https://zenn.dev/panda_program/articles/typescript-nextjs-routing

export const Paths = {
  signup: '/signup',
  signupCode: '/signup/code',
  login: '/login',
  welcome: '/welcome',
  complete: '/complete',
  lots: '/lots',
  lot: '/lots/[id]',
  purchase: '/purchase/[id]',
  resetPassword: '/reset-password',
  resetPasswordCode: '/reset-password/code',
  resetPasswordResetting: '/reset-password/resetting',
  changePassword: '/settings/change-password',
  accountSettings: '/settings/account',
  yellPointExchange: '/settings/point-exchange',
  yellPointExchangeHistory: '/settings/point-exchange-history',
  orderHistory: '/settings/order-history',
  v2: '/v2',
  v2Cell: '/v2/cell',
  v2Cells: '/v2/cells',
  v2Checked: '/v2/checked',
  v2Company: '/v2/companies/[id]',
  v2CompanyNfts: '/v2/companies/[id]/nfts',
  v2MyNft: '/v2/my-nfts/[id]',
  v2MyNfts: '/v2/my-nfts',
  v2Onboarding: '/v2/onboarding',
  v2Settings: '/v2/settings',
  v2Account: '/v2/settings/account',
  v2ChangePassword: '/v2/settings/change-password',
  v2ChangeEmail: '/v2/settings/change-email',
  v2ChangeEmailCode: '/v2/settings/change-email/code',
  v2OtherSettings: '/v2/settings/others',
  v2Support: '/v2/settings/support',
  v2Wallet: '/v2/settings/wallet',
  v2Withdrawal: '/v2/settings/withdrawal',
  v2WithdrawalCompleted: '/v2/settings/withdrawal/completed',
  v2Stamps: '/v2/stamps',
  v2Stores: '/v2/store',
  v2Store: '/v2/store/[id]',
  v2YellPoint: '/v2/settings/point',
  publicStores: '/stores',
  publicStore: '/stores/[id]',
} as const;

export type PathKey = keyof typeof Paths;
export type Path = (typeof Paths)[PathKey];
