import { checkboxAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const signup = definePartsStyle({
  control: defineStyle({
    width: '24px',
    height: '24px',
    rounded: '6px',
    _checked: { bg: '#181B1F' },
  }),
});

export const checkboxTheme = defineMultiStyleConfig({
  variants: { signup },
});
