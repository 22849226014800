import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

function IconStore({ size = 20, color = 'currentColor' }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7076 5.0028C18.4355 4.63926 18.0073 4.42593 17.5532 4.42766H5.23161L5.20593 4.32503C5.07619 3.80343 4.77545 3.34034 4.3518 3.00956C3.9281 2.67877 3.4059 2.4994 2.86841 2.5H1.48193C1.30979 2.5 1.15068 2.59187 1.06455 2.74096C0.978483 2.89005 0.978483 3.07379 1.06455 3.22287C1.15069 3.37197 1.30981 3.46383 1.48193 3.46383H2.86841C3.19085 3.46345 3.50423 3.57098 3.75845 3.7694C4.01273 3.96782 4.19323 4.24559 4.27125 4.55847L6.43234 13.2048V13.2049C6.56216 13.7265 6.86283 14.1895 7.28653 14.5203C7.71023 14.8511 8.23243 15.0305 8.76992 15.0299H15.9395C16.1116 15.0299 16.2707 14.938 16.3568 14.7889C16.4429 14.6398 16.4429 14.4561 16.3568 14.307C16.2707 14.1579 16.1116 14.0661 15.9395 14.0661H8.76992C8.44748 14.0664 8.1341 13.9589 7.87988 13.7605C7.6256 13.5621 7.4451 13.2843 7.36709 12.9714L7.15888 12.1384H15.449C15.9721 12.1367 16.4806 11.9656 16.8984 11.6506C17.3162 11.3357 17.6207 10.8939 17.7663 10.3914L18.9433 6.27069C19.0696 5.83456 18.9822 5.36438 18.7075 5.00287L18.7076 5.0028ZM18.0163 6.00518L16.8398 10.1259V10.1259C16.7523 10.4274 16.5696 10.6926 16.3189 10.8816C16.0682 11.0706 15.763 11.1734 15.449 11.1745H6.918L5.47256 5.39149H17.5532C17.7043 5.39142 17.8467 5.46228 17.9377 5.58291C18.0288 5.70353 18.0579 5.85993 18.0163 6.00518Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M17.3852 17.4393C17.3852 18.2377 16.738 18.885 15.9395 18.885C15.141 18.885 14.4937 18.2377 14.4937 17.4393C14.4937 16.6408 15.141 15.9935 15.9395 15.9935C16.738 15.9935 17.3852 16.6408 17.3852 17.4393Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
      <path
        d="M8.71078 17.4393C8.71078 18.2377 8.06351 18.885 7.26503 18.885C6.46656 18.885 5.81929 18.2377 5.81929 17.4393C5.81929 16.6408 6.46656 15.9935 7.26503 15.9935C8.06351 15.9935 8.71078 16.6408 8.71078 17.4393Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </svg>
  );
}

export const SvgIconStore = React.memo(IconStore);
