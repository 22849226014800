import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

function IconNft({ size = 21, color = 'currentColor' }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2.6"
        y="1.5"
        width="16"
        height="18"
        rx="1"
        stroke={color}
        strokeWidth="1.2"
      />
      <rect x="4.6" y="10.5" width="12" height="7" rx="1" fill={color} />
    </svg>
  );
}

export const SvgIconNft = React.memo(IconNft);
