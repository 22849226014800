'use client';
import { Grid, GridItem, Text, useMediaQuery, VStack } from '@chakra-ui/react';
import Link from 'next/link';

import { useBottomNav } from '@/components/common/Nav/useBottomNav';
import { BOTTOM_NAV_HEIGHT } from '@/utils/statics';

export const BottomNav = () => {
  const { navItems } = useBottomNav();
  const [hideText] = useMediaQuery('(max-width: 370px)');

  return (
    <Grid
      p="4"
      gap="3"
      h={BOTTOM_NAV_HEIGHT}
      justifyItems="center"
      w="full"
      overflow="hidden"
      gridTemplateColumns={`repeat(${navItems.length}, 1fr)`}
      bg="bg.primary"
    >
      {navItems.map((item) => (
        <GridItem
          key={item.path}
          h="12"
          w="full"
          maxW="12"
          rounded="8px"
          bg={item.isActive ? 'bg.lightGreen' : 'transparent'}
        >
          <Link href={item.path}>
            <VStack
              h="full"
              justifyContent="center"
              alignItems="center"
              gap="1"
              color={item.isActive ? 'text.darkPastelGreen' : 'text.gray'}
            >
              {item.icon}
              {!hideText && (
                <Text fontSize="x4s" lineHeight={1}>
                  {item.title}
                </Text>
              )}
            </VStack>
          </Link>
        </GridItem>
      ))}
    </Grid>
  );
};
