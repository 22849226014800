import { defineStyle } from '@chakra-ui/react';
import { mode } from '@chakra-ui/theme-tools';

type AccessibleColor = {
  bg?: string;
  color?: string;
  hoverBg?: string;
  activeBg?: string;
};

/** Accessible color overrides for less accessible colors. */
const accessibleColorMap: { [key: string]: AccessibleColor } = {
  yellow: {
    bg: 'yellow.400',
    color: 'black',
    hoverBg: 'yellow.500',
    activeBg: 'yellow.600',
  },
  cyan: {
    bg: 'cyan.400',
    color: 'black',
    hoverBg: 'cyan.500',
    activeBg: 'cyan.600',
  },
};

// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/button.ts#L91C19-L91C19 を参考に作成
export const buttonTheme = {
  sizes: {
    default: defineStyle({
      h: '9',
      minW: '9',
      fontSize: 'md',
      px: '4',
    }),
  },
  variants: {
    iconPrimary: defineStyle((props) => {
      const { colorScheme: c } = props;

      if (c === 'gray' || c === 'button.gray') {
        const bg = mode(`${c}.100`, `whiteAlpha.200`)(props);

        return {
          bg,
          color: mode(`${c}.800`, `whiteAlpha.900`)(props),
          _hover: {
            bg: mode(`${c}.200`, `whiteAlpha.300`)(props),
            _disabled: {
              bg,
            },
          },
          _active: { bg: mode(`${c}.300`, `whiteAlpha.400`)(props) },
        };
      }

      const {
        bg = `${c}.500`,
        color = 'white',
        hoverBg = `${c}.600`,
        activeBg = `${c}.700`,
      } = accessibleColorMap[c] ?? {};

      const background = mode(bg, `${c}.200`)(props);

      return {
        bg: background,
        color: mode(color, `gray.800`)(props),
        _hover: {
          bg: mode(hoverBg, `${c}.300`)(props),
          _disabled: {
            bg: background,
          },
        },
        _active: { bg: mode(activeBg, `${c}.400`)(props) },
      };
    }),
  },
};
