import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

function IconHeart({ size = 20, color = 'currentColor' }: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 21 21"
    >
      <path
        fill={color}
        stroke={color}
        strokeWidth="0.5"
        d="M14.5 4.1c.26 0 .518.025.772.073a4.077 4.077 0 013.224 4.143c-.182 4.241-5.811 7.532-7.532 8.44a.983.983 0 01-.928 0c-1.72-.907-7.35-4.199-7.532-8.44a4.077 4.077 0 013.224-4.143 3.958 3.958 0 013.42.95 2.007 2.007 0 002.704-.001A3.958 3.958 0 0114.501 4.1h0zm0-1a4.94 4.94 0 00-3.317 1.278 1.007 1.007 0 01-1.366 0A4.94 4.94 0 006.5 3.1c-.321 0-.64.03-.956.09a5.063 5.063 0 00-4.04 5.169c.201 4.672 5.807 8.09 8.066 9.282a1.983 1.983 0 001.86 0c2.26-1.191 7.864-4.61 8.066-9.282a5.063 5.063 0 00-4.04-5.17A5.197 5.197 0 0014.5 3.1h0z"
      ></path>
    </svg>
  );
}

export const SvgIconHeart = React.memo(IconHeart);
