import { ChakraProvider } from '@chakra-ui/react';
import { LazyMotion, domMax } from 'framer-motion';
import { SessionProvider } from 'next-auth/react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Provider } from 'urql';

import { initAmplitude } from '@/amplitude/initAmplitude';
import { V2BGLayout } from '@/components/common/Layout/V2BGLayout/PageRouter';
import { LoadingScreen } from '@/components/common/LoadingScreen';
import { AuthProvider } from '@/components/context/AuthContext';
import { urqlClient } from '@/graphql/proxyClient';
import { useGATracking } from '@/hooks/useGATracking';
import { theme } from '@/theme';

import type { AppProps } from 'next/app';

import 'react-circular-progressbar/dist/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '@/styles/global.scss';
import '@/styles/yell-button.scss';
import '@/styles/slick.scss';
import '@/styles/map.scss';
import '@/styles/calendar.scss';

function MyApp({ Component, pageProps }: AppProps) {
  useGATracking();
  const [loading, setLoading] = useState(false);
  const router = useRouter();
  useEffect(() => {
    if (router.isReady) {
      initAmplitude();
    }
  }, [router.isReady]);
  const client = urqlClient();

  useEffect(() => {
    const handleStart = (url: string) => {
      if (url !== router.asPath) {
        setLoading(true);
      }
    };
    const handleComplete = () => {
      setLoading(false);
    };
    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <ChakraProvider theme={theme}>
        <SessionProvider>
          <Provider value={client}>
            <AuthProvider>
              <LazyMotion features={domMax}>
                {loading ? (
                  <V2BGLayout>
                    <LoadingScreen />
                  </V2BGLayout>
                ) : (
                  <Component {...pageProps} />
                )}
              </LazyMotion>
            </AuthProvider>
          </Provider>
        </SessionProvider>
      </ChakraProvider>
    </>
  );
}

export default MyApp;
