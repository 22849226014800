import React from 'react';

const viewBoxWithPadding = '0 0 24 24';
const viewBoxWithoutPadding = '3 3 17 18';

interface Props {
  size?: number;
  color?: string;
  noViewBoxPadding?: boolean;
}

function IconStamp({
  size = 24,
  color = '#020202',
  noViewBoxPadding = false,
}: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox={noViewBoxPadding ? viewBoxWithoutPadding : viewBoxWithPadding}
    >
      <path
        fill={color}
        d="M19 5h-.53a1.734 1.734 0 01-2.441 0H14.97a1.734 1.734 0 01-2.441 0H11.47a1.734 1.734 0 01-2.442 0H7.971a1.734 1.734 0 01-2.442 0H5v.53a1.734 1.734 0 010 2.44v1.06a1.734 1.734 0 010 2.441v1.058a1.734 1.734 0 010 2.441v1.06a1.734 1.734 0 010 2.44V19h.53a1.734 1.734 0 012.442 0h1.057a1.734 1.734 0 012.442 0h1.058a1.734 1.734 0 012.442 0h1.058a1.734 1.734 0 012.442 0H19v-.53a1.734 1.734 0 010-2.442v-1.057a1.734 1.734 0 010-2.442V11.47a1.734 1.734 0 010-2.442V7.971a1.734 1.734 0 010-2.442V5m.16-1a.84.84 0 01.84.84v.763a.951.951 0 01-.3.643.734.734 0 000 1.008c.18.168.287.398.3.643v1.206a.951.951 0 01-.3.643.734.734 0 000 1.008c.18.168.287.398.3.643v1.206a.952.952 0 01-.3.643.734.734 0 000 1.008.952.952 0 01.3.643v1.206a.952.952 0 01-.3.643.734.734 0 000 1.008.952.952 0 01.3.643v.763a.84.84 0 01-.84.84h-.764a.951.951 0 01-.643-.3.734.734 0 00-1.008 0 .952.952 0 01-.642.3h-1.207a.952.952 0 01-.643-.3.734.734 0 00-1.007 0 .951.951 0 01-.643.3h-1.207a.952.952 0 01-.642-.3.734.734 0 00-1.008 0 .952.952 0 01-.643.3H7.897a.952.952 0 01-.643-.3.734.734 0 00-1.008 0 .952.952 0 01-.643.3H4.84a.84.84 0 01-.84-.84v-.764a.951.951 0 01.3-.642.734.734 0 000-1.008.951.951 0 01-.3-.643v-1.207a.952.952 0 01.3-.642.734.734 0 000-1.008.952.952 0 01-.3-.643v-1.207a.951.951 0 01.3-.642.734.734 0 000-1.008.951.951 0 01-.3-.642V7.897a.951.951 0 01.3-.643.734.734 0 000-1.008.951.951 0 01-.3-.642V4.84A.84.84 0 014.84 4h.763c.245.013.475.12.643.3a.734.734 0 001.008 0 .952.952 0 01.643-.3h1.206c.245.013.475.12.643.3a.734.734 0 001.008 0 .951.951 0 01.643-.3h1.206c.245.013.475.12.643.3a.734.734 0 001.008 0 .952.952 0 01.642-.3h1.207c.244.013.475.12.642.3a.734.734 0 001.008 0 .952.952 0 01.643-.3h.764zM15 8H9a1.001 1.001 0 00-1 1v6a1.001 1.001 0 001 1h6a1.002 1.002 0 001-1V9a1.001 1.001 0 00-1-1zm0-1a2 2 0 012 2v6a2 2 0 01-2 2H9a2 2 0 01-2-2V9a2 2 0 012-2h6z"
      ></path>
    </svg>
  );
}

export const SvgIconStamp = React.memo(IconStamp);
