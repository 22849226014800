import * as amplitude from '@amplitude/analytics-browser';
import { gql } from '@urql/core';
import { User } from 'firebase/auth';
import { signIn as signInByNextAuth, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { authUrlPatterns } from '@/constants/auth';
import { useAuthContextWebUserMeQuery } from '@/graphql/generated';
import { auth } from '@/utils/firebase/client';

export type IsLoginStatus = 'logout' | 'login' | 'onlyFirebase';

gql`
  fragment AuthContextUser on User {
    id
  }
`;

gql`
  query authContextWebUserMe {
    webUserMe {
      ...AuthContextUser
    }
  }
`;

interface InnerAuthContextState {
  currentUser?: User | null;
  isLoginStatus?: IsLoginStatus;
  isLoading: boolean;
}

const useInnerAuthContext = (): InnerAuthContextState => {
  const session = useSession();
  const router = useRouter();
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [isLoginStatus, setIsLoginStatus] = useState<
    IsLoginStatus | undefined
  >();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [webUserData, webUser] = useAuthContextWebUserMeQuery({
    pause:
      !currentUser ||
      !authUrlPatterns.some((pattern) => pattern.test(router.asPath)) ||
      typeof window === 'undefined',
  });

  // ユーザーがログイン状態を維持したまま app router への移行するためのコード。
  // next-auth の session がない場合に、firebase の token を使って session を作成する。
  // このコードがリリースされて、ユーザーが一度でもこのコードを通ったら、このコードは不要になる。
  const initNextAuth = useCallback(
    async (user: User) => {
      if (session.status === 'unauthenticated') {
        const token = await user.getIdToken();
        await signInByNextAuth('credentials', {
          idToken: token,
          refreshToken: user.refreshToken,
          host: `${window.location.protocol}//${window.location.host}`,
        });
      }
    },
    [session]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      return auth.onAuthStateChanged((user) => {
        if (user) {
          setCurrentUser(user);
        } else {
          setCurrentUser(null);
          setIsLoginStatus('logout');
          amplitude.reset();
        }
        setIsLoading(false);
      });
    }
  }, []);

  useEffect(() => {
    if (
      currentUser &&
      authUrlPatterns.some((pattern) => pattern.test(router.asPath))
    ) {
      webUser();
      initNextAuth(currentUser);
    }
  }, [currentUser, initNextAuth, router.asPath, webUser]);

  useEffect(() => {
    if (webUserData.data?.webUserMe) {
      setIsLoginStatus('login');
      amplitude.setUserId(webUserData.data?.webUserMe.id);
    } else if (currentUser) {
      //TODO: DBのアカウントが存在しない時に限定
      setIsLoginStatus('onlyFirebase');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webUserData]);

  return {
    currentUser,
    isLoginStatus,
    isLoading,
  };
};

export type { InnerAuthContextState };
export { useInnerAuthContext };
