import { useEffect } from 'react';

/**
 * Custom hook that adds a resize event listener to the window and calls the provided callback function when the window is resized.
 * @param onResize - The callback function to be called when the window is resized.
 */
export const useResizeEvent = (onResize: () => void) => {
  useEffect(() => {
    onResize();
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);
};
