import React from 'react';

interface Props {
  width?: number | string;
  height?: number | string;
  color?: string;
}

function CaptureXLogo(props: Props) {
  return (
    <svg
      width={props.width ?? 249}
      height={props.height ?? 48}
      viewBox="0 0 249 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2656_7780)">
        <path
          d="M27.2877 26.6301C25.9061 32.9431 21.1075 37.8082 14.137 37.8082C6.24657 37.8082 0 31.5616 0 22.6849C0 13.8082 6.24657 7.56165 14.137 7.56165C21.1075 7.56165 25.9061 12.4267 27.2877 18.7397H21.0411C20.0548 15.3856 17.8199 13.1507 14.137 13.1507C9.86301 13.1507 5.91781 17.0959 5.91781 22.6849C5.91781 28.274 9.86301 32.2192 14.137 32.2192C17.8199 32.2192 20.0548 29.9842 21.0411 26.6301H27.2877Z"
          fill={props.color ?? 'black'}
        />
        <path
          d="M51.2876 34.5205C50.6965 35.1116 50.039 35.7027 49.2486 36.1644C47.867 37.0178 45.6986 37.8082 42.7397 37.8082C36.1643 37.8082 32.5479 34.1918 32.5479 28.9315C32.5479 23.0137 36.4931 19.0685 50.9589 19.0685V18.0822C50.9589 15.1233 49.0527 13.1507 45.3698 13.1507C41.4246 13.1507 39.7808 14.7281 39.452 16.7671H33.5342C33.863 11.5068 38.0705 7.56165 45.3698 7.56165C52.2739 7.56165 56.8767 12.1644 56.8767 18.0822V37.4794H52.6027L51.2876 34.5205ZM50.9589 24.3288C40.4383 24.3288 38.4657 26.3014 38.4657 29.2603C38.4657 30.9041 40.1095 32.5479 43.0684 32.5479C48.0664 32.5479 50.9589 29.589 50.9589 25.9061V24.3288Z"
          fill={props.color ?? 'black'}
        />
        <path
          d="M64.7671 7.89041H69.041L70.3561 10.8493C70.9472 10.2582 71.6047 9.66715 72.3952 9.20548C73.7767 8.41504 75.9451 7.56165 78.904 7.56165C86.7944 7.56165 93.041 13.8082 93.041 22.6849C93.041 31.5617 86.7944 37.8082 78.904 37.8082C76.078 37.8082 74.1054 37.0178 72.7239 36.1644C71.9335 35.7027 71.2759 35.1116 70.6849 34.5206V48H64.7671V7.89041ZM87.1232 22.6849C87.1232 17.0959 83.1116 13.1507 78.5753 13.1507C74.039 13.1507 70.0273 17.0959 70.0273 22.6849C70.0273 28.274 74.039 32.2192 78.5753 32.2192C83.1116 32.2192 87.1232 28.274 87.1232 22.6849Z"
          fill={props.color ?? 'black'}
        />
        <path
          d="M103.558 13.4795H98.2978V7.89041H100.599C102.901 7.89041 104.544 6.24658 104.544 3.94521V0H109.476V7.89041H116.051V13.4795H109.476V27.6165C109.476 30.2466 111.449 32.2192 114.079 32.2192C114.54 32.2192 114.998 32.1527 115.46 32.0863L116.051 31.8904V37.1507C115.789 37.2836 115.46 37.413 115.131 37.4795C114.474 37.6753 113.683 37.8082 112.764 37.8082C107.503 37.8082 103.558 33.863 103.558 27.6165V13.4795Z"
          fill={props.color ?? 'black'}
        />
        <path
          d="M123.942 7.89038H129.859V24.9863C129.859 28.9979 133.081 32.2192 136.764 32.2192C140.642 32.2192 143.996 28.9979 143.996 24.9863V7.89038H149.914V37.4794H145.64L144.325 34.5205C143.734 35.1116 143.077 35.7027 142.286 36.1644C140.905 37.0178 138.736 37.8082 135.777 37.8082C129.202 37.8082 123.942 32.5479 123.942 24.9863V7.89038Z"
          fill={props.color ?? 'black'}
        />
        <path
          d="M157.805 7.89041H162.079L163.394 10.8493C163.918 10.2582 164.576 9.66715 165.3 9.20548C166.548 8.41504 168.392 7.56165 170.955 7.56165C171.679 7.56165 172.074 7.69455 172.403 7.75751L172.928 7.89041V13.8082L172.337 13.6753C171.875 13.6089 171.417 13.4795 170.955 13.4795C166.944 13.4795 163.722 16.8336 163.722 20.7123V37.4795H157.805V7.89041Z"
          fill={props.color ?? 'black'}
        />
        <path
          d="M205.147 28.9315C203.241 33.863 199.229 37.8082 192.325 37.8082C184.435 37.8082 178.188 31.5616 178.188 22.6849C178.188 13.8082 184.368 7.56165 191.996 7.56165C199.624 7.56165 205.805 13.8082 205.805 22.6849C205.805 23.4089 205.672 24.0664 205.609 24.5246L205.476 25.3151H184.435C184.764 28.9979 188.051 32.2192 192.325 32.2192C195.942 32.2192 197.914 30.2466 198.9 28.9315H205.147ZM200.216 20.3836C199.492 16.1096 196.666 13.1507 191.996 13.1507C188.051 13.1507 185.159 16.1096 184.435 20.3836H200.216Z"
          fill={props.color ?? 'black'}
        />
        <path
          d="M214.678 30.5753C216.717 30.5753 218.294 32.1527 218.294 34.1918C218.294 36.2308 216.717 37.8082 214.678 37.8082C212.639 37.8082 211.061 36.2308 211.061 34.1918C211.061 32.1527 212.639 30.5753 214.678 30.5753Z"
          fill={props.color ?? 'black'}
        />
        <path
          d="M231.116 22.3561L221.582 7.89038H228.157L234.733 17.7534L241.308 7.89038H247.883L238.349 22.3561L248.541 37.4794H241.965L234.733 26.6301L227.5 37.4794H220.924L231.116 22.3561Z"
          fill={props.color ?? 'black'}
        />
      </g>
      <defs>
        <clipPath id="clip0_2656_7780">
          <rect width="248.541" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export const SvgCaptureXLogo = React.memo(CaptureXLogo);
