import { Box, Grid, GridItem, VStack } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

import { SvgCaptureXLogo } from '@/components/assets/CaptureXLogo';
import { Earth } from '@/components/common/Layout/V2BGLayout/Earth';
import { SocialImpactCard } from '@/components/common/Layout/V2BGLayout/PageRouter/SocialImpactCard';
import { BottomNav } from '@/components/common/Nav/BottomNav';
import { useIsNativeApp } from '@/hooks/useIsNativeApp';

type Props = PropsWithChildren<{
  bottomNav?: boolean;
}>;

export const ContentWidthWithPc = '400px';

export const V2BGLayout = ({ children, bottomNav = false }: Props) => {
  const isNativeApp = useIsNativeApp();

  return (
    <>
      <Grid
        position="fixed"
        top="0"
        left="0"
        w="100%"
        h="100vh"
        overflow="hidden"
        bg="bg.primary"
        templateColumns={{
          base: '0 1fr 0',
          sm: '0 1fr 0',
          md: `1fr ${ContentWidthWithPc} 1fr`,
        }}
      >
        <GridItem position="relative">
          <Earth />
          <VStack
            h="full"
            p={[4, 4, 4, 8]}
            justify="flex-end"
            align="flex-start"
          >
            <Box
              w={{ base: 0, md: 124.5, lg: 249 }}
              h={{ base: 0, md: '24px', lg: '48px' }}
              zIndex={1}
            >
              <SvgCaptureXLogo width="100%" height="100%" />
            </Box>
          </VStack>
        </GridItem>
        <GridItem></GridItem>
        <GridItem>
          <SocialImpactCard />
        </GridItem>
      </Grid>
      <Box position="relative" w="full">
        <Box
          w={{ base: 'full', sm: 'full', md: ContentWidthWithPc }}
          mx="auto"
          boxShadow="0px 0px 80px 0px #0000000A"
          backgroundColor="bg.primary"
          position="relative"
          pb={!isNativeApp && bottomNav ? '88px' : '0'}
          css={`
            min-height: 100vh;
            min-height: 100dvh;
          `}
        >
          {children}
        </Box>
        {!isNativeApp && bottomNav && (
          <Box
            position="fixed"
            bottom="0"
            left="50%"
            transform="translateX(-50%)"
            w={{ base: 'full', sm: 'full', md: ContentWidthWithPc }}
          >
            <BottomNav />
          </Box>
        )}
      </Box>
    </>
  );
};
