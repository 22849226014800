import { authExchange } from '@urql/exchange-auth';
import Router from 'next/router';

import { auth } from '@/utils/firebase/client';

// Page Router 用
// App Router に完全移行後、削除できる。
export const authConfig: Parameters<typeof authExchange>[0] = async (utils) => {
  const idToken = await auth.currentUser?.getIdToken();
  let token = idToken ?? null;

  return {
    didAuthError: (error) => {
      console.error(error);
      return error.graphQLErrors.some(
        (e) => e.extensions?.code === 'FORBIDDEN'
      );
    },
    refreshAuth: async () => {
      const idToken = await auth.currentUser?.getIdToken();
      if (idToken) {
        token = idToken;
      }
    },
    willAuthError: () => {
      return true;
    },
    addAuthToOperation: (operation) => {
      if (!token) {
        return operation;
      }
      return utils.appendHeaders(operation, {
        Authorization: `Bearer ${token}`,
        language: Router.locale === 'ja' ? 'ja' : 'en',
      });
    },
  };
};
