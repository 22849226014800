import { languages } from '@/app/i18n/settings';
import { Path } from '@/constants/path';

type WithoutSlash<T> = T extends `/${infer U}` ? U : never;
type Resource<T> = T extends `${infer U}/${infer S}` ? U | Resource<S> : T;
type DynamicRoute<T> = T extends `[${infer U}]` ? U : never;

type Params<T> = DynamicRoute<Resource<WithoutSlash<T>>>;
type ParamKeys<T extends Path> = Params<T>;

type PathParams<T extends Path> = {
  path: T;
  params?: { [_K in ParamKeys<T>]: string | number };
};
export type Args<T extends Path> = ParamKeys<T> extends never
  ? PathParams<T>
  : Required<PathParams<T>>;

export const addLngPrefix = ({ path, lng }: { path: string; lng?: string }) => {
  let language = lng;
  // lng が指定されている場合は lng の値を優先する
  if (typeof window !== 'undefined' && language === undefined) {
    language = window.location.pathname.split('/').at(1);
  }

  // 現在のパスが有効な language を含む場合は、 language を含んだパスを返す
  if (language && languages.includes(language)) return `/${language}${path}`;
  return path;
};

export const createPathBase = <T extends Path>({
  args,
  lng,
}: {
  args: Args<T>;
  lng?: string;
}) => {
  const { path, params } = args;

  if (!params) {
    return addLngPrefix({ path, lng });
  }

  const formattedPath = path
    .split('/')
    .map((str) => {
      const match = str.match(/\[(.*?)]/);
      if (match) {
        const key = match[0];
        const trimmed = key.slice(1, -1) as ParamKeys<typeof path>;
        return params[trimmed];
      }
      return str;
    })
    .join('/');
  return addLngPrefix({ path: formattedPath, lng });
};

/**
 * @description 有効なパスを作成する。サーバーコンポーネントでパスを作成する場合は `createPathServer()` を使用してください。
 * @param args The arguments used to create the path.
 * @returns The created path.
 */
export function createPath<T extends Path>(args: Args<T>) {
  return createPathBase({ args });
}
