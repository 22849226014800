import { Text, VStack } from '@chakra-ui/react';

import { useSocialImpactCard } from '@/components/common/Layout/V2BGLayout/PageRouter/useSocialImpactCard';

export const SocialImpactCard = () => {
  const { count, fetching } = useSocialImpactCard();

  return (
    <VStack h="full" p={{ base: 4, md: 4, lg: 8 }} justify="center">
      {!fetching && (
        <Text
          borderStyle="solid"
          borderColor="stroke.secondary"
          borderWidth="2px"
          borderRadius="4px"
          lineHeight={1.1}
          color="text.darkGray"
          display={{ base: 'none', md: 'block' }}
          fontSize={{ base: 'sm', md: 'sm', lg: 'lg' }}
          px={{ base: 4, md: 4, lg: 8 }}
          py={{ base: 2, md: 2, lg: 5 }}
        >
          <Text as="span" fontWeight="bold" pr={1}>
            {count.toLocaleString()}
          </Text>
          times generated social impact
        </Text>
      )}
    </VStack>
  );
};
