import React from 'react';

interface Props {
  size?: number;
  color?: string;
}

function IconHome({ size = 20, color = 'currentColor' }: Props) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 3.5C10.1413 3.49921 10.2812 3.52876 10.4102 3.58666C10.5391 3.64456 10.6541 3.72946 10.7474 3.83563L16.7474 10.5856C16.9102 10.7686 17.0001 11.0051 17 11.25V16.4896C16.9994 17.0199 16.7885 17.5282 16.4136 17.9032C16.0386 18.2781 15.5302 18.489 15 18.4896H12V15.4896C12 14.9592 11.7893 14.4505 11.4142 14.0754C11.0391 13.7003 10.5304 13.4896 10 13.4896C9.46957 13.4896 8.96086 13.7003 8.58579 14.0754C8.21071 14.4505 8 14.9592 8 15.4896V18.4896H5C4.46975 18.489 3.96139 18.2781 3.58645 17.9032C3.2115 17.5282 3.0006 17.0199 3 16.4896V11.25C2.99991 11.0051 3.0898 10.7686 3.25259 10.5856L9.25259 3.83563C9.3459 3.72946 9.46091 3.64456 9.58985 3.58666C9.71879 3.52876 9.85866 3.49921 10 3.5ZM10 2.5C9.71748 2.49948 9.43809 2.55906 9.18037 2.67479C8.92264 2.79052 8.69249 2.95976 8.50519 3.17126L2.50519 9.92126C2.17976 10.2874 2 10.7602 2 11.25V16.4896C2 17.2853 2.31607 18.0483 2.87868 18.6109C3.44129 19.1736 4.20435 19.4896 5 19.4896H8C8.26522 19.4896 8.51957 19.3843 8.70711 19.1967C8.89464 19.0092 9 18.7548 9 18.4896V15.4896C9 15.2244 9.10536 14.9701 9.29289 14.7825C9.48043 14.595 9.73478 14.4896 10 14.4896C10.2652 14.4896 10.5196 14.595 10.7071 14.7825C10.8946 14.9701 11 15.2244 11 15.4896V18.4896C11 18.7548 11.1054 19.0092 11.2929 19.1967C11.4804 19.3843 11.7348 19.4896 12 19.4896H15C15.7956 19.4896 16.5587 19.1736 17.1213 18.6109C17.6839 18.0483 18 17.2853 18 16.4896V11.25C18 10.7602 17.8202 10.2874 17.4948 9.92126L11.4948 3.17126C11.3075 2.95976 11.0774 2.79052 10.8196 2.67479C10.5619 2.55906 10.2825 2.49948 10 2.5Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
    </svg>
  );
}

export const SvgIconHome = React.memo(IconHome);
